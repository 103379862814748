import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/hellberg/helpers';

import { Gauge } from '../Gauge/Gauge';

export const PagerStyled = styled.div`
  margin-block-start: ${spacing.regular};
`;

export const StyledGauge = styled(Gauge)`
  margin: 0 0 ${spacing.block} 0;
  width: 100%;
`;

export const PagerInner = styled.div`
  max-width: 250px;
  margin-block: 0;
  margin-inline: auto;
`;

export const Label = styled.div`
  margin-block-end: ${spacing.small};
  text-align: center;
  font-size: ${fontSizes.s};
  letter-spacing: 0.1rem;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    text-align: center;
    justify-content: center;
  }
`;
