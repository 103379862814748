import { useId } from 'react';

import dynamic from 'next/dynamic';

import {
  DynamicContentField,
  isBlueInfoBox,
  isBulletList,
  isDocumentBlockNew,
  isDotDigitalForm,
  isGreyInfoBox,
  isImageBlock,
  isImageSlider,
  isLinkBoxBlock,
  isQuote,
  isTextBlock,
  isTwoImageBlock,
  isVideo,
} from '@hultafors/hellberg/types';

import { StyledDynamicContent } from './DynamicContent.styled';

const BlueInfoBox = dynamic(() =>
  import('../BlueInfoBox/BlueInfoBox').then((mod) => mod.BlueInfoBox),
);
const BulletList = dynamic(() =>
  import('../BulletList/BulletList').then((mod) => mod.BulletList),
);

const DocumentBlock = dynamic(() => import('../document-block/document-block').then((mod) => mod.DocumentBlock));

const DualImageBlock = dynamic(() =>
  import('../DualImageBlock/DualImageBlock').then((mod) => mod.DualImageBlock),
);
const GreyInfoBox = dynamic(() =>
  import('../GreyInfoBox/GreyInfoBox').then((mod) => mod.GreyInfoBox),
);
const IFrameContainer = dynamic(() =>
  import('../IFrameContainer/IFrameContainer').then(
    (mod) => mod.IFrameContainer,
  ),
);
const ImageBlock = dynamic(() =>
  import('../ImageBlock/ImageBlock').then((mod) => mod.ImageBlock),
);
const ImageSlider = dynamic(() =>
  import('../ImageSlider/ImageSlider').then((mod) => mod.ImageSlider),
);
const LinkBoxBlock = dynamic(() =>
  import('../LinkBoxBlock/LinkBoxBlock').then((mod) => mod.LinkBoxBlock),
);
const Quote = dynamic(() => import('../Quote/Quote').then((mod) => mod.Quote));
const TextBlock = dynamic(() =>
  import('../TextBlock/TextBlock').then((mod) => mod.TextBlock),
);
const VideoBlock = dynamic(() =>
  import('../VideoBlock/VideoBlock').then((mod) => mod.VideoBlock),
);

interface DynamicContentProps {
  content: DynamicContentField[];
}
export const DynamicContent: React.FC<DynamicContentProps> = ({ content }) => {
  const uid = useId();

  if (!content.length) {
    return null;
  }

  function contentMapper(item: DynamicContentField, index: number) {
    const key = `DynamicContent-${uid}-${index}`;
    if (isBlueInfoBox(item)) {
      return <BlueInfoBox key={key} body={item.body} />;
    }
    if (isBulletList(item)) {
      return (
        <BulletList
          key={key}
          title={item.title ?? ''}
          body={item.list ?? ''}
          isBig={item.isBigBulletList}
          // link={item.link}
        />
      );
    }
    if (isDocumentBlockNew(item)) {
      return <DocumentBlock key={key} document={item.document} title={item.title} />;
    }
    if (isDotDigitalForm(item)) {
      return (
        <IFrameContainer
          key={key}
          title="Form"
          src={item.formUrl ?? ''}
          height={`${item.formHeight}px`}
        />
      );
    }
    if (isGreyInfoBox(item)) {
      return (
        <GreyInfoBox
          key={key}
          title={item.title ?? ''}
          body={item.body ?? ''}
        />
      );
    }
    if (isImageBlock(item)) {
      return (
        <ImageBlock
          key={key}
          image={item.image}
          label={item.label}
          small={item.small}
        />
      );
    }
    if (isImageSlider(item)) {
      return <ImageSlider key={key} images={item.images} />;
    }
    if (isLinkBoxBlock(item)) {
      return <LinkBoxBlock key={key} title={item.title} links={item.links} />;
    }
    if (isQuote(item)) {
      return (
        <Quote
          key={key}
          body={item.body ?? ''}
          author={item.author ?? ''}
          authorProfession={item.authorProfession ?? ''}
        />
      );
    }
    if (isTextBlock(item)) {
      return (
        <TextBlock
          key={key}
          body={item.body}
          bigHeader={item.bigHeader}
          title={item.title}
          className="TextBlock"
        />
      );
    }
    if (isTwoImageBlock(item)) {
      return (
        <DualImageBlock
          key={key}
          images={item.images}
          dynamicImages={item.dynamicImages}
          unlockedAspectRatio={item.unlockedAspectRatio}
        />
      );
    }
    if (isVideo(item)) {
      return <VideoBlock key={key} video={item.video} />;
    }

    return null;
  }

  return (
    <StyledDynamicContent>{content.map(contentMapper)}</StyledDynamicContent>
  );
};
