import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';

// Hack for build dependencies
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import {
  findRetailersMode,
  useFindRetailers,
  useOnlineStores,
} from '@hultafors/shared/api';
import { Store, StoreCoords } from '@hultafors/shared/types';

import {
  cleanTextFromUrl,
  DEFAULT_RADIUS,
  RADIUS_ALTERNATIVES,
  TABS,
} from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';
import { FindRetailersPageFragment } from '@hultafors/hellberg/types';

import { FindRetailersList } from '../FindRetailersList/FindRetailersList';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { InformationHero } from '../InformationHero/InformationHero';
import { LinkBlock } from '../LinkBlock/LinkBlock';
import { Loader } from '../Loader/Loader';
import { OnlineStoreList } from '../OnlineStoreList/OnlineStoreList';
import { Paragraph } from '../Paragraph/Paragraph';
import {
  ProductInformationTabsStyled,
  Tab,
  Tabs,
} from '../ProductInformationTabs/ProductInformationTabs.styled';
import { RetailersAdditionalSelections } from '../RetailersAdditionalSelections/RetailersAdditionalSelections';
import { SearchInput } from '../SearchInput/SearchInput';
import { Section } from '../Section/Section';

interface FindRetailersContentProps {
  content: FindRetailersPageFragment;
}

const Map = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.Map),
  {
    ssr: false,
  },
);

export const FindRetailersContent: React.FC<FindRetailersContentProps> = ({
  content,
}) => {
  // const { settings } = useGlobal();
  const { siteLocale } = useGlobal();
  const {
    term,
    setTerm,
    international,
    setInternational,
    radius,
    setRadius,
    mode,
    stores,
    loading,
    clear,
    setCoords,
    coords,
    geoLoading,
  } = useFindRetailers({
    defaultRadius: DEFAULT_RADIUS,
    radiusOptions: content.radiusAlternatives.map(
      ({ radiusValue }): number => radiusValue,
    ) || [DEFAULT_RADIUS],
    defaultCoords: siteLocale.defaultCoordinates as StoreCoords,
  });
  const { stores: onlineStores } = useOnlineStores();

  const [tab, setTab] = useState<number>(0);
  const [search, setSearch] = useState<string>(term || '');

  const [selectedItem, setSelectedItem] = useState<Store | undefined>(
    undefined,
  );

  const [displaySearchMessage, setDisplaySearchMessage] = useState('');

  useEffect(() => {
    if (loading) {
      setDisplaySearchMessage(content.noResultForYourLocation ?? '');
    } else {
      switch (mode) {
        // TODO Dont understand this mode thing, how does it play out on snickers?
        case findRetailersMode.findRetailersByCoord:
          setDisplaySearchMessage(
            !term
              ? ''
              : stores.length && term
                ? `${stores.length} ${
                  content.matchesFor ?? ''
                } ”${cleanTextFromUrl(search)}”`
                : content.noResultForYourLocation ?? '',
          );
          break;
        case findRetailersMode.findRetailers:
          setDisplaySearchMessage(
            !term
              ? ''
              : stores.length && term
                ? `${stores.length} ${
                  content.matchesFor ?? ''
                } ”${cleanTextFromUrl(search)}”`
                : content.noResultForYourLocation ?? '',
          );
          break;
      }
    }
  }, [stores, loading]);

  const onChangeTerm: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value);
  };

  const clearSearch = () => {
    setSearch('');
    clear();
  };

  const onSubmitSearch: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setTerm(search);
  };

  const onChangeRadius: React.ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    setRadius(parseInt(event.target.value.replace(' km', ''), 10));
  };

  const onChangeInternational: React.ChangeEventHandler<
    HTMLInputElement
  > = () => {
    setInternational(!international);
  };

  const selectItem = (id: string) => {
    setSelectedItem(stores?.find((x) => x.id === id) || selectedItem);
  };

  const mapPositionChanged = async (latitude: number, longitude: number) => {
    setCoords({ latitude, longitude });
    setTerm('');
  };

  return (
    <>
      <InformationHero
        title={content.heroTitle ?? ''}
        description={content.heroDescription ?? ''}
        center={true}
      />
      <LinkBlock
        linkText={content.becomeARetailer ?? ''}
        linkUrl={content.becomeARetailerLinkUrl ?? ''}
      />
      <Section noMarginBottom>
        <Grid
          columns={[{ columns: 4 }, { breakpoint: 'largeMobile', columns: 12 }]}
        >
          <GridChild>
            <ProductInformationTabsStyled>
              <Tabs $center>
                <Tab
                  $active={tab === TABS.search}
                  onClick={() => setTab(TABS.search)}
                >
                  <Paragraph styleType="body14">
                    {content.retailersLabel}
                  </Paragraph>
                </Tab>
                <Tab
                  $active={tab === TABS.onlineStores}
                  onClick={() => setTab(TABS.onlineStores)}
                >
                  <Paragraph styleType="body14">
                    {content.onineStoresLabel}
                  </Paragraph>
                </Tab>
              </Tabs>
            </ProductInformationTabsStyled>
          </GridChild>
        </Grid>
      </Section>
      <Section noMarginTop>
        <Grid
          columns={[{ columns: 4 }, { breakpoint: 'largeMobile', columns: 12 }]}
        >
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'largeMobile', columns: 8, start: 3 },
              { breakpoint: 'desktop', columns: 4, start: 5 },
            ]}
          >
            <Section noMargin hide={tab !== TABS.search}>
              <SearchInput
                name="findRetailersInput"
                onChange={onChangeTerm}
                value={search}
                placeholder={content.searchLabel ?? ''}
                onSubmit={onSubmitSearch}
                onClear={clearSearch}
                searchLabel={content.searchLabel || ''}
              />
              <RetailersAdditionalSelections
                data={content}
                selectedRadius={radius}
                radiusSelectChange={onChangeRadius}
                radiusAlternatives={RADIUS_ALTERNATIVES.map(
                  (radiusValue) => `${radiusValue} km`,
                )}
                internationalSearchToggleChange={onChangeInternational}
                isRetailerSearchExpandable={true}
                isSearchInternational={international}
              />
            </Section>
          </GridChild>
          <GridChild>
            <Section hide={tab !== TABS.onlineStores}>
              <Grid>
                <GridChild
                  columnSpan={[
                    { columns: 4 },
                    { breakpoint: 'mobileMax', columns: 8, start: 3 },
                    {
                      breakpoint: 'desktopLarge',
                      columns: 4,
                      start: 5,
                    },
                  ]}
                >
                  {loading
                    ? (
                      <Loader />
                      )
                    : (
                      <>
                        {onlineStores.length > 0
                          ? (
                            <OnlineStoreList stores={onlineStores} />
                            )
                          : (
                            <Paragraph center noMargin>
                              {content.noOnlineStoresFound}
                            </Paragraph>
                            )}
                      </>
                      )}
                </GridChild>
              </Grid>
            </Section>
          </GridChild>
        </Grid>
      </Section>
      <Section hide={tab !== TABS.search}>
        <>
          <Grid columnGap="small">
            <GridChild
              padding="0 0 0 0px"
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 5 },
                { breakpoint: 'desktop', columns: 5, start: 2 },
              ]}
              className="map"
            >
              <Map
                center={coords}
                stores={stores}
                viewOnMap={content.viewOnMap ?? ''}
                visitWebsiteLabel={content.visitWebsiteLabel ?? ''}
                sticky
              />
            </GridChild>
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 6, start: 7 },
                { breakpoint: 'desktop', columns: 4, start: 8 },
              ]}
            >
              {!geoLoading && !loading
                ? (
                  <>
                    {stores.length > 0 && (
                      <FindRetailersList
                        selectItem={selectItem}
                        selectedItem={selectedItem}
                        stores={stores.filter(({ data }) => !!data.companyName)}
                        viewOnMap={content.viewOnMap ?? ''}
                        visitWebsiteLabel={content.visitWebsiteLabel ?? ''}
                        storeAlsoSellsLabel={
                        content.thisStoreAlsoSellsLabel ?? ''
                      }
                      />
                    )}
                  </>
                  )
                : (
                  <Loader />
                  )}
            </GridChild>
          </Grid>
        </>
      </Section>
    </>
  );
};
