import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Paragraph } from '../Paragraph/Paragraph';

import { ContactInformationStyled } from './ContactInformation.styled';

interface ContactInformationProps {
  addressLabel: string;
  phoneLabel: string;
  emailLabel: string;
  localContactLabel: string;
  companyName: string;
  companyAddress: string;
  companyCountry: string;
  phoneNumber: string;
  companyEmail: string;
  localContactArr: string[];
}

export const ContactInformation: React.FC<ContactInformationProps> = ({
  addressLabel,
  phoneLabel,
  emailLabel,
  localContactLabel,
  companyName,
  companyAddress,
  companyCountry,
  phoneNumber,
  companyEmail,
  localContactArr,
}) => {
  if (!localContactLabel || !phoneLabel || !emailLabel) {
    return null;
  }
  const renderBlock = (title: string, list: any[], key: string) => {
    if (!list) return null;
    return (
      <div className="TextBlock">
        {title && (
          <Paragraph className="Title" styleType="body16Medium">
            {title}
          </Paragraph>
        )}
        {list.map((item, i: number) => {
          return (
            <Paragraph styleType="body16" key={`${key}-${i}`}>
              {item}
            </Paragraph>
          );
        })}
      </div>
    );
  };

  const renderAdressBlock = (
    title: string,
    name: string,
    address: string,
    country: string,
  ) => {
    if (!title && !address) return;
    return renderBlock(title, [name, address, country], 'Address');
  };

  const renderPhoneNumbersBlock = (title, phone) => {
    if (!title && !phone) return '';

    const tags = [
      <a key="phoneKey" href={`tel:${phone.replace(/ /g, '')}`}>
        {phone}
      </a>,
    ];
    return renderBlock(title, tags, 'Phone');
  };

  const renderEmailBlock = (title: string, email: string) => {
    if (!title && !email) return;
    const aTag = email ? <a href={`mailto:${email}`}>{email}</a> : '';
    return renderBlock(title, [aTag], 'Email');
  };

  const filteredContactArr = localContactArr.filter((el) => {
    return el != null;
  });

  return (
    <ContactInformationStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 4, start: 3 },
            { breakpoint: 'desktop', columns: 3, start: 4 },
          ]}
        >
          {renderAdressBlock(
            addressLabel,
            companyName,
            companyAddress,
            companyCountry,
          )}
          {renderPhoneNumbersBlock(phoneLabel, phoneNumber)}
          {renderEmailBlock(emailLabel, companyEmail)}
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 4, start: 8 },
            { breakpoint: 'desktop', columns: 3, start: 7 },
          ]}
        >
          <div className="LocalContactInfo">
            <div className="TextBlock">
              {localContactLabel && filteredContactArr.length > 0 && (
                <Paragraph className="Title" styleType="body16Medium">
                  {localContactLabel}
                </Paragraph>
              )}
              {filteredContactArr.map((item, i: number) => {
                return (
                  <Paragraph styleType="body16" key={`text-${i}`}>
                    {item}
                  </Paragraph>
                );
              })}
            </div>
          </div>
        </GridChild>
      </Grid>
    </ContactInformationStyled>
  );
};
