import { MarkdownToJSX } from 'markdown-to-jsx';

import { StyledMarkdown } from './Markdown.styled';

interface MarkdownProps {
  children?: string;
  options?: MarkdownToJSX.Options;
}

export const Markdown: React.FC<MarkdownProps> = ({
  children,
  options = {},
}) => {
  return (
    <StyledMarkdown options={{ forceBlock: true, ...options }}>
      {children ?? ''}
    </StyledMarkdown>
  );
};
