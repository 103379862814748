import { ParagraphStyleType, StyledParagraph } from './Paragraph.styled';
interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  styleType?: ParagraphStyleType;
  center?: boolean;
  noMargin?: boolean;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  styleType,
  center,
  noMargin,
  ...rest
}) => {
  return (
    <StyledParagraph
      $styleType={styleType}
      $center={center}
      $noMargin={noMargin}
      {...rest}
    >
      {children}
    </StyledParagraph>
  );
};
