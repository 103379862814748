import { useState } from 'react';

import { useTimeoutEffect, useToggle } from '@react-hookz/web';
import { m } from 'framer-motion';

import { useCart } from '@hultafors/hellberg/hooks';

import Check from '../../svg/check.svg';
import ShoppingList from '../../svg/shopping_list.svg';

import {
  AddToShoppingListButtonStyled,
  StyledLoadingCircle,
} from './AddToShoppingListButton.styled';

interface AddToShoppingListButtonProps {
  addToShoppingListButtonLabel?: string | null;
  productForStorage?: any;
}

export const AddToShoppingListButton: React.FC<
  AddToShoppingListButtonProps
> = ({ addToShoppingListButtonLabel, productForStorage }) => {
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [enabled, toggleEnabled] = useToggle();
  const [isDone, setIsDone] = useState<boolean>(false);
  const { addItemToCart } = useCart();

  const [_cancel, _reset] = useTimeoutEffect(
    () => {
      setIsAddingToCart(false);
    },
    enabled ? 2000 : undefined,
  );

  const addToShoppingList = () => {
    setIsAddingToCart(true);
    toggleEnabled(true);

    addItemToCart(productForStorage, 1);
    setIsDone(true);
  };

  return (
    <AddToShoppingListButtonStyled
      disabled={isAddingToCart}
      onClick={addToShoppingList}
    >
      {isAddingToCart && (
        <m.div
          initial={{ height: '100%' }}
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: 'easeInOut' }}
        >
          <StyledLoadingCircle
            aria-hidden={true}
            focusable={false}
            width={32}
            height={32}
          />
        </m.div>
      )}
      {!isAddingToCart && !isDone
        ? (
          <>
            <ShoppingList
              aria-hidden={true}
              focusable={false}
              width={24}
              height={24}
            />
            {addToShoppingListButtonLabel}
          </>
          )
        : (
            !isAddingToCart
            && isDone && (
              <Check aria-hidden={true} focusable={false} width={32} height={32} />
            )
          )}
    </AddToShoppingListButtonStyled>
  );
};
