import { CategoryFeatureFragment } from '@hultafors/hellberg/types';

import { Features } from '../Features/Features';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Markdown } from '../Markdown/Markdown';

import {
  CategoryHeaderStyled,
  Description,
  TextGrid,
} from './CategoryHero.styled';
interface CategoryHeroProps {
  title?: string | null;
  description?: string | null;
  featuresHeader?: string | null;
  features?: CategoryFeatureFragment[] | null;
}
export const CategoryHero: React.FC<CategoryHeroProps> = ({
  title,
  description,
  featuresHeader,
  features = [],
}) => {
  return (
    <CategoryHeaderStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12, start: 1 },
            { breakpoint: 'desktop', columns: 5, start: 1 },
          ]}
        >
          <div>
            {title && (
              <HTag type="h1" styleType="header2">
                {title}
              </HTag>
            )}
          </div>
        </GridChild>
      </Grid>
      {description && (
        <TextGrid>
          <Description>
            <div>
              <Markdown>{description}</Markdown>
            </div>
          </Description>
          {description && (
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 12, start: 1 },
                { breakpoint: 'desktop', columns: 4, start: 8 },
              ]}
            >
              {!!features?.length && (
                <Features title={featuresHeader} features={features} />
              )}
            </GridChild>
          )}
        </TextGrid>
      )}
    </CategoryHeaderStyled>
  );
};
