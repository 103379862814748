import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import Download from '../../svg/download.svg';
import File from '../../svg/file.svg';

export const DownloadFileBoxStyled = styled.span`
  a {
    position: relative;
    background-color: ${colors.gray5};
    color: ${colors.black};
    width: 100%;
    max-width: 657px;
    height: auto;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    padding-block: ${spacing.small};
    padding-inline: 0;
    margin-block-end: ${spacing.xsmall};

    :hover {
      cursor: pointer;
      background-color: ${colors.gray6};
    }
  }

  .TextWrapper {
    display: flex;
    flex-direction: column;
    padding-inline: ${spacing.small};
    width: 100%;
    text-align: left;
    color: ${colors.black};

    .Size {
      color: ${colors.hellSalmon};
    }
  }
`;

export const StyledDownloadIcon = styled(Download)`
  margin-inline-end: ${spacing.small};
  height: 100%;
`;

export const StyledFileIcon = styled(File)`
  color: ${colors.black};
  margin-inline-start: ${spacing.xsmall};
  height: 100%;
`;
