import CloseCircle from '../../svg/close_circle.svg';
import Search from '../../svg/search.svg';
import { SearchField } from '../SearchInput/SearchInput.styled';

import {
  ClearButton,
  ResultText,
  SearchButton,
  SearchInputChildren,
  SearchInputForm,
  SearchInputFormWrapper,
  SearchInputStyled,
} from './SearchRetailersInput.styled';

interface SearchRetailersInputProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit: React.FormEventHandler;
  clear(): void;
  value: string;
  placeholder?: string;
  name?: string;
  displaySearchMessage?: string;
  children?: React.ReactNode;
  clearLabel: string;
  searchLabel: string;
  className?: string;
  pattern?: string;
  disabled?: boolean;
  tall?: boolean;
}

export const SearchRetailersInput: React.FC<SearchRetailersInputProps> = ({
  onSubmit,
  onChange,
  value,
  placeholder,
  clear,
  children,
  displaySearchMessage = '',
  name = 'SearchBox',
  clearLabel,
  searchLabel,
  pattern,
  tall,
}) => {
  const onKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      clear();
    }
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };
  return (
    <SearchInputStyled onKeyDown={onKeyDown}>
      <SearchInputForm onSubmit={onSubmit} autoComplete="off">
        <SearchInputFormWrapper $tall={tall}>
          <SearchField
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            pattern={pattern}
            type="text"
          />
          {value && (
            <ClearButton onClick={clear} aria-label={clearLabel}>
              <CloseCircle
                aria-hidden={true}
                focusable={false}
                width={16}
                height={16}
              />
            </ClearButton>
          )}
          <SearchButton
            aria-label={searchLabel}
            onClick={onSubmit}
            disabled={!value.length}
          >
            <Search
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
          </SearchButton>
        </SearchInputFormWrapper>
      </SearchInputForm>
      {children && <SearchInputChildren>{children}</SearchInputChildren>}
      <ResultText>{displaySearchMessage}</ResultText>
    </SearchInputStyled>
  );
};
