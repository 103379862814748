import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { Button } from '../Button/Button';
import { Paragraph } from '../Paragraph/Paragraph';
import { Section } from '../Section/Section';

export const StyledButtonFilters = styled.div`
  border-block-end: 1px solid ${colors.gray4};
  padding-inline: ${spacing.small} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline: ${spacing.medium} ${spacing.medium};
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  @media screen and (min-width: ${breakpoints.desktop}) {
    overflow-x: hidden;
    padding: 0;
  }

  label {
    padding: 20px;
    border: 1px solid ${colors.black};
  }
`;

export const TypeLabel = styled(Paragraph).attrs({
  styleType: 'body16Medium',
})`
  /* Keep */
`;

export const FilterSection = styled(Section).attrs({
  maxWidth: true,
  smallMarginTop: true,
  mediumMarginBottom: true,
  fullWidth: true,
})`
  /* Keep */
`;

export const FilterButton = styled(Button).attrs({
  nature: 'outlinePrimary',
})`
  margin: ${spacing.xsmall} ${spacing.small} 0 0;
`;
