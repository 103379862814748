import { useId } from 'react';

import {
  ProductFilter,
  ProductFilterValue,
  QueryProductFilter,
} from '@hultafors/shared/types';

import {
  FilterButton,
  FilterSection,
  Inner,
  StyledButtonFilters,
  TypeLabel,
} from './ButtonFilters.styled';

interface ButtonFiltersProps {
  filters: ProductFilter[];
  typeLabel: string;
  filterChange({ AttrId, ValueId }: QueryProductFilter): void;
}

export const ButtonFilters: React.FC<ButtonFiltersProps> = ({
  filters = [],
  typeLabel,
  filterChange,
}) => {
  const uid = useId();
  const isHighlightedFilter = ({ isHighLighted }: ProductFilter) =>
    isHighLighted;

  const filterMapper = ({ id: parentId, values }: ProductFilter) => {
    const valueMapper = ({
      id: valueId,
      active,
      description,
    }: ProductFilterValue) => {
      const key = `ButtonFilters-${uid}-value-${valueId}`;
      const onClick = () => {
        filterChange({
          AttrId: `${parentId}`,
          ValueId: `${valueId}`,
        });
      };
      return (
        <FilterButton key={key} selected={active} onClick={onClick}>
          {description}
        </FilterButton>
      );
    };
    const key = `ButtonFilters-${uid}-filter-${parentId}`;
    return (
      <div key={key}>
        <TypeLabel>{typeLabel}</TypeLabel>
        {values.map(valueMapper)}
      </div>
    );
  };
  return (
    <StyledButtonFilters>
      <FilterSection>
        <Inner>{filters?.filter(isHighlightedFilter).map(filterMapper)}</Inner>
      </FilterSection>
    </StyledButtonFilters>
  );
};
