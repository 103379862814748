import { useGlobal } from '@hultafors/hellberg/hooks';
import { FindRetailersPageFragment } from '@hultafors/hellberg/types';

import { Select } from '../Select/Select';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';

import {
  RadiusSelectContainer,
  StyledRetailersAdditionalSelections,
} from './RetailersAdditionalSelections.styled';

interface RetailersAdditionalSelectionsProps {
  data: FindRetailersPageFragment;
  selectedRadius?: number;
  radiusSelectChange: React.ChangeEventHandler<HTMLSelectElement>;
  internationalSearchToggleChange: React.ChangeEventHandler<HTMLInputElement>;
  radiusAlternatives?: string[];
  isRetailerSearchExpandable?: boolean;
  isSearchInternational?: boolean;
}
export const RetailersAdditionalSelections: React.FC<
  RetailersAdditionalSelectionsProps
> = ({
  data,
  radiusSelectChange,
  radiusAlternatives = [],
  internationalSearchToggleChange,
  isRetailerSearchExpandable,
  isSearchInternational,
  selectedRadius,
}) => {
  const { siteLocale } = useGlobal();

  const RadiusSelect = () => {
    if (!isRetailerSearchExpandable || !data?.radiusAlternatives) {
      return null;
    }
    return (
      <RadiusSelectContainer>
        <Select
          id="radiusSelect"
          label={data.radiusHelpText ?? ''}
          selected={`${selectedRadius} km`}
          onChange={radiusSelectChange}
          options={radiusAlternatives}
        />
      </RadiusSelectContainer>
    );
  };

  const InternationalSearchToggle = () => {
    if (!siteLocale?.parttrapMarket || siteLocale?.parttrapMarket === 'com') {
      return null;
    }
    return (
      <ToggleSwitch
        label={data.otherCountriesIncluded ?? ''}
        checked={isSearchInternational}
        onChange={internationalSearchToggleChange}
      />
    );
  };

  return (
    <StyledRetailersAdditionalSelections>
      <div className="AdditionalSelections">
        <RadiusSelect />
        <InternationalSearchToggle />
      </div>
    </StyledRetailersAdditionalSelections>
  );
};
