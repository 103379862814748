export const spacing = {
  XL: '192px',
  large: '96px',
  medium: '48px',
  block: '32px',
  regular: '24px',
  small: '16px',
  xsmall: '8px',
  tiny: '8px',
  xxsmall: '4px',
  tinier: '8px',

  // Not sure is this are accurate
  mobileTop: '72px',
  menuHeight: '64px',
} as const;

export type Spacing = keyof typeof spacing;
