import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import LoadingCircle from '../../svg/loading_circle.svg';
import { Button } from '../Button/Button';

export const AddToShoppingListButtonStyled = styled(Button).attrs({
  stretch: true,
})`
  svg {
    margin-inline-end: ${spacing.xsmall};
    color: ${colors.white};
  }
`;

export const StyledLoadingCircle = styled(LoadingCircle)`
  color: ${colors.white};
  margin-inline-end: 0 !important;
`;
