import { ErrorMessage, InputStyled } from './Input.styled';

export interface InputProps {
  type?: string;
  label?: string;
  name?: string;
  accept?: string;
  required?: boolean;
  capture?: boolean;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  multiple?: boolean;
  textarea?: boolean;
  placeholder?: string;
  pattern?: string;
  value?: string | number | boolean;
  className?: string;
  errorMessage?: string;
  defaultValue?: string;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
}

export const Input: React.FC<InputProps> = ({
  className,
  type = 'text',
  label = '',
  name = 'Input',
  accept = '*',
  required = false,
  capture = false,
  multiple = false,
  textarea = false,
  placeholder = '',
  value = '',
  pattern = '[^#_@£$|*/]{1,500}',
  errorMessage = '',
  defaultValue,
  onChange,
  onKeyDown,
}) => (
  <InputStyled className={className}>
    {label && <label htmlFor={name}>{label}</label>}

    {textarea && (
      <textarea
        id={name}
        required={required}
        onChange={onChange}
        value={`${value}`}
        defaultValue={defaultValue}
      />
    )}
    {!textarea && (
      <input
        type={type}
        id={name}
        name={name}
        accept={accept}
        required={required}
        placeholder={placeholder}
        pattern={pattern}
        multiple={multiple}
        capture={capture}
        onChange={onChange}
        value={`${value}`}
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
      />
    )}
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </InputStyled>
);
