import { useId } from 'react';

import { ParttrapValue } from '@hultafors/shared/types';

import { createDetailPageUrl } from '@hultafors/hellberg/helpers';
import {
  HellbergProduct,
  HellbergProductDetails,
  ParsedProduct,
} from '@hultafors/hellberg/types';

import { Badge } from '../Badge/Badge';
import { Paragraph } from '../Paragraph/Paragraph';
import { ProductImage } from '../ProductImage/ProductImage';
import { RouterLink } from '../RouterLink/RouterLink';

import {
  ProductIcon,
  ProductIconWrapper,
  ProductStyled,
} from './Product.styled';

const ProductIcons: React.FC<{ icons?: ParttrapValue[] | null }> = ({
  icons,
}) => {
  const uid = useId();
  const slicedIcons = icons?.filter((icon) => !!icon?.image?.url)?.slice(0, 3);
  if (!slicedIcons?.length) {
    return null;
  }
  const iconMapper = (icon: ParttrapValue, index: number) => {
    return (
      <ProductIcon
        key={`ProductIcons-${uid}-${index}`}
        src={icon?.image?.url ?? ''}
        alt={icon.text || 'icon image'}
        width={24}
        height={24}
      />
    );
  };
  return <ProductIconWrapper>{slicedIcons.map(iconMapper)}</ProductIconWrapper>;
};

interface ProductProps {
  product: HellbergProduct | HellbergProductDetails | ParsedProduct;
  badgeText?: string;
  inFavorites?: boolean;
  toggleMenu?(): void;
  rrpLabel: string;
  rrpIncludingVatLabel?: string;
  isSmall?: boolean;
  noHover?: boolean;
}

export const Product: React.FC<ProductProps> = ({
  product,
  badgeText,
  inFavorites,
  toggleMenu,
  rrpLabel,
  rrpIncludingVatLabel,
  isSmall,
  noHover,
}) => {
  const isArray = (array: any) => {
    return array && Array.isArray(array) && array.length > 0;
  };

  const handleOnClick = () => {
    toggleMenu?.();
  };
  const to = createDetailPageUrl(product);
  return (
    <ProductStyled
      $isSmall={!!isSmall}
      $inFavorites={!!inFavorites}
      $noHover={!!noHover}
    >
      <div className={`productWrapper ${product.id}`}>
        <div className="ProductImage">
          <RouterLink className="ProductName" to={to} onClick={handleOnClick}>
            <ProductImage url={product.image?.url} name={product.name} />
          </RouterLink>

          {product.isNew && (
            <Badge styleName="TopLeft" isNew={true}>
              {badgeText}
            </Badge>
          )}
          <ProductIcons icons={product?.icons} />
        </div>
        <div className="ProductInfo">
          <RouterLink className="ProductName" to={to} onClick={handleOnClick}>
            <Paragraph styleType="body14Medium">{product.name}</Paragraph>
          </RouterLink>
          {product.price && product.price > 0
            ? (
              <div className="ProductPrice">
                <Paragraph styleType="body14">{`${product.currency} ${product.price}`}</Paragraph>
                <Paragraph styleType="body10" className="rrp">
                  {`${rrpLabel} `}
                </Paragraph>
              </div>
              )
            : product.priceWithVat && product.priceWithVat > 0
              ? (
                <div className="ProductPrice">
                  <Paragraph styleType="body14">{`${product.currency} ${product.priceWithVat}`}</Paragraph>
                  <Paragraph styleType="body10" className="rrp">
                    {`${rrpIncludingVatLabel} `}
                  </Paragraph>
                </div>
                )
              : (
                  ''
                )}

          {product?.usp && product.usp.length > 0 && (
            <div className="USP">
              <ul>
                {product.usp.map((item: string, index: number) => (
                  <li key={`usp-l-${index}`}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </ProductStyled>
  );
};
