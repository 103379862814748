import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

export const DocumentPageStyled = styled.div`
  padding-block: 0;
  padding-inline: ${spacing.small};

  .HeroTitle {
    padding-block: ${spacing.medium};
    padding-inline: 0;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: 0;
  }
`;

export const StyledDocumentsContent = styled.div`
  margin-block-end: ${spacing.large};

  .Header {
    margin-block-end: ${spacing.regular};
  }

  .DownloadBox + .Header {
    margin-block-start: ${spacing.medium};

    &.Big {
      margin-block-start: ${spacing.large};
    }
  }

  .DownloadBox {
    a {
      margin-block-end: ${spacing.small};
    }
  }

  .DesktopOnly {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: ${spacing.XL};

    .MobileOnly {
      display: none;
    }

    .DesktopOnly {
      display: inherit;
    }
  }

  > div:last-of-type {
    padding-block-end: 0;
  }
`;
