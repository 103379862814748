import { ParttrapImage } from '@hultafors/shared/types';

import {
  HellbergProduct,
  HellbergProductDetails,
  ParsedProduct,
} from '@hultafors/hellberg/types';

export const transformProduct = (
  product: HellbergProduct | HellbergProductDetails | ParsedProduct,
  amount?: number,
): ParsedProduct => {
  // This is shit
  const isNew = product.isNew ? true : false;
  return {
    amount: amount,
    sku: product.sku,
    modelSku: product.modelSku,
    id: product.id,
    productListId: product?.productListId || undefined,
    image: product.image as ParttrapImage,
    name: product.name || '',
    isNew: isNew,
    category: product.category,
    parentCategory: product.parentCategory,
    price: product.price,
    priceWithVat: product.priceWithVat,
    currency: product.currency,
  };
};
