import Link from 'next/link';
import styled from 'styled-components';

import { spacing } from '@hultafors/hellberg/helpers';

import { HTag } from '../HTag/HTag';

export const AppLinksStyled = styled.div`
  inline-size: 1000px;
  max-inline-size: calc(100vw - 40px);
  margin-block: ${spacing.regular};
  display: flex;
  flex-direction: column;
  margin-inline: auto;
`;

export const Header = styled(HTag).attrs({ styleType: 'header4', type: 'h2' })`
  margin-block-end: ${spacing.xxsmall};
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  inline-size: 100%;
  block-size: 100%;
`;

export const AppLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;

  :first-child {
    margin-inline-end: ${spacing.tiny};
  }
`;
