import { constructBackLink } from '@hultafors/hellberg/helpers';

import ArrowBack from '../../svg/arrow_back.svg';

import { StyledBackLink } from './BackLink.styled';

interface BackLinkProps {
  parentParentSlug?: string | null;
  route: string;
  parentSlug: string;
  label: string;
  className?: string;
  goBackSearch?: string;
  fullWidth?: boolean;
}

export const BackLink: React.FC<BackLinkProps> = ({
  parentParentSlug,
  route,
  parentSlug,
  label,
  className,
}) => {
  const link = constructBackLink(route, parentSlug, parentParentSlug);
  if (label) {
    link.title = label;
  }

  return (
    <StyledBackLink href={link.url} className={className}>
      <ArrowBack aria-hidden={true} focusable={false} width={18} height={18} />
      <span>{link.title}</span>
    </StyledBackLink>
  );
};
