import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

export const FilterLabel = styled(Paragraph).attrs({
  styleType: 'body14Medium',
})`
  padding-inline-start: ${spacing.xsmall};
`;

export const FilterWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  background-color: ${colors.black};
  height: 32px;
  width: 32px;
  border-radius: 16px;

  &:hover {
    background-color: ${colors.blackHover};
  }
`;

export const MobileFilterBar = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: none;
  margin-block: ${spacing.regular};
  margin-inline: ${spacing.small};
  background-color: transparent;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-inline-start: ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;
