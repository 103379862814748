import styled, { css } from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

interface StyledTechnicalDataBoxProps {
  $snr?: boolean;
}

export const StyledTechnicalDataBox = styled.div<StyledTechnicalDataBoxProps>`
  padding: ${spacing.regular};
  background-color: ${colors.gray5};

  ${({ $snr }) =>
    $snr
    && css`
      margin-block-end: ${spacing.regular};
      background-color: ${colors.hellTurqouiseLight};
    `}

  h2 {
    padding-block-end: ${spacing.small};
  }
`;

export const Section = styled.div`
  & + & {
    margin-block-start: ${spacing.small};
  }
`;
