import { CSSProperties } from 'styled-components';

import { GridChildColumnSpanProp, StyledGridChild } from './GridChild.styled';

interface GridChildProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  align?: CSSProperties['justifySelf'];
  alignSelf?: CSSProperties['alignSelf'];
  padding?: CSSProperties['padding'];
  rowStart?: CSSProperties['gridRowStart'];
  rowSpan?: CSSProperties['gridRowEnd'];
  columnSpan?: GridChildColumnSpanProp;
}
export const GridChild: React.FC<GridChildProps> = ({
  align = 'stretch',
  className,
  style,
  children,
  alignSelf,
  padding,
  rowStart,
  rowSpan,
  columnSpan,
}) => (
  <StyledGridChild
    className={`GridChild ${className}`.trim()}
    style={style}
    $align={align}
    $alignSelf={alignSelf}
    $padding={padding}
    $rowStart={rowStart}
    $rowSpan={rowSpan}
    $columnSpan={columnSpan}
  >
    {children}
  </StyledGridChild>
);
