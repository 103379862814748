import { Store } from '@hultafors/shared/types';

import ExternalLink from '../../svg/external_link.svg';

import { OnlineStoreListStyled } from './OnlineStoreList.styled';

interface OnlineStoreListProps {
  stores: Store[];
}

export const OnlineStoreList: React.FC<OnlineStoreListProps> = ({ stores }) => {
  return (
    <OnlineStoreListStyled>
      {stores.map((item, index) => {
        return (
          <a
            key={`Online-Store-${index}`}
            href={item.data.webSite ?? ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.data.companyName || item.data.webSite}
            <ExternalLink
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
          </a>
        );
      })}
    </OnlineStoreListStyled>
  );
};
