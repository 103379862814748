import { getFontColorBasedOnBg, IsTextDark } from '@hultafors/shared/helpers';

import { colors } from '@hultafors/hellberg/helpers';
import { ColorPlugFragment } from '@hultafors/hellberg/types';

import { ArrowLink } from '../ArrowLink/ArrowLink';

import {
  ColorPlugsBlockStyled,
  ContentWrapper,
  CtaLabel,
  PlugGrid,
  StyledColorPlug,
  Title,
} from './ColorPlugsBlock.styled';

interface ColorPlugsBlockProps {
  plugs: ColorPlugFragment[];
}

export const ColorPlugsBlock: React.FC<ColorPlugsBlockProps> = ({ plugs }) => {
  if (!plugs?.length) {
    return null;
  }

  const plugMapper = (
    item: ColorPlugFragment,
    index: number,
    all: ColorPlugFragment[],
  ) => {
    const bgColor = item?.color?.hex ?? colors.white;
    const isDarkText = IsTextDark(
      getFontColorBasedOnBg(bgColor, colors.white, colors.black),
    );
    const wide = !(index % 2) && index === all.length - 1;
    return (
      <ContentWrapper
        to={item.url || ''}
        $wide={wide}
        key={`${bgColor}-${index}`}
      >
        <StyledColorPlug $bgColor={bgColor}>
          <Title
            $isDarkText={isDarkText}
            styleType={[
              { styleType: 'header2' },
              {
                breakpoint: 'mobileMax',
                styleType: wide ? 'mega' : 'header2',
              },
            ]}
          >
            {item.title}
          </Title>
          <ArrowLink color={isDarkText ? 'black' : 'white'} large>
            <CtaLabel>{item.ctaLinkText}</CtaLabel>
          </ArrowLink>
        </StyledColorPlug>
      </ContentWrapper>
    );
  };

  return (
    <ColorPlugsBlockStyled>
      <PlugGrid>{plugs.map(plugMapper)}</PlugGrid>
    </ColorPlugsBlockStyled>
  );
};
