import { useEffect } from 'react';

interface ScrollToTopProps {
  children?: React.ReactNode;
  location?: any;
}
/**
 * This component will scroll to the top of the page when the location change
 * For instance, when a <Link /> (from react-router-dom) is clicked.
 */
export const ScrollToTop: React.FC<ScrollToTopProps> = ({
  children,
  location: { pathname },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return <>{children}</>;
};
