import { useEffect, useRef } from 'react';

import Script from 'next/script';

import { DotDigitalFormFragment } from '@hultafors/hellberg/types';

export const DotDigitalFormBlock: React.FC<DotDigitalFormFragment> = ({
  formUrl,
}) => {
  const iframeWrapperRef = useRef<HTMLDivElement | null>(null);

  /*
  Ugly hack to make the form render in the footer if
  the form is not a popover
  */
  function appendFormToFooter() {
    /* Removed specific class querySelectors
    because classnames change in production
    */
    const footerMiddleSection = document.getElementById(
      'footer-middle-section',
    );
    const iframeElement = document.querySelector(
      '._lpSurveyEmbed',
    ) as HTMLIFrameElement;
    const iframeWrapper = document.createElement('div');

    // Apply styles to iframe
    iframeElement.style.width = '100%';
    iframeElement.style.position = 'relative';

    // Apply styles to wrapper
    iframeWrapper.style.width = '100%';
    iframeWrapper.style.maxWidth = '600px';

    // Append to the footer
    iframeWrapper.appendChild(iframeElement);
    footerMiddleSection?.appendChild(iframeWrapper);

    iframeWrapperRef.current = iframeWrapper;
  }

  useEffect(() => {
    return () => {
      document.querySelector('#lpclose')?.dispatchEvent(new Event('click'));
    };
  }, []);

  useEffect(() => {
    return () => {
      // Remove the iframe element from the footer when the component unmounts
      if (iframeWrapperRef.current) {
        iframeWrapperRef?.current.remove();
      }
    };
  }, []);

  return formUrl?.includes('popover')
    ? (
      <Script src={formUrl || undefined} />
      )
    : (
      <Script src={formUrl || undefined} onReady={appendFormToFooter} />
      );
};
