import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

export const StyledQuote = styled.div`
  margin-inline: auto;

  &:empty {
    display: none;
  }
`;

export const ByLine = styled.div`
  display: flex;
  padding-block-start: ${spacing.xsmall};

  &:empty {
    display: none;
  }
`;

export const Author = styled(Paragraph).attrs({
  styleType: 'body14Medium',
})`
  padding-inline-end: ${spacing.xsmall};
`;

export const Profession = styled(Paragraph).attrs({
  styleType: 'body14',
})`
  color: ${colors.hellSalmon};
`;
