// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProductFilter } from '@hultafors/shared/types';

/**
 * Parses filters to URL search params
 * @param filters Array of product filters
 * @returns Object object of active filters
 */
export const getFilterQueryObject = (
  filters: ProductFilter[],
): Record<string, string> => {
  const params: Record<string, string> = {};

  let index = 0;
  filters.forEach(({ id: AttrId, values }) => {
    values
      .filter(({ active = false }) => !!active)
      .forEach(({ id: ValueId }) => {
        params[`f[${index}].AttrId`] = `${AttrId}`;
        params[`f[${index}].ValueId`] = `${ValueId}`;
        index += 1;
      });
  });

  return params;
};

/**
 * Parses filters to URL search params
 * @param filters Array of product filters
 * @returns URLSearchParams object of active filters
 */
export const getFilterQuery = (filters: ProductFilter[]): URLSearchParams => {
  return new URLSearchParams(getFilterQueryObject(filters));
};
