import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem),
);

export const ContactStoresStyled = styled.div`
  padding-block: 0;
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }
`;

export const OfficeAccordionItem = styled(AccordionItem)`
  padding: ${spacing.small};
`;
