import { QueryProductFilter } from '@hultafors/shared/types';

export function parseIncomingFilters(
  incoming: URLSearchParams,
): QueryProductFilter[] {
  const incomingFilters: QueryProductFilter[] = [];

  incoming.forEach((value, key) => {
    if (key.startsWith('f')) {
      const type = key.split('.')?.[1] ?? '';
      const index = parseInt(key.substring(2, key.indexOf(']')));
      let AttrId = '';
      if (incomingFilters[index]?.AttrId?.length) {
        AttrId = incomingFilters[index]?.AttrId ?? '';
      }
      if (type === 'AttrId') {
        AttrId = `${value}`;
      }
      let ValueId = '';
      if (incomingFilters[index]?.ValueId?.length) {
        ValueId = incomingFilters[index]?.ValueId ?? '';
      }
      if (type === 'ValueId') {
        ValueId = `${value}`;
      }
      incomingFilters[index] = {
        AttrId,
        ValueId,
      };
    }
  });
  return incomingFilters;
}
