import { IFrameWrapper } from './IFrameContainer.styled';

export type IFrameContainerProps =
  React.IframeHTMLAttributes<HTMLIFrameElement>;

export const IFrameContainer: React.FC<IFrameContainerProps> = ({
  title,
  src,
  height = '100%',
  width = '100%',
  ...rest
}) => {
  return (
    <IFrameWrapper $width={width} $height={height}>
      <iframe title={title} src={src} width={width} height={height} {...rest} />
    </IFrameWrapper>
  );
};
