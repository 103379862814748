import { useContext } from 'react';

import {
  MarketPickerContext,
  MarketPickerContextValue,
} from '@hultafors/shared/context';

export function useMarketPicker(): MarketPickerContextValue {
  const context = useContext(MarketPickerContext);
  if (!context) {
    throw new Error('useMarketPicker must be used within MarketPickerContext!');
  }
  return context;
}
