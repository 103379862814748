import { useState } from 'react';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/hellberg/hooks';
import { NavItemRecord } from '@hultafors/hellberg/types';

import { Cart } from '../Cart/Cart';
import { Favorites } from '../Favorites/Favorites';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { NavigationDesktop } from '../NavigationDesktop/NavigationDesktop';
import { NavigationMobile } from '../NavigationMobile/NavigationMobile';
import { NavigationWrapper } from '../NavigationWrapper/NavigationWrapper';
import { SearchMenu } from '../SearchMenu/SearchMenu';

import { StyledNav } from './Nav.styled';

export interface OpenSettings {
  desktopMenu: boolean;
  mobileMenu: boolean;
  favorites: boolean;
  cart: boolean;
  desktop: string;
  mobile: string;
  search: boolean;
}

export const Nav: React.FC = () => {
  const defaultOpenSettings: OpenSettings = {
    desktopMenu: false,
    mobileMenu: false,
    favorites: false,
    cart: false,
    desktop: '',
    mobile: '',
    search: false,
  };

  const { shared, allNavItems } = useGlobal();
  const [isOpen, setIsOpen] = useState(defaultOpenSettings);
  const { toggle } = useMarketPicker();

  const toggleMenu = () => {
    const open = !isOpen.mobileMenu;
    setIsOpen({ ...defaultOpenSettings, mobileMenu: open });
  };

  const toggleSearchMenu = () => {
    setIsOpen({ ...defaultOpenSettings, search: !isOpen.search });
  };

  const toggleFavoritesMenu = () => {
    const open = !isOpen.favorites;
    setIsOpen({ ...defaultOpenSettings, favorites: open });
  };

  const toggleCartMenu = () => {
    const open = !isOpen.cart;
    setIsOpen({ ...defaultOpenSettings, cart: open });
  };

  function toggleMarketPicker() {
    toggle(true);
  }

  return (
    <StyledNav>
      <NavigationWrapper isOpen={isOpen.desktopMenu}>
        <NavigationMobile
          setIsOpen={setIsOpen}
          defaultOpenSettings={defaultOpenSettings}
          toggleFavoritesMenu={toggleFavoritesMenu}
          toggleCartMenu={toggleCartMenu}
          toggleMenu={toggleMenu}
          toggleMarketPicker={toggleMarketPicker}
        />
        {allNavItems && (
          <NavigationDesktop
            setIsOpen={setIsOpen}
            defaultOpenSettings={defaultOpenSettings}
            menu={allNavItems}
            isOpen={isOpen}
            toggleFavoritesMenu={toggleFavoritesMenu}
            toggleCartMenu={toggleCartMenu}
            global={shared}
            toggleSearchMenu={toggleSearchMenu}
            toggleMarketPicker={toggleMarketPicker}
          />
        )}
      </NavigationWrapper>

      {isOpen.search && (
        <SearchMenu
          isOpen={isOpen.search}
          toggleSearchMenu={toggleSearchMenu}
          menuType="Search"
        />
      )}

      {isOpen.mobileMenu && allNavItems && (
        <MobileMenu
          isOpen={isOpen.mobileMenu}
          toggleMenu={toggleMenu}
          menu={allNavItems as NavItemRecord[]}
          partnerPortalUrl={shared.urlToPartnerPortal || ''}
          partnerLoginLabel={shared.partnerPortalLabel || ''}
        />
      )}

      {isOpen.favorites && (
        <Favorites
          isOpen={isOpen.favorites}
          toggleMenu={toggleFavoritesMenu}
          header={shared.favorites || ''}
          clearText={shared.clearAll || ''}
          badgeText={shared.new || ''}
          rrpLabel={shared.rrpLabel || ''}
          rrpIncludingVatLabel={shared.rrpIncludingVatLabel || ''}
          favoritesEmpty={shared.noFavorites || ''}
          isSmall
        />
      )}

      {isOpen.cart && (
        <Cart
          isOpen={isOpen.cart}
          toggleMenu={toggleCartMenu}
          header={shared.shoppingList ?? ''}
          clearText={shared.clearAll ?? ''}
          sendText={shared.send ?? ''}
          emailText={shared.email ?? ''}
          shoppingListText={shared.shoppingList ?? ''}
          sendShoppingListText={shared.send ?? ''}
          rrpLabel={shared.rrpLabel ?? ''}
          rrpIncludingVatLabel={shared.rrpIncludingVatLabel ?? ''}
          artNoLabel={shared.skuLabel ?? ''}
          cartEmpty={shared.shoppingListIsEmpty ?? ''}
        />
      )}
    </StyledNav>
  );
};
