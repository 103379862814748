import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

export const FooterStyledOld = styled.footer`
  width: 100%;
  margin-block-start: auto;
  background-color: ${colors.gray4};
  color: ${colors.black};

  .Grid {
    position: relative;
  }

  section {
    padding: ${spacing.small};
    padding-block-start: ${spacing.regular};
    margin: 0;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-block-start: ${spacing.regular};
      padding-inline: ${spacing.medium};
    }
  }

  .FooterFunctions {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    margin-block-end: ${spacing.regular};
    position: absolute;
    inset-block-start: 2px;
    inset-inline-end: 0;

    .PartnerLoginLink {
      display: flex;
      align-items: center;
      font-size: ${fontSizes.body16};
      line-height: ${lineHeights.body10};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontRegular};
      color: ${colors.hellBlue};
      text-decoration: none;

      svg {
        margin-inline-start: ${spacing.xsmall};

        path {
          stroke: ${colors.hellBlue};
        }
      }

      &:hover {
        color: ${colors.hellBlueHover};

        svg {
          path {
            stroke: ${colors.hellBlueHover};
          }
        }
      }
    }
  }
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-block-start: ${spacing.small};
  margin-block-end: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  flex-basis: 55%;
  text-align: left;

  .IntroText {
    padding-block: 6px ${spacing.regular};
  }

  p,
  a {
    text-align: left;
    margin-block: 0 4px;
    max-width: 600px;
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body12};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontRegular};
    color: ${colors.black};
    word-break: keep-all;
    padding-block: 4px;
    padding-inline: 3px;

    &:hover {
      color: ${colors.blackHover};
    }
  }

  .markdown-wrapper {
    margin-block-end: 4px;
  }
`;

export const SubscribeAreaWrapper = styled.div`
  flex-basis: 25%;
  margin-block-end: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-end: 0;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  font-size: ${fontSizes.body14};
  line-height: ${lineHeights.body14};
  font-weight: ${fontWeights.fontRegular};
  font-family: ${fontFamilies.fontRegular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
    align-items: center;
  }

  .SocialMedia {
    display: flex;
    padding-block-end: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-block-end: 0;
    }

    a {
      width: 32px;
      height: 32px;

      img {
        width: 100%;
      }

      &:not(:last-of-type) {
        margin-inline-end: ${spacing.small};
      }
    }
  }
`;
export const BottomSectionLinks = styled.nav`
  display: flex;
  flex-wrap: wrap;

  /* gap: ${spacing.small}; */

  @media screen and (hover: none), (max-width: ${breakpoints.mobileMax}) {
    > * {
      min-height: 48px;
      min-width: 48px;
    }
  }

  > * {
    position: relative;
    padding-block: 2px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-inline-end: ${spacing.small};
    }
  }

  > *:not(:last-child)::after {
    content: '|';
    position: absolute;
    inset-inline-end: -10px;

    /* display: block; */

    /* width: 20px; */
  }

  a {
    text-decoration: none;
    color: ${colors.black};
  }
`;
export const CookieSettings = styled.button`
  ${buttonReset};
  cursor: pointer;
  line-height: ${lineHeights.body14};
  font-weight: ${fontWeights.fontRegular};
  font-family: ${fontFamilies.fontRegular};
`;

export const FooterHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-block: ${spacing.small} ${spacing.block};
`;

export const LogoLink = styled(Link)`
  display: flex;
  color: ${colors.black};
  align-items: center;
  min-height: 48px;
  min-width: 48px;
`;
export const PartnerLoginLink = styled(Link)`
  display: flex;
  gap: ${spacing.xxsmall};
  align-items: center;
  min-height: 48px;
  min-width: 48px;
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.body10};
  font-weight: ${fontWeights.fontRegular};
  font-family: ${fontFamilies.fontRegular};
  color: ${colors.hellBlue};
  text-decoration: none;

  &:hover {
    color: ${colors.hellBlueHover};
  }
`;

export const AppsWrapper = styled.div`
  max-width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-block-start: ${spacing.regular};

  > div {
    max-width: fit-content;
    margin: 0;

    > div {
      gap: 10px;
      justify-content: space-between;

      :first-child {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: flex-end;
    margin-block-start: 0;
  }
`;
