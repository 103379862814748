import { TextLink } from '../TextLink/TextLink';

import { LinkBlockStyled } from './LinkBlock.styled';
interface LinkBlockProps {
  linkText?: string;
  linkUrl?: string;
  external?: boolean;
  square?: boolean;
}
export const LinkBlock: React.FC<LinkBlockProps> = ({
  linkUrl,
  linkText,
  external,
  square,
}) => {
  return (
    <LinkBlockStyled $square={square}>
      {linkUrl && linkText && (
        <TextLink className="TextLink" to={linkUrl} external={external}>
          {linkText}
        </TextLink>
      )}
    </LinkBlockStyled>
  );
};
