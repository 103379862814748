import styled, { CSSProperties } from 'styled-components';

interface IFrameWrapperProps {
  $height?: CSSProperties['height'];
  $width?: CSSProperties['width'];
}

export const IFrameWrapper = styled.div<IFrameWrapperProps>`
  overflow: auto;
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};

  iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }
`;
