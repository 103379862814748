import styled, { css } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hellberg/helpers';

type HTagStyleType = 'mega' | 'header1' | 'header2' | 'header3' | 'header4';

interface HTagStyleTypeBreak {
  breakpoint?: Breakpoint;
  styleType: HTagStyleType;
}

export type HTagStyleTypeProp = HTagStyleType | HTagStyleTypeBreak[];

interface StyledHTagProps {
  $center?: boolean;
  $styleType?: HTagStyleTypeProp;
}

export const styleMega = css`
  font-size: ${fontSizes.mega};
  line-height: ${lineHeights.mega};
  font-weight: ${fontWeights.fontMedium};
  font-family: ${fontFamilies.fontHero};
`;

export const styleHeader1 = css`
  font-size: ${fontSizes.header1};
  line-height: ${lineHeights.header1};
  font-weight: ${fontWeights.fontMedium};
  font-family: ${fontFamilies.fontHero};
`;

export const styleHeader2 = css`
  font-size: ${fontSizes.header2};
  line-height: ${lineHeights.header2};
  font-weight: ${fontWeights.fontMedium};
  font-family: ${fontFamilies.fontHero};
`;

export const styleHeader3 = css`
  font-size: ${fontSizes.header3};
  line-height: ${lineHeights.header3};
  font-weight: ${fontWeights.fontMedium};
  font-family: ${fontFamilies.fontHero};
`;

export const styleHeader4 = css`
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontMedium};
  font-family: ${fontFamilies.fontHero};
`;

const fontStyle = ($styleType?: HTagStyleType) => {
  if ($styleType === 'mega') {
    return styleMega;
  }
  if ($styleType === 'header2') {
    return styleHeader2;
  }
  if ($styleType === 'header3') {
    return styleHeader3;
  }
  if ($styleType === 'header4') {
    return styleHeader4;
  }
  return styleHeader1;
};

const handleStyleType = ({ $styleType }: StyledHTagProps) => {
  if (Array.isArray($styleType)) {
    return $styleType.map(({ breakpoint, styleType }) => {
      if (breakpoint) {
        return css`
          @media all and (min-width: ${breakpoints[breakpoint]}) {
            ${fontStyle(styleType)}
          }
        `;
      }
      return fontStyle(styleType);
    });
  }
  return fontStyle($styleType);
};

function textAlign({ $center }: StyledHTagProps) {
  if ($center) {
    return css`
      text-align: center;
    `;
  }
  return css`
    text-align: initial;
  `;
}

export const StyledHTag = styled.h1<StyledHTagProps>`
  letter-spacing: 0.01em;
  color: ${colors.black};

  ${textAlign};
  ${handleStyleType}
  &:empty {
    display: none;
  }
`;
