import Link from 'next/link';

import { StyledRouterLink } from './RouterLink.styled';

export interface RouterLinkProps {
  dataValue?: string;
  to: string;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  search?: string;
  linkType?: string;
}

export const RouterLink: React.FC<RouterLinkProps> = ({
  dataValue,
  to,
  children,
  className,
  id,
  onClick,
  linkType,
}) => {
  if (to.startsWith('http')) {
    return (
      <StyledRouterLink
        $linkType={linkType}
        className={className}
        data-value={dataValue}
      >
        <Link href={to} target="_blank" rel="noreferrer noopener">
          {children}
        </Link>
      </StyledRouterLink>
    );
  }
  return (
    <StyledRouterLink
      $linkType={linkType}
      className={className}
      data-value={dataValue}
    >
      <Link href={to} id={id} onClick={onClick}>
        {children}
      </Link>
    </StyledRouterLink>
  );
};
