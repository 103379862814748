import { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  GridColumnGapProp,
  GridColumnsProp,
  GridRowGapProp,
  StyledGrid,
} from './Grid.styled';

interface GridProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  autoFlow?: CSSProperties['gridAutoFlow'];
  align?: CSSProperties['alignItems'];
  margin?: CSSProperties['margin'];
  maxWidth?: CSSProperties['maxWidth'];
  center?: boolean;
  minColumnWidth?: string;
  rowGap?: GridRowGapProp;
  columnGap?: GridColumnGapProp;
  columns?: GridColumnsProp;
}

export const Grid: React.FC<GridProps> = ({
  children,
  align = 'stretch',
  columnGap = [{ columnGap: 40 }, { breakpoint: 'mobileMax', columnGap: 20 }],
  columns = [{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }],
  margin = '0 auto',
  maxWidth = breakpoints.maxPageWidth,
  rowGap = 0,
  autoFlow = 'row',
  minColumnWidth,
  className,
  style,
  center,
}) => {
  if (!children) {
    return null;
  }
  return (
    <StyledGrid
      className={`Grid ${className}`.trim()}
      style={style}
      $align={align}
      $columnGap={columnGap}
      $columns={columns}
      $margin={margin}
      $maxWidth={maxWidth}
      $rowGap={rowGap}
      $autoFlow={autoFlow}
      $minColumnWidth={minColumnWidth}
      $center={center}
    >
      {children}
    </StyledGrid>
  );
};
