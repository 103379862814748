import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

export const ModelFeatureTableBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 40px);
  width: 1000px;
  margin-inline: auto;
  margin-block: ${spacing.large};
  gap: 50px;
`;

export const BlockHeader = styled.div`
  font-style: normal;
  font-family: ${fontFamilies.fontRegularBold};
  font-weight: ${fontWeights.fontMedium};
  font-size: ${fontSizes.body14};
  line-height: ${lineHeights.body14};

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
  }
`;

export const FeatureTable = styled.table`
  width: 100%;
  font-size: ${fontSizes.microcopy};

  tr {
    border-block-end: 1px solid ${colors.gray4};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: ${fontSizes.s};
  }
`;

export const ModelName = styled.th.attrs({ scope: 'col' })<{ $color?: string }>`
  text-align: left;
  color: ${({ $color }) => $color ?? 'black'};
  padding-block-end: 8px;
`;

export const FeatureLabel = styled.th.attrs({ scope: 'row' })`
  color: black;
  text-align: left;
  vertical-align: top;
  padding-block: ${spacing.xsmall};
  padding-inline-end: ${spacing.medium};
`;

export const FeatureValue = styled.td`
  color: black;
  text-align: left;
  max-width: 100px;
  padding-block: ${spacing.xsmall};
  padding-inline-end: ${spacing.xsmall};
  vertical-align: top;
`;

export const TableRow = styled.tr`
  border-block-end: 1px solid ${colors.gray4};

  &:last-of-type {
    border-block-end: none;
  }
`;
