import { Paragraph } from '../Paragraph/Paragraph';
import { RouterLink } from '../RouterLink/RouterLink';

import { Wrapper } from './ArrowLink.styled';
import BigArrowSvg from './big-arrow.svg';
import SecondArrowSvg from './second-arrow.svg';
import SmallArrowSvg from './small-arrow.svg';

interface ArrowLinkProps {
  url?: string;
  color?: 'black' | 'blue' | 'white';
  large?: boolean;
  smallText?: boolean;
  secondArrow?: boolean;
  rotateIcon?: boolean;
  search?: string;
  className?: string;
  children?: React.ReactNode;
}

export const ArrowLink: React.FC<ArrowLinkProps> = ({
  color = 'blue',
  className,
  search = '',
  url,
  large,
  smallText,
  secondArrow,
  rotateIcon,
  children,
}) => {
  // Get correct arrow icon
  const Icon: React.FC = () => {
    if (secondArrow) {
      return <SecondArrowSvg aria-hidden={true} focusable={false} width={16} />;
    }
    if (large) {
      return <BigArrowSvg aria-hidden={true} focusable={false} width={24} />;
    }
    return <SmallArrowSvg aria-hidden={true} focusable={false} width={16} />;
  };

  // Get link or just text label
  const Label: React.FC = () => {
    if (url) {
      return (
        <RouterLink to={url} search={search}>
          {children}
        </RouterLink>
      );
    }
    if (typeof children === 'string') {
      return <Paragraph styleType="body14">{children}</Paragraph>;
    }
    return children;
  };

  return (
    <Wrapper
      $smallText={smallText}
      $rotateIcon={rotateIcon}
      className={className}
      $color={color}
    >
      <Icon />
      <Label />
    </Wrapper>
  );
};
