import { QuoteFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';

import { Author, ByLine, Profession, StyledQuote } from './QuoteBlock.styled';

export const QuoteBlock: React.FC<QuoteFragment> = ({
  body,
  author,
  authorProfession,
}) => {
  return (
    <StyledQuote>
      <div>
        <div>
          <HTag type="h2" styleType="header1">
            &quot;
            {body}
            &quot;
          </HTag>
          <ByLine>
            {author && <Author>{author}</Author>}
            {authorProfession && <Profession>{authorProfession}</Profession>}
          </ByLine>
        </div>
      </div>
    </StyledQuote>
  );
};
