import { LanguageData } from '@hultafors/shared/types';

export const findActiveMarket = (markets: LanguageData[], active: string) =>
  markets?.find((market: LanguageData) => {
    if (!market.url) {
      return null;
    }

    const marketUrl = new URL(market.url);
    const marketHostnamePath = [
      marketUrl.hostname,
      marketUrl.pathname.replace(/^\//, '').replace(/\/$/, ''),
    ]
      .filter(Boolean)
      .join('/');

    return marketHostnamePath.replace('https://', '') === active;
  }) ?? null;
