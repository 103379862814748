import Link from 'next/link';
import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

interface ButtonLinkStyledProps {
  $color?: string;
}

export const StyledButtonLink = styled(Link)<ButtonLinkStyledProps>`
  appearance: none;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${spacing.medium};
  padding: 0 ${spacing.regular} 0 ${spacing.regular};
  box-sizing: border-box;
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.body16};
  font-weight: normal;
  color: ${colors.white};
  border-radius: 1px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;

  &.Black {
    border: 1px solid ${colors.black};
    background-color: ${colors.black};
  }

  &.Outline {
    background-color: transparent;
    border: 1px solid ${colors.black};
    color: ${colors.black};

    &:hover {
      background-color: ${colors.black};
      color: ${colors.white};
    }
  }

  &.OutlineWhite {
    background-color: transparent;
    border: 1px solid ${colors.white};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.white};
      color: ${colors.black};
    }
  }

  &.White {
    background-color: ${colors.gray5};
    border: 1px solid ${colors.gray4};
    color: ${colors.black};

    &:hover {
      background-color: ${colors.gray4};
      border: 1px solid ${colors.gray3};
    }
  }

  &.Blue {
    background-color: ${colors.hellBlue};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.hellBlueHover};
    }
  }

  &.DatoColor {
    background-color: ${({ $color }) => $color};
    color: ${colors.black};

    &:hover {
      box-shadow: inset 0 0 0 10em rgb(255 255 255 / 20%);
    }
  }

  &:disabled {
    cursor: not-allowed;
    border: 0;
    color: ${colors.black};
    background-color: ${colors.gray5};
    transition: 0.2s;
    opacity: 0.5;

    svg {
      opacity: 0.3;
    }
  }
`;
