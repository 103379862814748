import { Fragment } from 'react';

import {
  DocumentsPageFragment,
  DocumentsSectionFragment,
  isDocumentBlockNew,
  isHeaderBlock,
} from '@hultafors/hellberg/types';

import { DownloadFileBox } from '../DownloadFileBox/DownloadFileBox';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  DocumentPageStyled,
  StyledDocumentsContent,
} from './DocumentPageContent.styled';

interface DocumentPageContentProps {
  content: DocumentsPageFragment;
}

export const DocumentPageContent: React.FC<DocumentPageContentProps> = ({
  content,
}) => {
  function documentMapper(item: DocumentsSectionFragment, index: number) {
    const key = `DC-${index}`;
    if (isHeaderBlock(item) && item.isBigHeader) {
      return (
        <Fragment key={key}>
          <HTag
            className="Header Big"
            type="h3"
            styleType={[
              { styleType: 'header4' },
              {
                breakpoint: 'mobileMax',
                styleType: 'header3',
              },
            ]}
          >
            {item.header}
          </HTag>
        </Fragment>
      );
    }
    if (isHeaderBlock(item)) {
      return (
        <Fragment key={key}>
          <HTag
            className="Header Regular DesktopOnly"
            type="h3"
            styleType="header4"
          >
            {item.header}
          </HTag>
          <Paragraph
            className="Header Regular MobileOnly"
            styleType="body16Medium"
          >
            {item.header}
          </Paragraph>
        </Fragment>
      );
    }
    if (isDocumentBlockNew(item)) {
      return (
        <Fragment key={key}>
          <DownloadFileBox
            className="DownloadBox"
            url={item.document?.url || ''}
            label={item.title ? item.title : item.document?.filename || ''}
            filetype={item.document?.format || ''}
            size={item.document?.size || ''}
          />
        </Fragment>
      );
    }
    return null;
  }
  return (
    <DocumentPageStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 9, start: 2 },
            { breakpoint: 'desktop', columns: 7, start: 4 },
          ]}
        >
          <HTag
            className="HeroTitle"
            type="h1"
            styleType={[
              { styleType: 'header3' },
              { breakpoint: 'mobileMax', styleType: 'header2' },
            ]}
          >
            {content.pageHeader}
          </HTag>
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 9, start: 2 },
            { breakpoint: 'desktop', columns: 7, start: 4 },
          ]}
        >
          <StyledDocumentsContent>
            {content.documentsSection.map(documentMapper)}
          </StyledDocumentsContent>
        </GridChild>
      </Grid>
    </DocumentPageStyled>
  );
};
