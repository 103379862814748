export const fontSizes = {
  mega: '60px',
  header1: '40px',
  header2: '34px',
  header3: '26px',
  header4: '20px',
  body16: '16px',
  body14: '14px',
  body12: '12px',
  body10: '10px',

  // old should be replaced
  microcopy: '0.625rem',
  s: '0.75rem',
  m: '1rem',
  l: '1.25rem',
  h4: '1.25rem',
  h3: '2rem',
  h2: '2.5rem',
  h1: '3rem',
} as const;

export type FontSize = keyof typeof fontSizes;
