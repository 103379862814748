import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem),
);

export const MenuStyled = styled.div`
  .SlideInInside {
    .SlideInHeader {
      position: relative;
      border: none;
    }

    .SlideInBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-block: 0 ${spacing.regular};
    }
  }

  .MenuWrapper {
    min-height: 78%;
    margin-block-start: ${spacing.small};
  }

  .PartnerLogin {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-inline-end: ${spacing.small};

    p {
      width: 100%;
    }

    .PartnerLoginLink {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-decoration: none;
      font-size: ${fontSizes.body16};
      font-weight: ${fontWeights.fontRegular};
      color: ${colors.hellBlue};

      &:hover {
        color: ${colors.hellBlueHover};
      }

      svg {
        margin-inline-start: ${spacing.xsmall};
      }
    }
  }

  .Hide {
    display: none;
  }

  .SearchWrapper {
    padding: ${spacing.xsmall} ${spacing.small} ${spacing.medium} 0;
  }

  .CollapseHeader {
    transition: margin-bottom 0.3s;
  }

  .CollapseHeader.isOpen {
    margin-block-end: ${spacing.small};
  }

  .CollapseContent.isOpen {
    max-height: 600px;
  }

  .CategoryLabel {
    font-size: ${fontSizes.body12};
    line-height: ${lineHeights.body12};
    color: ${colors.gray0};
  }
`;

const MenuLinkStyle = css`
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding-block: 12px;

  &:empty {
    display: none;
  }
`;

interface MenuLinkProps {
  $level?: number;
  $weight?: number;
  $size?: number;
}

export const MenuLink = styled(RouterLink)<MenuLinkProps>`
  ${MenuLinkStyle};
  &:empty {
    display: none;
  }

  font-weight: ${({ $weight }) => $weight};
  font-size: ${({ $size }) => `${$size}px`};
  ${({ $level }) =>
    $level
    && css`
      padding-inline-start: ${$level * 10}px;
    `};
`;

export const MenuLabel = styled.div`
  font-size: 26px;
  font-family: ${fontFamilies.fontHero};
`;

export const MenuAccordionItem = styled(AccordionItem)`
  padding-block: 12px;
`;
