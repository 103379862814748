import styled from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  spacing,
} from '@hultafors/hellberg/helpers';

export const TextButtonStyled = styled.button`
  margin: 0;
  padding: ${spacing.small};
  background-color: transparent;
  border: none;
  appearance: none;
  font-family: ${fontFamilies.fontRegular};
  font-size: ${fontSizes.body16};
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: ${colors.black};
  }

  :hover {
    cursor: pointer;

    p {
      color: ${colors.blackHover};
    }
  }

  &:focus {
    outline: none;
  }
`;
