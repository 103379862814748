import styled, { css } from 'styled-components';

import { colors } from '@hultafors/hellberg/helpers';

import { Input } from '../Input/Input';

interface TallProps {
  $tall?: boolean;
}

export const SearchFieldWrapper = styled.div<TallProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $tall }) => ($tall ? '150px' : 'auto')};
  width: 100%;
  flex: 1;
`;

export const SearchField = styled(Input)`
  width: 100%;
  margin: 0;

  input[type='text'] {
    margin: 0;
    padding: 0 40px 0 46px;
    line-height: 3rem;
    color: ${colors.black};
    background-color: ${colors.white};
  }

  input:placeholder-shown {
    border-color: ${colors.black};
  }

  input::placeholder {
    color: ${colors.black};
  }
`;

export const SearchFieldForm = styled.form`
  width: 100%;
  max-width: 400px;
  position: relative;
  display: flex;
  justify-content: center;
`;

const buttonStyles = css`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  inset-block-start: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  height: 24px;
  width: 24px;
  border: none;
  background: none;
`;

export const ClearButton = styled.button`
  ${buttonStyles};
  inset-inline-end: 8px;
  transition: opacity 0.1s ease-out;
  color: ${colors.hellBlue};
`;

export const SearchButton = styled.button`
  ${buttonStyles};
  color: ${colors.black};
  inset-inline-start: 12px;
  z-index: 1;
`;
