import { ImageFragment } from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { LinkBlock } from '../LinkBlock/LinkBlock';
import { SquareImage } from '../SquareImage/SquareImage';

import {
  BigPlugStyled,
  ContentWrapper,
  StyledGridChild,
  Title,
} from './BigPlugsBlock.styled';

interface BigPlugProps {
  title: string;
  image: ImageFragment;
  ctaText: string;
  ctaUrl: string;
}
// TODO delete component when contentArea fully implemented
export const BigPlugsBlock: React.FC<BigPlugProps> = ({
  title,
  image,
  ctaUrl,
  ctaText,
}) => (
  <BigPlugStyled>
    <Grid columnGap={0} rowGap={0} autoFlow="dense">
      <StyledGridChild
        columnSpan={[
          { columns: 4 },
          { breakpoint: 'mobileMax', columns: 12, start: 1 },
          { breakpoint: 'desktop', columns: 6, start: 7 },
        ]}
      >
        <SquareImage image={image} />
      </StyledGridChild>
      <StyledGridChild
        columnSpan={[
          { columns: 4 },
          { breakpoint: 'mobileMax', columns: 12, start: 1 },
          { breakpoint: 'desktop', columns: 6, start: 1 },
        ]}
      >
        <ContentWrapper>
          <Title>{title}</Title>
          <LinkBlock linkText={ctaText} linkUrl={ctaUrl} square />
        </ContentWrapper>
      </StyledGridChild>
    </Grid>
  </BigPlugStyled>
);
