import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { styleHeader1, styleHeader3, styleHeader4 } from '../HTag/HTag.styled';

export const StyledGrid = styled(Grid).attrs({ autoFlow: 'dense' })`
  border-block-start: 1px solid ${colors.gray4};
  padding-block: ${spacing.medium};
  padding-inline: 0;
`;

export const StyledGridChild = styled(GridChild).attrs({
  rowSpan: '3',
  columnSpan: [
    { columns: 12 },
    { breakpoint: 'mobileMax', columns: 12, start: 1 },
    { breakpoint: 'desktopSmall', columns: 7, start: 1 },
  ],
})`
  margin-block-end: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin-block-end: ${spacing.regular};
  }

  ul {
    padding: 0;
    list-style-position: inside;
  }

  li,
  p,
  a {
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontRegular};
  }

  a {
    display: block;
    text-decoration: none;
    text-decoration: underline;
    color: ${colors.hellBlue};

    &:hover {
      color: ${colors.hellBlueHover};
    }
  }

  p + p {
    margin-block-start: ${spacing.small};
  }

  * + *:not(p) {
    margin-block-start: ${spacing.regular};
  }

  *:not(p) + p {
    margin-block-start: ${spacing.xxsmall};
  }

  h1 {
    ${styleHeader1};
  }

  h2 {
    ${styleHeader3};
  }

  h3 {
    ${styleHeader4};
  }

  h4,
  h5,
  h6 {
    ${styleHeader4};
  }


`;

export const TechInfo = styled(GridChild)`
 grid-column: span 12;
 display: flex;
 gap: 25px;
 flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    gap: 20px;
   }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    grid-column: 9 / span 4;
    flex-direction: column;

   }
`;

export const TechBoxWrapper = styled.div`
  inline-size: 100%;

  > div {
    margin: 0;
  }
`;

export const StyledProductMoreInformation = styled.div`
  padding-block: 0;
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-block: 0;
    padding-inline: ${spacing.medium};
  }
`;
