import { StoreBrand } from '@hultafors/shared/types';

import ArrowRight from '../../svg/arrow_right.svg';
import ExternalLink from '../../svg/external_link.svg';
import Mail from '../../svg/mail.svg';
import Phone from '../../svg/phone.svg';
import PlaceTag from '../../svg/place_tag.svg';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  Address,
  IconLink,
  LinkWrapper,
  Separator,
  StoreItemStyled,
  Stores,
  StoreSells,
  StoresWrapper,
  StoreText,
  VisitSite,
} from './StoreItem.styled';

interface StoreItemProps {
  label?: string | null;
  subLabel?: string | null;
  keyPrefix?: string | null;
  id?: string | null;
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  mapLink?: string | null;
  view_on_map?: string | null;
  emailAddress?: string | null;
  phone?: string | null;
  storeSells?: StoreBrand[];
  this_store_also_sells?: string | null;
  website?: string | null;
  visit_web_site?: string | null;
  local_web?: string | null;
  openingHours?: string | null;
  coordinates?: {
    lng?: string | number;
    lat?: string | number;
  };
}

export const StoreItem: React.FC<StoreItemProps> = ({
  address,
  zipCode,
  city,
  country,
  mapLink,
  view_on_map,
  emailAddress,
  phone,
  storeSells,
  this_store_also_sells,
  website,
  visit_web_site,
  local_web,
  openingHours,
}) => {
  return (
    <StoreItemStyled>
      <Address>
        {address && address}
        <br />
        {zipCode && zipCode}
        {' '}
        {city && city}
        <br />
        {country && country}
      </Address>
      {openingHours && <Paragraph styleType="body16">{openingHours}</Paragraph>}
      <LinkWrapper>
        {view_on_map && mapLink && (
          <IconLink href={mapLink} target="_blank" rel="noopener noreferrer">
            <PlaceTag
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
            {view_on_map}
          </IconLink>
        )}
        {emailAddress && (
          <IconLink href={`mailto:${emailAddress}`}>
            <Mail aria-hidden={true} focusable={false} width={16} height={16} />
            {emailAddress}
          </IconLink>
        )}
        {phone && (
          <IconLink href={`tel:${phone}`}>
            <Phone
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
            {phone}
          </IconLink>
        )}
      </LinkWrapper>

      {storeSells && storeSells.length > 0 && (
        <StoreSells>
          <StoresWrapper>
            <StoreText>{this_store_also_sells}</StoreText>
            <Stores>
              {storeSells.map((brand, i) => {
                return (
                  <div key={`Brand-${i}`}>
                    <a
                      href={brand.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brand.name}
                    </a>
                    {i < storeSells.length - 1 && <Separator>|</Separator>}
                  </div>
                );
              })}
            </Stores>
          </StoresWrapper>
        </StoreSells>
      )}
      {website && (
        <VisitSite>
          <IconLink href={website} target="_blank" rel="noopener noreferrer">
            {visit_web_site && visit_web_site}
            {visit_web_site && (
              <ExternalLink
                aria-hidden={true}
                focusable={false}
                width={16}
                height={16}
              />
            )}
            {local_web && local_web}
            {local_web && (
              <ArrowRight
                aria-hidden={true}
                focusable={false}
                width={16}
                height={16}
              />
            )}
          </IconLink>
        </VisitSite>
      )}
    </StoreItemStyled>
  );
};
