import styled from 'styled-components';

import { colors } from '@hultafors/hellberg/helpers';

import CloseCircle from '../../svg/close_circle.svg';
import Favorite from '../../svg/favorite.svg';

interface FavoriteStyledProps {
  $isFavorite?: boolean;
}

export const FavoriteStyled = styled.span<FavoriteStyledProps>`
  display: inline-block;
  cursor: pointer;
  z-index: 2;
`;

export const StyledRemoveIcon = styled(CloseCircle)`
  color: ${colors.hellBlue};

  &:hover {
    color: ${colors.hellBlueHover};
  }
`;

export const StyledFavoriteIcon = styled(Favorite)<FavoriteStyledProps>`
  color: ${({ $isFavorite }) => ($isFavorite ? colors.black : 'transparent')};
`;
