import { useGlobal } from '@hultafors/hellberg/hooks';

import CloseCircle from '../../svg/close_circle.svg';
import Search from '../../svg/search.svg';

import {
  ClearButton,
  SearchButton,
  SearchField,
  SearchFieldForm,
  SearchFieldWrapper,
} from './SearchInput.styled';

interface SearchInputProps {
  onSubmit: React.FormEventHandler;
  onChange: React.ChangeEventHandler;
  onClear(): void;
  value: string;
  name?: string;
  placeholder?: string;
  className?: string;
  tall?: boolean;
  searchLabel?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  onSubmit,
  onChange,
  onClear,
  name = 'q',
  placeholder,
  value,
  tall,
  searchLabel,
  className,
}) => {
  const { shared } = useGlobal();
  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      onClear();
    }
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };
  return (
    <SearchFieldWrapper $tall={tall} className={className}>
      <SearchFieldForm onSubmit={onSubmit} autoComplete="off">
        <SearchButton
          disabled={!value.length}
          type="submit"
          aria-label={searchLabel || shared?.searchProducts || ''}
        >
          <Search aria-hidden={true} focusable={false} width={18} height={18} />
        </SearchButton>
        <SearchField
          type="text"
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          name={name}
          onKeyDown={handleKeyDown}
        />
        {value && (
          <ClearButton onClick={onClear} aria-label={shared?.clearAll || ''}>
            <CloseCircle
              aria-hidden={true}
              focusable={false}
              width={18}
              height={18}
            />
          </ClearButton>
        )}
      </SearchFieldForm>
    </SearchFieldWrapper>
  );
};
