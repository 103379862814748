import { ProductFilter, QueryProductFilter } from '@hultafors/shared/types';

import FilterSvg from '../../svg/filter.svg';
import { BoxFilters } from '../BoxFilters/BoxFilters';
import { ButtonFilters } from '../ButtonFilters/ButtonFilters';
import { Section } from '../Section/Section';

import { FilterLabel, FilterWrapper, MobileFilterBar } from './Filter.styled';

interface FilterProps {
  filters: ProductFilter[];
  filterLabel: string;
  clearFilterLabel: string;
  selectedFilters: ProductFilter[];
  filterChange({ AttrId, ValueId }: QueryProductFilter): void;
  clearFilter(): void;
  typeLabel: string;
  toggleFilter(): void;
  loading?: boolean;
}

export const Filter: React.FC<FilterProps> = ({
  filters,
  filterLabel,
  selectedFilters,
  filterChange,
  clearFilter,
  clearFilterLabel,
  typeLabel,
  toggleFilter,
}) => {
  if (!filters?.length) {
    return null;
  }
  return (
    <div>
      <Section noMargin>
        <ButtonFilters
          filters={filters}
          filterChange={filterChange}
          typeLabel={typeLabel}
        />
        <MobileFilterBar onClick={toggleFilter}>
          <FilterWrapper>
            <FilterSvg
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
          </FilterWrapper>
          <FilterLabel>{filterLabel}</FilterLabel>
        </MobileFilterBar>
        <BoxFilters
          filters={filters}
          selectedFilters={selectedFilters}
          filterChange={filterChange}
          clearFilter={clearFilter}
          clearFilterLabel={clearFilterLabel}
        />
      </Section>
    </div>
  );
};
