import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const NavigationWrapperStyled = styled.div`
  position: fixed;
  inset-block-start: 0;
  width: 100%;
  height: var(--header-height, ${spacing.menuHeight});
  text-align: center;
  z-index: 10;
  background-color: ${colors.white};

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-block-end: 1px solid ${colors.gray4};
    padding-block: 0;
    padding-inline: ${spacing.medium};
  }
`;
