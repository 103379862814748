import { Link } from '../Link/Link';

import { LinkButtonStyled } from './LinkButton.styled';

interface LinkButtonProps {
  className?: string;
  fullWidth?: boolean;

  text?: string;
  mailToLink?: string;
  url?: string;
  mailTo?: boolean;
  black?: boolean;
  icon?: React.ReactNode;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  className,
  fullWidth,
  mailTo,
  mailToLink,
  url,
  text,
  black,
  icon,
}) => {
  if (mailTo) {
    return (
      <LinkButtonStyled
        className={className}
        $fullWidth={fullWidth}
        $black={black}
      >
        {icon}
        <a
          href={`mailto:${mailToLink}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      </LinkButtonStyled>
    );
  }
  return (
    <LinkButtonStyled className={className} $fullWidth={fullWidth}>
      {icon}
      (
      <Link href={url || ''}>{text}</Link>
      )
    </LinkButtonStyled>
  );
};
