import { GaugeStyled, SoFar, ToGo } from './Gauge.styled';

export interface GaugeProps {
  soFar: number;
  toGo: number;
  className?: string;
}

export const Gauge: React.FC<GaugeProps> = ({ soFar, toGo, className }) => {
  return (
    <GaugeStyled className={className}>
      <SoFar $value={soFar} />
      <ToGo $value={toGo} />
    </GaugeStyled>
  );
};
