import { CSSProperties } from 'styled-components';

import { ImageBasicFragment, ImageFragment } from '@hultafors/shared/types';

import Logo from '../../svg/logo.svg';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { HeroImage } from '../HeroImage/HeroImage';
import { HTag } from '../HTag/HTag';

import { ContentWrapper, HeroIcon, StyledHero } from './Hero.styled';

interface HeroProps {
  title: string;
  heroBorder?: boolean;
  heroCtaTitle1?: string;
  heroCtaUrl1?: string;
  ctaColor?: string;
  heroCtaTitle2?: string;
  heroCtaUrl2?: string;
  className?: string;
  mobileImage?: ImageFragment;
  tabletImage?: ImageFragment;
  desktopImage?: ImageFragment;
  icon?: ImageBasicFragment;
  priority?: boolean;
}

export const Hero: React.FC<HeroProps> = ({
  title,
  heroCtaTitle1,
  heroCtaUrl1,
  ctaColor,
  heroBorder,
  heroCtaTitle2,
  heroCtaUrl2,
  className,
  mobileImage,
  tabletImage,
  desktopImage,
  icon,
  priority,
}) => {
  let borderColor: CSSProperties['borderColor'] = undefined;
  if (heroBorder && ctaColor) {
    borderColor = ctaColor;
  }
  return (
    <StyledHero $image={!!(desktopImage || tabletImage || mobileImage)}>
      <HeroImage
        mobile={mobileImage}
        tablet={tabletImage}
        desktop={desktopImage}
        priority={priority}
      />
      <div className={`HeroContent ${className}`}>
        <ContentWrapper $borderColor={borderColor}>
          <div className="CenterWrapper">
            {icon && (
              <HeroIcon color={ctaColor}>
                <Logo
                  aria-hidden={true}
                  focusable={false}
                  width={200}
                  height={200}
                />
              </HeroIcon>
            )}
            <HTag
              className="Title"
              styleType={[
                { styleType: 'header1' },
                { breakpoint: 'mobileMax', styleType: 'mega' },
              ]}
            >
              {title}
            </HTag>
            {(heroCtaTitle1 || heroCtaTitle2) && (
              <div className="HeroButtonWrapper">
                <span className="InnerButtonWrapper">
                  {heroCtaTitle1 && (
                    <ButtonLink
                      to={heroCtaUrl1 ?? ''}
                      className={`${ctaColor ? 'DatoColor' : 'Blue'}`}
                      color={ctaColor}
                    >
                      {heroCtaTitle1}
                    </ButtonLink>
                  )}
                  {heroCtaTitle2 && (
                    <ButtonLink to={heroCtaUrl2 ?? ''} className="White">
                      {heroCtaTitle2}
                    </ButtonLink>
                  )}
                </span>
              </div>
            )}
          </div>
        </ContentWrapper>
      </div>
    </StyledHero>
  );
};
