import styled, { css } from 'styled-components';

/* import { spacing } from '@hultafors/hellberg/helpers'; */

export const ContentAreaStyled = styled.div<{ $largeBottomMargin?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ $largeBottomMargin }) =>
    $largeBottomMargin
    && css`
      padding-block-end: 96px;
    `}
`;
