import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';
import { TextButton } from '../TextButton/TextButton';

export const StyledBoxFilters = styled.div`
  padding-block: ${spacing.regular};
  padding-inline: ${spacing.medium};

  select {
    width: 100px;
    margin-inline-end: 20px;
  }

  display: none;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 ${spacing.small} 0;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: flex;
  }
`;

export const DropDownFilters = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Actions = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }

  button {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    svg {
      margin-inline-start: ${spacing.xsmall};
    }
  }
`;

interface ChildSelectedProps {
  $childSelected?: boolean;
}

export const TextAndIcon = styled(TextButton)<ActiveProps & ChildSelectedProps>`
  display: flex;
  align-items: center;
  padding: 0;
  margin-inline-end: ${spacing.regular};

  ${({ $active }) =>
    $active
    && css`
      p {
        color: ${colors.hellBlue};
      }

      svg {
        transform: rotate(180deg);

        path {
          stroke: ${colors.hellBlue};
        }
      }
    `}
  ${({ $childSelected }) =>
    $childSelected
    && css`
      p {
        color: ${colors.hellBlue};
      }

      svg {
        path {
          stroke: ${colors.hellBlue};
        }
      }
    `}
  svg {
    transition-duration: 0.2s;
    margin-block-start: 2px;
    margin-inline-start: ${spacing.xsmall};
  }
`;

export const FilterWrapper = styled.div`
  position: relative;
`;

interface ActiveProps {
  $active?: boolean;
}
export const SubFilters = styled.div<ActiveProps>`
  display: none;
  inset-block-start: 36px;
  border: 1px solid ${colors.gray4};
  padding: ${spacing.small};
  z-index: 3;
  background-color: ${colors.white};
  min-width: 220px;

  ${({ $active }) =>
    $active
    && css`
      display: block;
    `}

  @media screen and (min-width: ${breakpoints.desktop}) {
    position: absolute;
  }
`;

export const Label = styled.label<ActiveProps>`
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: relative;
    width: ${spacing.small};
    height: ${spacing.small};
    border: thin solid ${colors.gray3};
    display: block;
    margin-inline-end: ${spacing.xsmall};
    line-height: ${spacing.small};
    color: ${colors.hellBlue};
    text-align: center;
  }

  ${({ $active }) =>
    $active
    && css`
      &::before {
        content: '✓';
      }
    `}

  &:hover {
    color: ${colors.gray2};

    &::before {
      background-color: ${colors.gray4};
    }
  }
`;
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  visibility: hidden;
  width: 0;
`;

export const SubFilter = styled.div`
  cursor: pointer;

  &:not(:last-of-type) {
    margin-block-end: ${spacing.small};
  }

  span {
    font-size: ${fontSizes.body12};
    letter-spacing: 0.04em;
  }
`;

export const FilterLabel = styled(Paragraph).attrs({
  styleType: 'body16Medium',
})`
  /* Keep */
`;

export const ClearLabel = styled(Paragraph).attrs({
  styleType: 'body16',
})`
  /* Keep */
`;
