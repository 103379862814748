import { NATURES, StyledButton } from './Button.styled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  nature?: keyof typeof NATURES;
  stretch?: boolean;
  iconUrl?: string;
  center?: boolean;
  selected?: boolean;
  icon?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  stretch,
  nature = 'default',
  children,
  center,
  selected,
  icon,
  ...rest
}) => {
  return (
    <StyledButton
      $stretch={stretch ? 1 : 0}
      $nature={nature}
      $center={center}
      $selected={selected}
      $icon={icon}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};
