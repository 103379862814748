import Head from 'next/head';
import { useRouter } from 'next/router';

import { createMeta, MetaData, SeoField } from '@hultafors/shared/helpers';

import { SeoFragment } from '@hultafors/hellberg/types';

import { StyledContentWrapper } from './Page.styled';

interface PageContainerProps {
  seo?: SeoFragment | null;
  children?: React.ReactNode;
  testId?: string;
  metadata?: MetaData;
  canonical?: string;
}

export const Page: React.FC<PageContainerProps> = ({
  seo,
  children,
  testId = '',
  metadata: metaDataInput,
  canonical,
}) => {
  const { locale = 'en' } = useRouter();

  const metadata: MetaData
    = metaDataInput ?? createMeta(seo ? (seo as SeoField) : undefined, locale);

  return (
    <>
      <Head>
        {canonical && (
          <link rel="canonical" href={canonical.toString()} key="canonical" />
        )}

        {metadata?.title && (
          <>
            <title>{metadata.title}</title>
            <meta property="og:title" content={metadata.title} />
            <meta name="twitter:title" content={metadata.title} />
          </>
        )}
        {metadata?.description && (
          <>
            <meta name="description" content={metadata.description} />
            <meta property="og:description" content={metadata.description} />
            <meta name="twitter:description" content={metadata.description} />
          </>
        )}
        {metadata?.type && <meta property="og:type" content={metadata.type} />}
        {metadata?.image?.url && (
          <>
            <meta property="og:image" content={metadata.image.url} />
            <meta property="twitter:image" content={metadata.image.url} />
          </>
        )}
        {metadata?.image?.width && (
          <meta property="og:image:width" content={`${metadata.image.width}`} />
        )}
        {metadata?.image?.height && (
          <meta
            property="og:image:height"
            content={`${metadata.image.height}`}
          />
        )}
        {metadata?.image?.alt && (
          <meta property="twitter:image:alt" content={metadata.image.alt} />
        )}
        <meta name="twitter:card" content="summary" />
      </Head>
      <StyledContentWrapper data-test-id={testId}>
        {children}
      </StyledContentWrapper>
    </>
  );
};
