import { NavigationWrapperStyled } from './NavigationWrapper.styled';
interface NavigationWrapperProps {
  disabled?: boolean;
  isOpen?: boolean;
  children?: React.ReactNode;
}
export const NavigationWrapper: React.FC<NavigationWrapperProps> = ({
  children,
}) => {
  return <NavigationWrapperStyled>{children}</NavigationWrapperStyled>;
};
