import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

export const NATURES = {
  default: 'default',
  primary: 'primary',
  outlinePrimary: 'outlinePrimary',
} as const;

interface StyledButtonProps {
  $nature?: keyof typeof NATURES;
  $stretch?: number | boolean;
  $iconUrl?: string;
  $center?: boolean;
  $selected?: boolean;
  $icon?: boolean;
}

const getNatureStyle = ({
  $nature,
  $stretch,
  $selected,
  $icon,
}: StyledButtonProps) => {
  if ($nature === 'primary') {
    return css`
      min-height: 56px;
      padding-block: 0;
      padding-inline: 32px;
      width: ${$stretch ? '100%' : 'fit-content'};
      color: ${colors.white};
      background-color: ${colors.hellBlue};
    `;
  }
  if ($nature === 'outlinePrimary') {
    return css`
      min-height: 40px;
      padding-block: ${spacing.xsmall};
      padding-inline: ${spacing.regular};
      border-radius: 100px;
      border: 1px solid ${colors.hellBlue};
      background-color: ${colors.white};
      color: ${colors.hellBlue};

      &:hover {
        background-color: ${colors.gray3};
        color: ${colors.hellBlue};
      }
      ${$selected
      && css`
        background-color: ${colors.hellBlue};
        color: ${colors.white};

        &:hover {
          background-color: ${colors.hellBlueHover};
          color: ${colors.white};
        }
      `}
    `;
  }

  return css`
    min-height: 56px;
    padding-block: 0;
    padding-inline: 32px;
    width: ${$stretch ? '100%' : 'fit-content'};
    color: ${colors.gray5};
    background-color: ${colors.black};

    &:hover {
      background-color: ${colors.blackHover};
    }
  `;
};

export const StyledButton = styled.button<StyledButtonProps>`
  appearance: none;
  display: ${({ $stretch }) => ($stretch ? 'block' : 'inline-block')};
  padding-block: ${spacing.tiny};
  padding-inline: ${spacing.small};
  min-height: 40px;
  border: 0;
  font-size: ${fontSizes.body14};
  line-height: ${lineHeights.body14};
  font-weight: ${fontWeights.fontMedium};
  font-family: ${fontFamilies.fontRegularBold};
  text-align: ${({ $stretch }) => ($stretch ? 'center' : 'initial')};
  text-decoration: none;
  cursor: pointer;
  transition: 0.1s;
  ${getNatureStyle};

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  ${({ $icon }) =>
    $icon
    && css`
      display: inline-flex;
      justify-content: center;
      align-items: center;
    `}
`;
