import { useId } from 'react';

import { FieldWrapper, FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { Label } from '../Label/Label';

import { StyledSelect, StyledSelectContainer } from './Select.styled';
interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  inline?: boolean;
  options?: string[];
  selected: string;
  label: string;
  wrapperProps?: FieldWrapperProps;
}
export const Select: React.FC<SelectProps> = ({
  children,
  label,
  id,
  inline,
  options = [],
  selected,
  onChange,
  wrapperProps,
  ...rest
}) => {
  const uid = useId();
  const optionMapper = (option: string) => {
    return (
      <option id={option} value={option} key={`Select-${uid}-${id}-${option}`}>
        {option}
      </option>
    );
  };

  return (
    <FieldWrapper inline={inline} {...wrapperProps}>
      <StyledSelectContainer>
        <Label htmlFor={id}>{label}</Label>
        <StyledSelect id={id} value={selected} onChange={onChange} {...rest}>
          {/* If children are provided, they will take
          precedence over the "options" prop */}
          {children ?? options.map(optionMapper)}
        </StyledSelect>
      </StyledSelectContainer>
    </FieldWrapper>
  );
};
