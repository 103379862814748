import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { HTag } from '../HTag/HTag';
import { RouterLink } from '../RouterLink/RouterLink';

export const PlugGrid = styled.div`
  display: grid;
  width: 100%;
  gap: ${spacing.small};

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid: auto-flow dense / 1fr 1fr;
    gap: ${spacing.block};
  }
`;

export const ContentWrapper = styled(RouterLink)<{ $wide?: boolean }>`
  ${({ $wide }) =>
    $wide
    && css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        grid-column: 1 / 3;
      }
    `}
`;

export const ColorPlugsBlockStyled = styled.div`
  padding: ${spacing.small};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-block: ${spacing.medium};
    padding-inline: ${spacing.large};
  }
`;

interface StyledColorPlugProps {
  $bgColor: CSSProperties['backgroundColor'];
}

interface TitleProps {
  $isDarkText?: boolean;
}

const titleColor = ({ $isDarkText }: TitleProps) => {
  if ($isDarkText) {
    return colors.black;
  }
  return colors.white;
};

export const Title = styled(HTag).attrs({
  type: 'h3',
})<TitleProps>`
  color: ${titleColor} !important;
  margin-block-end: ${spacing.regular};
`;

export const CtaLabel = styled.span`
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontMedium};
  font-family: ${fontFamilies.fontHero};
`;

const bgColor = ({ $bgColor }: StyledColorPlugProps) =>
  $bgColor ?? colors.white;

export const StyledColorPlug = styled.div<StyledColorPlugProps>`
  background-color: ${bgColor};
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-block: ${spacing.large} ${spacing.regular};
  padding-inline: ${spacing.regular};
  min-height: 296px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    min-height: 312px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    min-height: 448px;
  }
`;
