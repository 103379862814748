import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

import { GridChild } from '../GridChild/GridChild';

export const StyledGridChild = styled(GridChild)`
  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    &:nth-child(1) {
      grid-row-start: 1;
    }

    &:nth-child(2) {
      grid-row: 1 / 4;
    }

    &:nth-child(3) {
      grid-row-start: 2;
    }
  }
`;

export const ProductDetailViewStyled = styled.div`
  margin-block-start: ${spacing.small};
`;
