import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Markdown } from '../Markdown/Markdown';

import {
  BottomSpacingStyled,
  SeoSectionStyled,
  Title,
} from './SeoSection.styled';

interface SeoSectionProps {
  title?: string | null;
  description?: string | null;
}

export const SeoSection: React.FC<SeoSectionProps> = ({
  title,
  description,
}) => {
  if (!title && !description) {
    return <BottomSpacingStyled />;
  }
  return (
    <SeoSectionStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 5, start: 2 },
          ]}
        >
          {title && <Title>{title}</Title>}

          {description && (
            <Markdown options={{ forceBlock: true }}>{description}</Markdown>
          )}
        </GridChild>
      </Grid>
    </SeoSectionStyled>
  );
};
