import { useState } from 'react';

import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';
import { ParttrapImage } from '@hultafors/shared/types';

import { colors } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';

import Plus from '../../svg/plus.svg';
import { ProductImageDialog } from '../ProductImageDialog/ProductImageDialog';
import { TextButton } from '../TextButton/TextButton';

import {
  ImageWrapper,
  SingleProductImageStyled,
  ZoomContainer,
} from './SingleProductImage.styled';

interface SingleProductImageProps {
  image?: ParttrapImage;
  productName: string;
}
export const SingleProductImage: React.FC<SingleProductImageProps> = ({
  image,
  productName,
}) => {
  const { shared } = useGlobal();
  const [openDialog, setOpenDialog] = useState(false);
  const sizes = [
    `(min-width: ${breakpoints.desktopLarge}) 692px`,
    `(min-width: ${breakpoints.desktopSmall}) calc((100vw - 220px) * 7 / 12 - 20px)`,
    '100vw',
  ].join(', ');

  const showDialog = () => {
    document.body.style.overflowY = 'hidden';
    setOpenDialog(true);
  };

  const closeDialog = () => {
    document.body.style.overflowY = 'initial';
    setOpenDialog(false);
  };

  if (!image) {
    return null;
  }

  return (
    <SingleProductImageStyled>
      {openDialog && (
        <ProductImageDialog images={[image]} onClick={closeDialog} />
      )}
      <ZoomContainer>
        <TextButton onClick={showDialog} aria-label={shared.zoomImage}>
          <Plus aria-hidden={true} focusable={false} width={16} height={16} />
        </TextButton>
      </ZoomContainer>
      <ImageWrapper>
        <Image
          src={`${image.url}?w=2880&format=jpg&bgcolor=${colors.gray5.substring(
            1,
          )}`}
          alt={image.description || productName}
          style={{ objectFit: 'contain' }}
          fill
          sizes={sizes}
        />
      </ImageWrapper>
    </SingleProductImageStyled>
  );
};
