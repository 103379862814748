import { useGlobal } from '@hultafors/hellberg/hooks';

import { SearchBox } from '../SearchBox/SearchBox';
import { SlideIn } from '../SlideIn/SlideIn';

import {
  DeskTopMenuContent,
  DeskTopMenuContentWrapper,
  DeskTopMenuWrapper,
} from './SearchMenu.styled';

interface SearchMenuProps {
  isOpen: boolean;
  toggleSearchMenu(): void;
  menuType: string;
}

export const SearchMenu: React.FC<SearchMenuProps> = ({
  isOpen,
  toggleSearchMenu,
  menuType,
}) => {
  const { shared } = useGlobal();

  return (
    <SlideIn
      toggle={toggleSearchMenu}
      noHeader
      isOpen={isOpen}
      fromTop
      isSearch
      closeLabel={shared?.closeLabel ?? ''}
    >
      <DeskTopMenuWrapper>
        <DeskTopMenuContentWrapper $show={isOpen} $type={menuType}>
          <DeskTopMenuContent>
            <SearchBox isOpen={isOpen} toggleMenu={toggleSearchMenu} />
          </DeskTopMenuContent>
        </DeskTopMenuContentWrapper>
      </DeskTopMenuWrapper>
    </SlideIn>
  );
};
