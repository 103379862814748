import Image from 'next/image';

import { SplitHeroBlockFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';
import { Markdown } from '../Markdown/Markdown';

import {
  ImageWrapper,
  SplitHeroBlockStyled,
  TextContentWrapper,
  VideoInner,
  VideoStyled,
} from './SplitHeroBlock.styled';

interface SplitHeroProps extends Omit<SplitHeroBlockFragment, 'id'> {
  priority?: boolean;
}

interface DescriptionRendererProps {
  children: React.ReactNode;
  props: SplitHeroProps | SplitHeroBlockFragment;
}

export const DescriptionRenderer = ({
  children,
  ...rest
}: DescriptionRendererProps) => (
  <HTag {...rest} type="h2" styleType="header4">
    {children}
  </HTag>
);

export const SplitHeroBlock: React.FC<SplitHeroProps> = ({
  image,
  title,
  description,
  centerContent,
  priority,
  useVideo,
  video,
}) => {
  if (!image && !title && !video) {
    return null;
  }
  return (
    <SplitHeroBlockStyled $priority={priority} $center={centerContent}>
      {!useVideo && (
        <TextContentWrapper $center={centerContent} $image={!!image}>
          {title && (
            <HTag
              type="h1"
              styleType={[
                { styleType: 'header3' },
                { breakpoint: 'mobileMax', styleType: 'header2' },
              ]}
            >
              {title}
            </HTag>
          )}
          {description && (
            <Markdown options={{ overrides: { span: DescriptionRenderer } }}>
              {description}
            </Markdown>
          )}
        </TextContentWrapper>
      )}
      {/* Sometimes hellberg uses an icon as image here, it cannot be a responsive image */}
      {image?.responsiveImage?.src && !useVideo
        ? (
          <ImageWrapper>
            <Image
              src={image?.responsiveImage?.src || image.url}
              blurDataURL={image?.responsiveImage?.base64 || ''}
              alt={image?.alt || 'image'}
              placeholder="blur"
              priority={priority}
              sizes="50vw"
              fill
            />
          </ImageWrapper>
          )
        : (
            image?.url
            && !video && (
              <ImageWrapper $margin>
                <Image
                  src={image.url}
                  alt={image?.alt || 'image'}
                  priority={true}
                  sizes="50vw"
                  fill
                />
              </ImageWrapper>
            )
          )}
      {useVideo && (
        <VideoStyled>
          <TextContentWrapper $center={centerContent} $hasVideo={useVideo}>
            {title && (
              <HTag
                type="h1"
                styleType={[
                  { styleType: 'header3' },
                  { breakpoint: 'mobileMax', styleType: 'header2' },
                ]}
              >
                {title}
              </HTag>
            )}
            {description && (
              <Markdown options={{ overrides: { span: DescriptionRenderer } }}>
                {description}
              </Markdown>
            )}
          </TextContentWrapper>
          <VideoInner>
            <video
              loop
              playsInline
              autoPlay
              muted
              poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            >
              <source src={video?.url} />
            </video>
          </VideoInner>
        </VideoStyled>
      )}
    </SplitHeroBlockStyled>
  );
};
