import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

interface WrapperProps {
  $smallText?: boolean;
  $rotateIcon?: boolean;
  $color?: string;
}

function color({ $color }: WrapperProps) {
  if ($color === 'blue') {
    return css`
      color: ${colors.hellBlue};

      * {
        color: ${colors.hellBlue};
      }

      &:hover,
      &:hover * {
        color: ${colors.hellBlueHover};
      }
    `;
  }

  if ($color === 'white') {
    return css`
      color: ${colors.white};

      * {
        color: ${colors.white};
      }

      &:hover,
      &:hover * {
        color: ${colors.gray4};
      }
    `;
  }

  return css`
    color: ${colors.black};

    * {
      color: ${colors.black};
    }

    &:hover,
    &:hover * {
      color: ${colors.blackHover};
    }
  `;
}

const smallText = ({ $smallText }: WrapperProps) =>
  $smallText
  && css`
    a,
    p {
      font-size: ${fontSizes.body12};
      line-height: ${lineHeights.body12};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontRegular};
    }
  `;

const transform = ({ $rotateIcon }: WrapperProps) =>
  $rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;

  a,
  p {
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontRegular};
  }

  ${smallText};

  svg {
    margin-inline-end: ${spacing.xsmall};
    transform: ${transform};
  }

  ${color}
`;
