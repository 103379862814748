import { getPlugLinkUrl } from '@hultafors/hellberg/helpers';
import { BigPlugBlockFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';
import { LinkBlock } from '../LinkBlock/LinkBlock';
import { SquareImage } from '../SquareImage/SquareImage';

import { BigPlugBlockStyled, ContentWrapper } from './BigPlugBlock.styled';

export const BigPlugBlock: React.FC<BigPlugBlockFragment> = ({
  title,
  image,
  ctaLabel,
  ctaLink,
  ctaUrl,
  background,
  fullWidth,
  flipSide,
}) => {
  const getLink = () => {
    if (ctaUrl) {
      return ctaUrl;
    }
    if (ctaLink && ctaLink?.__typename && ctaLink.slug) {
      return `${getPlugLinkUrl(ctaLink.__typename, ctaLink.slug)}`;
    }
    return '';
  };

  return (
    <BigPlugBlockStyled
      $fullWidth={fullWidth}
      $flipSide={flipSide}
      $background={background?.hex}
    >
      <ContentWrapper>
        <HTag
          className="ContentTitle"
          type="h2"
          styleType={[
            { styleType: 'header2' },
            { breakpoint: 'desktop', styleType: 'header1' },
          ]}
        >
          {title}
        </HTag>
        {ctaLabel && <LinkBlock linkText={ctaLabel} linkUrl={getLink()} />}
      </ContentWrapper>
      {image && <SquareImage image={image} />}
    </BigPlugBlockStyled>
  );
};
