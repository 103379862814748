import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hellberg/helpers';

export const StyledMobileFilter = styled.div`
  .DropDownFilters {
    display: flex;
    flex-direction: column;
  }

  .FilterWrapper {
    position: relative;
    border-block-end: 1px solid ${colors.gray4};
    padding-block: ${spacing.regular};
    padding-inline: ${spacing.small};

    .FilterType {
      padding-block-end: ${spacing.small};
    }

    button {
      padding: 0;
      margin-inline-end: ${spacing.regular};

      &.ChildSelected {
        span {
          position: relative;
        }

        span::before {
          content: '';
          position: absolute;
          display: block;
          width: 4px;
          height: 4px;
          inset-inline-start: 50%;
          inset-block-end: -10px;
          background-color: #000;
          border-radius: 50%;
        }
      }
    }

    .SubFilters {
      inset-block-start: 36px;
      z-index: 1;
      background-color: ${colors.white};
      min-width: 220px;

      .SubFilter {
        cursor: pointer;

        &:not(:last-of-type) {
          margin-block-end: ${spacing.small};
        }

        span {
          font-size: ${fontSizes.s};
          letter-spacing: 0.04em;
        }

        input {
          visibility: hidden;
          width: 0;
        }

        label {
          position: relative;
          display: flex;
          align-items: center;

          &::before {
            content: '';
            position: relative;
            width: ${spacing.small};
            height: ${spacing.small};
            border: 1px solid ${colors.gray3};
            display: block;
            margin-inline-end: ${spacing.xsmall};
          }

          &.checked::before {
            content: '✓';
            width: ${spacing.small};
            height: ${spacing.small};
            line-height: ${spacing.small};
            color: ${colors.hellBlue};
            text-align: center;
          }

          &:hover {
            color: ${colors.gray2};

            &::before {
              background-color: ${colors.gray4};
            }
          }
        }
      }
    }
  }
`;
