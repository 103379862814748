import {
  DocumentBlockFragment,
  DocumentsSectionFragment,
  HeaderBlockFragment,
} from '../dato';

export function isHeaderBlock(
  item: DocumentsSectionFragment,
): item is HeaderBlockFragment {
  return item.__typename === 'HeaderBlockRecord';
}

export function isDocumentBlock(
  item: DocumentsSectionFragment,
): item is DocumentBlockFragment {
  return item.__typename === 'DocumentBlockRecord';
}
