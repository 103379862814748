import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/hellberg/helpers';

export const StyledEmptyProducts = styled.div`
  padding-block: ${spacing.large};
  font-size: ${fontSizes.header4};
  text-align: center;

  &:empty {
    display: none;
  }
`;
