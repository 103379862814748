import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

interface StyledLoaderProps {
  $fullScreen?: boolean;
}

export const StyledLoader = styled.div<StyledLoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $fullScreen }) => ($fullScreen ? '100vh' : 'auto')};

  svg {
    fill: ${colors.gray2};
    height: 16px;
    width: 64px;
  }

  margin-block: ${spacing.medium};
  margin-inline: 0;
`;
