import Markdown from 'markdown-to-jsx';

import { BulletListFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';

import { Content, StyledBulletList } from './BulletListBlock.styled';

type BulletListBlockProps = Omit<
  BulletListFragment,
  '_modelApiKey' | '__typename'
>;

export const BulletListBlock: React.FC<BulletListBlockProps> = ({
  title,
  list,
  isBigBulletList,
}) => {
  return (
    <StyledBulletList>
      <Content $isBig={isBigBulletList}>
        {title && (
          <HTag type="h2" styleType={isBigBulletList ? 'header3' : 'header4'}>
            {title}
          </HTag>
        )}

        <Markdown
          options={{ forceBlock: true }}
          className={
            isBigBulletList ? 'markdown-wrapper big' : 'markdown-wrapper'
          }
        >
          {list ?? ''}
        </Markdown>
      </Content>
    </StyledBulletList>
  );
};
