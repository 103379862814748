import { createContext } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { FAVORITE_KEY, transformProduct } from '@hultafors/hellberg/helpers';
import {
  HellbergProduct,
  HellbergProductDetails,
  ParsedProduct,
  SiteLocaleFragment,
} from '@hultafors/hellberg/types';

export interface FavoritesContextValue {
  favorites: ParsedProduct[];
  toggleFavoriteItem(
    product: HellbergProduct | HellbergProductDetails | ParsedProduct,
    isFavorite: boolean
  ): void;
  deleteFavorites(): void;
  isProductFavorite(sku: string): boolean;
}

export const FavoritesContext = createContext<FavoritesContextValue | null>(
  null,
);

export interface FavoritesProviderProps {
  children?: React.ReactNode;
  siteLocale: SiteLocaleFragment;
}

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({
  children,
  siteLocale,
}) => {
  const favorites = useLocalStorageValue<ParsedProduct[]>(
    `${FAVORITE_KEY}-${siteLocale?.parttrapMarket ?? 'com'}`,
    { initializeWithValue: false, defaultValue: [] },
  );

  const toggleFavoriteItem = (
    product: HellbergProduct | HellbergProductDetails | ParsedProduct,
    isFavorite: boolean,
  ) => {
    let list = [];
    if (isFavorite) {
      list = favorites.value
        ? product?.sku
          ? (favorites.value || []).filter((x) => x.sku !== product.sku)
          : [...(favorites.value || [])]
        : [];
    } else {
      const transformedProduct: ParsedProduct = transformProduct(product);
      list = [...(favorites.value || []), transformedProduct];
    }
    favorites.set(list);
  };
  const deleteFavorites = () => {
    favorites.set([]);
  };

  const isProductFavorite = (sku: string): boolean =>
    favorites?.value?.find((x) => x.sku === sku) ? true : false;

  return (
    <FavoritesContext.Provider
      value={{
        favorites: favorites.value || [],
        toggleFavoriteItem,
        deleteFavorites,
        isProductFavorite,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
