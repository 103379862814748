import { ParsedProduct } from '@hultafors/hellberg/types';

export const addToCart = (
  cart: ParsedProduct[],
  product: ParsedProduct,
  amount = 1,
) => {
  let result: ParsedProduct[] = [];

  if (!product) {
    return cart ? [...cart] : result;
  }

  amount = isNaN(amount) ? 1 : amount;
  const cartProduct: ParsedProduct = {
    ...product,
    amount,
  };

  if (cart) {
    const existingItem = cart.find((x) => x.sku === product.sku);

    if (existingItem) {
      const otherItems = cart.filter((x) => x.sku !== product.sku);
      // add one amount to existing cart item
      cartProduct.amount = existingItem.amount
        ? existingItem.amount + amount
        : amount;
      result = [...otherItems, cartProduct];
    } else {
      result = [...cart, cartProduct];
    }
  } else {
    // Cart does not exist, create with new product
    result = [cartProduct];
  }

  return result;
};
