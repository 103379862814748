import { isMorePages } from '@hultafors/shared/helpers';
import { ListApiPaging } from '@hultafors/shared/types';

import { Button } from '../Button/Button';
import { Paragraph } from '../Paragraph/Paragraph';
import { TextButton } from '../TextButton/TextButton';

import {
  Buttons,
  Label,
  PagerInner,
  PagerStyled,
  StyledGauge,
} from './Pager.styled';

interface PagerProps {
  loadMore(): void;
  showAll(): void;
  pagerData?: ListApiPaging;
  loading?: boolean;
  showMoreText: string;
  showAllText: string;
  ofText: string;
  productsText: string;
}

export const Pager: React.FC<PagerProps> = ({
  showMoreText,
  ofText,
  pagerData,
  productsText,
  loadMore,
  showAll,
  showAllText,
  loading,
}) => {
  if (!pagerData) {
    return null;
  }
  const { itemCount, pageNumber, pageCount, pageSize } = pagerData;
  if (!itemCount || pageNumber >= pageCount) {
    return null;
  }

  const hasMorePages = isMorePages(pagerData);
  const resultCount
    = pageNumber * pageSize > itemCount ? itemCount : pageNumber * pageSize;

  const soFar = Math.round((resultCount / itemCount) * 100);
  const toGo = 100 - soFar;

  return (
    <PagerStyled>
      <PagerInner>
        <Label>
          {resultCount}
          {' '}
          {ofText}
          {' '}
          {itemCount}
          {' '}
          {productsText}
        </Label>
        <StyledGauge soFar={soFar} toGo={toGo} />
        {hasMorePages && (
          <Buttons>
            <Button onClick={loadMore} center>
              {showMoreText}
            </Button>
            <TextButton onClick={showAll}>
              <Paragraph styleType="body14Medium">{showAllText}</Paragraph>
            </TextButton>
          </Buttons>
        )}
      </PagerInner>
    </PagerStyled>
  );
};
