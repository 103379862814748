import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const StyledRelatedProducts = styled.div`
  inline-size: 100%;
  max-inline-size: 1440px;
  margin-inline: auto;
  border-block-start: 1px solid ${colors.gray4};
  padding-inline: ${spacing.medium};
`;

export const Header = styled.h2`
  margin-block: ${spacing.medium};
`;
