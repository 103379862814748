import styled from 'styled-components';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hellberg/helpers';

interface StyledH2Props {
  $center?: boolean;
}

export const StyledH2 = styled.h2<StyledH2Props>`
  font-size: ${fontSizes.h2};
  line-height: ${lineHeights.header2};
  font-weight: ${fontWeights.fontMedium};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
`;
