import { HTag } from '../HTag/HTag';

import { Author, ByLine, Profession } from './Quote.styled';
interface QuoteProps {
  body: string;
  author?: string;
  authorProfession?: string;
}
export const Quote: React.FC<QuoteProps> = ({
  body,
  author,
  authorProfession,
}) => {
  return (
    <div>
      <HTag type="h2" styleType="header1">
        &quot;
        {body}
        &quot;
      </HTag>
      {author && authorProfession && (
        <ByLine>
          {author && <Author>{author}</Author>}
          {authorProfession && <Profession>{authorProfession}</Profession>}
        </ByLine>
      )}
      <span />
    </div>
  );
};
