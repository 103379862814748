import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

export const DeskTopMenuWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
`;

interface DeskTopMenuContentWrapperProps {
  $show?: boolean;
  $menuType?: string;
}

export const DeskTopMenuContentWrapper = styled.div<DeskTopMenuContentWrapperProps>`
  box-sizing: border-box;
  display: none;
  height: 100%;
  width: 100%;
  margin-block: 0;
  margin-inline: auto;
  padding-block: 0;
  padding-inline: ${spacing.regular};
  opacity: 0;
  transition: opacity 0.2s linear;
  z-index: -1;

  ${({ $show }) =>
    $show
    && css`
      display: block;
      opacity: 1;
      z-index: 1;
      transition: opacity 0.2s 0.3s linear;
    `}

  ${({ $menuType }) =>
    $menuType === 'ContentPlug'
    && css`
      max-width: 337px;
      max-height: 337px;
    `}

  ${({ $menuType }) =>
    $menuType === 'ContentPlugGridChild'
    && css`
      display: flex;
      justify-content: center;
      padding-block-start: ${spacing.large};
    `}
`;

export const DeskTopMenuContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: start;
  padding: ${spacing.medium} 0 0 0;
  margin-block-end: ${spacing.small};
`;

export const MenuSection = styled.div`
  margin-block-end: 32px;
`;

export const MenuListChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.tinier};

  &:empty {
    display: none;
  }
`;

interface MenuLinkProps {
  $parent?: boolean;
}

function menuLink({ $parent }: MenuLinkProps) {
  if ($parent) {
    return css`
      display: block;
      margin-block-end: ${spacing.small};
      font-size: ${fontSizes.header4};
      line-height: ${lineHeights.header4};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontHero};
      letter-spacing: 0.01em;
      color: ${colors.black};

      &:hover {
        a {
          color: ${colors.blackHover};
        }
      }
    `;
  }
  return css`
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontRegular};
    color: ${colors.black};
  `;
}

export const MenuLink = styled(RouterLink)<MenuLinkProps>`
  margin-inline-end: 160px;
  ${menuLink};
`;

export const DesktopMenuList = styled.div`
  display: flex;
  place-content: flex-start flex-start;
  flex-flow: column wrap;
  width: 100%;
  height: 100%;
  max-height: 500px;
`;
