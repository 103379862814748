import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { HTag } from '../HTag/HTag';

interface StyledInformationHeroProps {
  $center?: boolean;
  $image?: boolean;
  $mobileImage?: boolean;
}

export const StyledInformationHero = styled.div<StyledInformationHeroProps>`
  margin-block-end: ${spacing.medium};
  border-block-end: none;

  .HeroText {
    padding-block: ${spacing.medium};
    padding-inline: ${spacing.small};

    h2 {
      padding-block-start: ${spacing.small};
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    .HeroText {
      padding: ${({ $center }) =>
        $center ? `${spacing.medium} 0` : `${spacing.medium} ${spacing.small}`};

      h2 {
        padding-block-start: ${spacing.small};
      }
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-end: ${({ $center }) =>
      $center ? spacing.medium : spacing.large};
    border-block-end: ${({ $image }) =>
      $image ? `1px solid ${colors.gray4}` : 'none'};

    .HeroText {
      padding: ${spacing.medium} 0 0 0;
      padding-inline-start: ${({ $center }) => ($center ? 0 : spacing.medium)};
    }

    &.FindRetailers {
      margin-block-end: ${spacing.medium};

      .HeroText {
        padding-block-end: 0;
      }
    }
  }

  &.FindRetailers,
  &.ContactView {
    .HeroText {
      padding-block-end: 0;
    }
  }

  .DesktopImage {
    display: ${({ $mobileImage }) => ($mobileImage ? 'none' : 'block')};
    max-height: 900px;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      display: block;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-block-start: -34px;
    }

    @media screen and (min-width: ${breakpoints.desktopLarge}) {
      max-height: 1100px;
    }
  }

  .MobileImage {
    max-height: 800px;
    display: ${({ $mobileImage }) => ($mobileImage ? 'block' : 'none')};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      display: none;
    }
  }
`;

export const Description = styled(HTag).attrs({
  type: 'h2',
  styleType: 'header4',
})`
  /* keep */
`;
