import { HellbergProduct } from '@hultafors/hellberg/types';

import { Product } from '../Product/Product';

interface ProductsProps {
  badgeText?: string;
  rrpLabel: string;
  rrpIncludingVatLabel: string;
  products: HellbergProduct[];
  toggleMenu?: () => void;
}

export const Products: React.FC<ProductsProps> = ({
  badgeText,
  rrpLabel,
  rrpIncludingVatLabel,
  products,
  toggleMenu,
}) => (
  <>
    {products?.map((product, i) => (
      <Product
        key={`p-${i}`}
        product={product}
        badgeText={badgeText}
        rrpLabel={rrpLabel}
        rrpIncludingVatLabel={rrpIncludingVatLabel}
        toggleMenu={toggleMenu}
      />
    ))}
  </>
);
