import { useContext } from 'react';

import {
  FavoritesContext,
  FavoritesContextValue,
} from '@hultafors/hellberg/context';

export const useFavorites = (): FavoritesContextValue => {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error('useFavorites must be used within FavoritesContext!');
  }
  return context;
};
