import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

export const DualTextBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin-inline: auto;
  margin-block: ${spacing.large};
  padding-inline: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: flex;
    flex-direction: row;
    gap: 48px;
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    padding-inline: 0;
  }

  @media screen and (min-width: ${breakpoints.maxPageWidth}) {
    padding-inline: 125px;
  }
`;

export const ItemWrapper = styled.div`
  flex: 1 1 0px;
`;

export const Description = styled(Paragraph)`
  padding-block-start: ${spacing.small};
  margin-block-end: ${spacing.regular};
`;
