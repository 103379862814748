import { StyledH1 } from './H1.styled';

interface H1Props {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const H1: React.FC<H1Props> = ({ children, center, className }) => {
  return (
    <StyledH1 className={className} $center={center}>
      {children}
    </StyledH1>
  );
};
