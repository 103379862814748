import { useEffect, useId, useRef, useState } from 'react';

import { AnimatePresence, m } from 'framer-motion';
import { AccordionItemState } from 'react-accessible-accordion';

import ChevronDown from '../../svg/chevron_down.svg';
import Plus from '../../svg/plus.svg';
import { RouterLinkProps } from '../RouterLink/RouterLink';

import {
  AccordionItemButtonStyled,
  AccordionItemHeadingStyled,
  AccordionItemPanelStyled,
  AccordionItemStyled,
  AccordionLinkStyled,
  ButtonInner,
} from './AccordionItem.styled';

// Hack due to React Accessible Accordion not exporting types
type DivAttributes = React.HTMLAttributes<HTMLDivElement>;
type ID = string | number;
declare type AccordionItemProps = DivAttributes & {
  uuid?: ID;
  activeClassName?: string;
  dangerouslySetExpanded?: boolean;
  label?: React.ReactNode;
  level?: number;
  arrowIcon?: boolean;
  plusIcon?: boolean;
  className?: string;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  arrowIcon = false,
  plusIcon = false,
  label = '',
  level = 1,
  uuid,
  ...rest
}) => {
  const uid = useId();
  const [height, setHeight] = useState(0);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (panelRef.current) {
      setHeight(
        panelRef.current.scrollHeight
        + 72
        + (Array.from(panelRef.current.querySelectorAll('*'))
          .map((item) => item.scrollHeight)
          .sort()
          .shift() || 0),
      );
    }
  }, [panelRef]);

  return (
    <AccordionItemStyled {...rest} uuid={uuid ?? uid}>
      <AccordionItemHeadingStyled aria-level={level || rest['aria-level']}>
        <AccordionItemButtonStyled $arrow={arrowIcon} $plus={plusIcon}>
          <ButtonInner>{label || ''}</ButtonInner>

          {arrowIcon && (
            <ChevronDown
              aria-hidden={true}
              focusable={false}
              width={24}
              height={24}
            />
          )}
          {plusIcon && (
            <Plus aria-hidden={true} focusable={false} width={16} height={16} />
          )}
        </AccordionItemButtonStyled>
      </AccordionItemHeadingStyled>
      <AccordionItemPanelStyled $height={height}>
        <AccordionItemState>
          {({ expanded }) => (
            <AnimatePresence>
              {expanded && (
                <m.div
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div ref={panelRef}>{children}</div>
                </m.div>
              )}
            </AnimatePresence>
          )}
        </AccordionItemState>
      </AccordionItemPanelStyled>
    </AccordionItemStyled>
  );
};

export const AccordionLink: React.FC<RouterLinkProps> = ({ ...rest }) => (
  <AccordionLinkStyled {...rest} />
);
