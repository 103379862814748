import { StyledButtonLink } from './ButtonLink.styled';

interface ButtonLinkProps {
  color?: string;
  disabled?: boolean;
  to: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  color,
  disabled,
  to,
  className,
  children,
  onClick,
}) => {
  return (
    <StyledButtonLink
      href={to}
      $color={color}
      className={className}
      // disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledButtonLink>
  );
};
