import { SectionStyled } from './Section.styled';
interface SectionProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  hide?: boolean;
  borderBottom?: boolean;
  borderTop?: boolean;
  fullWidth?: boolean;
  largeMarginBottom?: boolean;
  largeMarginTop?: boolean;
  maxWidth?: boolean;
  mediumMarginBottom?: boolean;
  megaMarginBottom?: boolean;
  noMargin?: boolean;
  noMarginBottom?: boolean;
  noMarginTop?: boolean;
  smallMarginBottom?: boolean;
  smallMarginTop?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  children,
  className,
  style,
  hide,
  borderBottom,
  borderTop,
  fullWidth,
  largeMarginBottom,
  largeMarginTop,
  maxWidth,
  mediumMarginBottom,
  megaMarginBottom,
  noMargin,
  noMarginBottom,
  noMarginTop,
  smallMarginBottom,
  smallMarginTop,
}) => {
  return (
    <SectionStyled
      className={className}
      style={style}
      $hide={hide}
      $borderBottom={borderBottom}
      $borderTop={borderTop}
      $fullWidth={fullWidth}
      $largeMarginBottom={largeMarginBottom}
      $largeMarginTop={largeMarginTop}
      $maxWidth={maxWidth}
      $mediumMarginBottom={mediumMarginBottom}
      $megaMarginBottom={megaMarginBottom}
      $noMargin={noMargin}
      $noMarginBottom={noMarginBottom}
      $noMarginTop={noMarginTop}
      $smallMarginBottom={smallMarginBottom}
      $smallMarginTop={smallMarginTop}
    >
      {children}
    </SectionStyled>
  );
};
