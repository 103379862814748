import MuxPlayer from '@mux/mux-player-react';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { HTag } from '../HTag/HTag';

export const MuxPlayerStyled = styled(MuxPlayer).attrs({ streamType: 'on-demand',
  loop: false,
  muted: true,
  autoPlay: false,
})`
  --dialog: none; /* Hide the error dialog */
  --media-object-fit: cover;
  --controls: none;

    align-self: center;
    max-width: 100%;
    max-height: calc(100vh - var(--header-height));
    object-fit: cover;
    width: 100%;

  `;

export const StyledVideo = styled.div<{ $aspectRatio?: string }>`
  display: block;

  > div > div {
   aspect-ratio: 16 / 9;
  }

  video {
    align-self: center;
    max-width: 100%;
    max-height: calc(100vh - var(--header-height));
    object-fit: cover;
    width: 100%;
  }

  .description {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-block-start: ${spacing.small};
    font-size: ${fontSizes.body12};

    .video-label {
      color: ${colors.gray3};
      margin-inline-end: ${spacing.medium};
    }

    .video-title {
      line-height: ${lineHeights.body12};
      margin-block-start: 1px;
    }
  }

  :hover {
    div > button {
      opacity: 1;
    pointer-events: initial;
    transition: opacity 0.3s ease;
    }
  }
`;

export const VideoWrapper = styled.div`
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
`;

export const Title = styled(HTag)`
  color: ${colors.white};
  text-align: center;
  text-shadow: 0 2px 20px rgb(0 0 0 / 25%);
`;
export const PlayToggle = styled.div`
    button {
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      transform: translate(-50%, -50%);
      width: 56px;
      height: 56px;
      opacity: 0;
      padding: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;
      cursor: pointer;
      background: rgb(234 234 234 / 50%);
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid ${colors.hellTurqouise};


      svg path {
        opacity: 1;
        fill: ${colors.hellTurqouise};
      }

      &::before {
        @media screen and (min-width: ${breakpoints.desktop}) {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          inset-block-start: 0;
          inset-inline-start: 0;
          background: ${colors.blackHover};
          transform: translateX(-100%);
          pointer-events: none;
          z-index: -1;
        }
      }

      &:hover::before {
        transform: translateX(0);
      }

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        width: 56px;
        height: 56px;
      }
    }
`;
