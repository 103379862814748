import Link from 'next/link';
import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const ContentPlugStyled = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;

  .NoImagePlaceholder {
    height: 100%;
    width: 100%;
    background-color: ${colors.gray6};
  }

  .TextBoxWrapper {
    padding-block-start: ${spacing.regular};
  }

  .TextBoxTitle {
    margin-block-end: ${spacing.regular};
  }

  .TextBoxText {
    margin-block-end: ${spacing.regular};
  }

  .TextBoxCTAWrapper {
    display: flex;
    align-items: center;

    svg {
      margin-inline-end: ${spacing.xsmall};

      path {
        stroke: ${colors.hellBlue};
      }
    }
  }

  .TextBoxCTA {
    color: ${colors.hellBlue};
  }

  :hover {
    .TextBoxCTAWrapper {
      svg {
        path {
          stroke: ${colors.hellBlueHover};
        }
      }
    }

    .TextBoxCTA {
      color: ${colors.hellBlueHover};
    }
  }
`;
