import Link from 'next/link';
import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hellberg/helpers';

export const StyledLinkBoxBlock = styled.section`
  margin: ${spacing.regular};
  display: flex;
  justify-content: center;

  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    align-self: center;
    width: 100%;

    .Title {
      margin-block: ${spacing.regular};
      margin-inline: 0;
      font-size: ${fontSizes.header3};
      align-self: flex-start;
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block: ${spacing.large};
    margin-inline: auto;

    .Title {
      font-size: ${fontSizes.h3};
    }
  }
`;

interface LinkBoxProps {
  $color?: CSSProperties['color'];
}

export const LinkBox = styled(Link)<LinkBoxProps>`
  background-color: ${({ $color }) => $color ?? colors.black};
  padding: ${spacing.regular};
  margin-block: ${spacing.tiny};
  margin-inline: 0;
  display: flex;
  width: 100%;
  height: 70px;
  max-width: 600px;
  align-items: center;
  box-shadow: rgb(99 99 99 / 40%) 0 2px 8px 0;
  text-decoration: none;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    height: 100px;
  }
`;

export const StyledImage = styled.img<{ $dark?: boolean }>`
  filter: ${({ $dark }) => ($dark ? 'invert(0)' : 'invert(1)')};
  margin-inline-end: ${spacing.medium};
  width: 36px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    width: 56px;
  }
`;

interface LinkTextProps {
  $color?: CSSProperties['color'];
  $image?: boolean;
}

export const LinkText = styled.p<LinkTextProps>`
  color: ${({ $color }) => $color};
  text-align: center;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  transform: ${({ $image }) => $image && 'translateX(-28px)'};
  max-width: ${({ $image }) => $image && '400px'};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    transform: ${({ $image }) => $image && 'translateX(-18px)'};
    font-size: 20px;
  }
`;
