import {
  AppLinksBlockFragment,
  BigPlugBlockFragment,
  BlueInfoBoxFragment,
  BulletListFragment,
  ColorPlugsBlockFragment,
  ContactInformationBlockFragment,
  ContentAreaField,
  DocumentBlockFragment,
  DotDigitalFormFragment,
  DualTextBlockFragment,
  DynamicContentField,
  ExploreAreaBlockFragment,
  GreyInfoBoxFragment,
  HeroBlockFragment,
  ImageBlockFragment,
  ImageSliderFragment,
  LinkBoxBlockFragment,
  ModelFeatureTableBlockFragment,
  OfficesBlockFragment,
  PagePlugsBlockFragment,
  QuoteFragment,
  RelatedProductsBlockFragment,
  SimpleTitleAndTextBlockFragment,
  SliderBlockFragment,
  SplitHeroBlockFragment,
  TextBlockFragment,
  TwoImageBlockFragment,
  VideoFragment,
  VisibleSeoFragment,
} from '../dato';

type InputType = ContentAreaField | DynamicContentField;

export function isAppLinksBlock(
  item: InputType,
): item is AppLinksBlockFragment {
  return item.__typename === 'AppLinksBlockRecord';
}
export function isBigPlugBlock(item: InputType): item is BigPlugBlockFragment {
  return item.__typename === 'BigPlugBlockRecord';
}
export function isBlueInfoBox(item: InputType): item is BlueInfoBoxFragment {
  return item.__typename === 'BlueInfoBoxRecord';
}
export function isBulletList(item: InputType): item is BulletListFragment {
  return item.__typename === 'BulletListRecord';
}
export function isColorPlugsBlock(
  item: InputType,
): item is ColorPlugsBlockFragment {
  return item.__typename === 'ColorPlugsBlockRecord';
}
export function isContactInformationBlock(
  item: InputType,
): item is ContactInformationBlockFragment {
  return item.__typename === 'ContactInformationBlockRecord';
}
export function isDocumentBlockNew(
  item: InputType,
): item is DocumentBlockFragment {
  return item.__typename === 'DocumentBlockRecord';
}

export function isDotDigitalForm(
  item: InputType,
): item is DotDigitalFormFragment {
  return item.__typename === 'DotDigitalFormRecord';
}
export function isTwoImageBlock(
  item: InputType,
): item is TwoImageBlockFragment {
  return item.__typename === 'TwoImageBlockRecord';
}
export function isDualTextBlock(
  item: InputType,
): item is DualTextBlockFragment {
  return item.__typename === 'DualTextBlockRecord';
}
export function isExploreAreaBlock(
  item: InputType,
): item is ExploreAreaBlockFragment {
  return item.__typename === 'ExploreAreaBlockRecord';
}
export function isGreyInfoBox(item: InputType): item is GreyInfoBoxFragment {
  return item.__typename === 'GreyInfoBoxRecord';
}
export function isHeroBlock(item: InputType): item is HeroBlockFragment {
  return item.__typename === 'HeroBlockRecord';
}
export function isImageBlock(item: InputType): item is ImageBlockFragment {
  return item.__typename === 'ImageRecord';
}
export function isImageSlider(item: InputType): item is ImageSliderFragment {
  return item.__typename === 'ImageSliderRecord';
}
export function isLinkBoxBlock(item: InputType): item is LinkBoxBlockFragment {
  return item.__typename === 'LinkBoxBlockRecord';
}
export function isModelFeatureTableBlock(
  item: InputType,
): item is ModelFeatureTableBlockFragment {
  return item.__typename === 'ModelFeatureTableBlockRecord';
}
export function isOfficesBlock(item: InputType): item is OfficesBlockFragment {
  return item.__typename === 'OfficesBlockRecord';
}
export function isPagePlugsBlock(
  item: InputType,
): item is PagePlugsBlockFragment {
  return item.__typename === 'PagePlugsBlockRecord';
}
export function isQuote(item: InputType): item is QuoteFragment {
  return item.__typename === 'QuoteRecord';
}

export function isSimpleTitleAndTextBlock(
  item: InputType,
): item is SimpleTitleAndTextBlockFragment {
  return item.__typename === 'SimpleTitleAndTextBlockRecord';
}

export function isSliderBlock(item: InputType): item is SliderBlockFragment {
  return item.__typename === 'SliderBlockRecord';
}

export function isSplitHeroBlock(
  item: InputType,
): item is SplitHeroBlockFragment {
  return item.__typename === 'SplitHeroBlockRecord';
}

export function isRelatedProductsBlock(
  item: InputType,
): item is RelatedProductsBlockFragment {
  return item.__typename === 'RelatedProductsBlockRecord';
}

export function isTextBlock(item: InputType): item is TextBlockFragment {
  return item.__typename === 'TextBlockRecord';
}
export function isVideo(item: InputType): item is VideoFragment {
  return item.__typename === 'VideoRecord';
}
export function isVisibleSeo(item: InputType): item is VisibleSeoFragment {
  return item.__typename === 'VisibleSeoRecord';
}
