import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

interface PlugsGridStyledProps {
  $total?: number;
  $gap?: CSSProperties['gap'];
  $minWidth?: CSSProperties['minWidth'];
}

export const PlugsGridStyled = styled.div<PlugsGridStyledProps>`
  --grid-column-count: ${({ $total }) => $total};
  --grid-item-min-width: ${({ $minWidth }) =>
    $minWidth ? `${$minWidth}px` : '100px'};
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );
  --grid-layout-gap: ${({ $gap }) => ($gap ? `${$gap}px` : '100px')};

  display: grid;
  grid-template-columns: minmax(0, 1fr);

  > a:not(:last-child) {
    padding-block-end: 32px;
  }

  gap: calc(var(--grid-layout-gap) / 2);

  @media screen and (min-width: ${breakpoints.tablet}) {
    > a:not(:last-child) {
      padding-block-end: 0;
    }

    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr)
    );
    gap: var(--grid-layout-gap);
  }
`;
