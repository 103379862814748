import { StyledH3 } from './H3.styled';

interface H3Props {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const H3: React.FC<H3Props> = ({ center, children, className }) => (
  <StyledH3 className={className} $center={center}>
    {children}
  </StyledH3>
);
