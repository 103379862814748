import { ModelFeatureTableBlockFragment } from '@hultafors/hellberg/types';

import {
  BlockHeader,
  FeatureLabel,
  FeatureTable,
  FeatureValue,
  ModelFeatureTableBlockStyled,
  ModelName,
  TableRow,
} from './ModelFeatureTableBlock.styled';

interface ModelFeatureTableProps extends ModelFeatureTableBlockFragment {
  inContentArea?: boolean;
}

export const ModelFeatureTableBlock: React.FC<ModelFeatureTableProps> = ({
  modelSeries,
  table,
  modelHeaderColor,
}) => {
  function modelNameMapper(table: ModelFeatureTableBlockFragment['table']) {
    return (
      <TableRow>
        {table?.columns?.map((header: string, index: number) => {
          if (header.trim() === '') {
            return (
              <ModelName
                $color={modelHeaderColor?.hex}
                key={`ModelFeature-header-${header.trim()}-${index}`}
              >
                &nbsp;
              </ModelName>
            );
          } else {
            return (
              <ModelName
                $color={modelHeaderColor?.hex}
                key={`ModelFeature-header-${header.trim()}-${index}`}
              >
                {header}
              </ModelName>
            );
          }
        })}
      </TableRow>
    );
  }

  function bodyValuesMapper(table: ModelFeatureTableBlockFragment['table']) {
    return table?.data?.map((row: any, index: number) => (
      <TableRow key={`Table-Row-${index}`}>
        {table?.columns?.map((column: string, subIndex: number) => {
          const key = `${row[column].trim()}-${subIndex}`;

          if (column.trim() === '') {
            return <FeatureLabel key={key}>{row[column]}</FeatureLabel>;
          } else {
            return <FeatureValue key={key}>{row[column]}</FeatureValue>;
          }
        })}
      </TableRow>
    ));
  }

  return (
    <ModelFeatureTableBlockStyled>
      <BlockHeader>
        <h2>{modelSeries}</h2>
      </BlockHeader>
      <FeatureTable>
        <tbody>
          {modelNameMapper(table)}
          {bodyValuesMapper(table)}
        </tbody>
      </FeatureTable>
    </ModelFeatureTableBlockStyled>
  );
};
