import { TextButtonStyled } from './TextButton.styled';
interface TextButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  center?: boolean;
  iconUrl?: string;
  iconLeft?: boolean;
  className?: string;
  children?: React.ReactNode;
}
export const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  children,
  className,
}) => {
  return (
    <TextButtonStyled className={className} onClick={onClick}>
      {children}
    </TextButtonStyled>
  );
};
