import { useCart, useFavorites } from '@hultafors/hellberg/hooks';

import Cart from '../../svg/cart.svg';
import Favorite from '../../svg/favorite.svg';
import PartnerLogin from '../../svg/partner_login.svg';
import { Badge } from '../Badge/Badge';
import { IconButtonWrapper } from '../IconButtonWrapper/IconButtonWrapper';
import { NavLabel } from '../NavLabel/NavLabel';

import { ItemContainer, Link } from './NavItem.styled';

interface NavItemProps {
  toggle?(): void;
  isFavorite?: boolean;
  inTopMenu?: boolean;
  isShoppingCart?: boolean;
  urlToPartnerPortal?: string | null;
  linkUrl?: string;
  label?: string | null;
}

export const NavItem: React.FC<NavItemProps> = ({
  toggle,
  isFavorite,
  isShoppingCart,
  urlToPartnerPortal,
  label,
}) => {
  const { favorites } = useFavorites();
  const { cart } = useCart();
  if (isFavorite) {
    return (
      <ItemContainer $hasFavorite={favorites.length > 0} onClick={toggle}>
        <Favorite aria-hidden={true} focusable={false} width={24} height={24} />
        {label && <NavLabel>{label}</NavLabel>}
      </ItemContainer>
    );
  }
  if (isShoppingCart) {
    return (
      <IconButtonWrapper onClick={toggle} name="Open cart" className="Cart">
        <Cart aria-hidden={true} focusable={false} width={24} height={24} />
        {cart.length > 0 && (
          <Badge round blue>
            {cart.length}
          </Badge>
        )}
        {label && <NavLabel>{label}</NavLabel>}
      </IconButtonWrapper>
    );
  }
  if (urlToPartnerPortal) {
    return (
      <Link
        href={urlToPartnerPortal}
        aria-label="Partner Portal"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PartnerLogin
          aria-hidden={true}
          focusable={false}
          width={24}
          height={24}
        />
        {label && <NavLabel>{label}</NavLabel>}
      </Link>
    );
  }
  return null;
};
