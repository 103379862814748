import { useGlobal } from '../use-global/use-global';

export const useConstructUrl = () => {
  const { allNavItems = [] } = useGlobal();

  const getRootSlug = (slug: string): string | null => {
    const rootItem = allNavItems.find((item) => {
      return item.children?.find((subItem) => {
        if (subItem?.pageLink?.slug === slug) {
          return subItem;
        }
        return subItem?.children?.find(
          (subSubItem) => subSubItem?.pageLink?.slug === slug,
        );
      });
    });
    if (rootItem) {
      return rootItem.url || '';
    }

    return null;
  };

  const getParentSlug = (root: string, slug: string): string | null => {
    const menuItem = allNavItems.find((item) => item.url === root);

    const parent = menuItem?.children?.find((item) => {
      return item?.children?.find(
        (subItem) => subItem?.pageLink?.slug === slug,
      );
    });

    if (parent) {
      return parent.pageLink?.slug || '';
    }

    return null;
  };

  const constructUrl = (slug: string): string => {
    const root = getRootSlug(slug) || '';
    const parentSlug = getParentSlug(root, slug);
    if (root && parentSlug) {
      return `${root}/${parentSlug}/${slug}`;
    } else if (root) {
      return `${root}/${slug}`;
    } else {
      return `/${slug}`;
    }
  };

  return {
    constructUrl,
  };
};
