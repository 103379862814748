import dynamic from 'next/dynamic';

import { useConstructUrl, useGlobal } from '@hultafors/hellberg/hooks';
import {
  NavItemChildFragment,
  NavItemFragment,
  NavItemGrandChildFragment,
} from '@hultafors/hellberg/types';

import PartnerLogin from '../../svg/partner_login.svg';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';
import { SlideIn } from '../SlideIn/SlideIn';

import {
  MenuAccordionItem,
  MenuLabel,
  MenuLink,
  MenuStyled,
} from './MobileMenu.styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion),
);
const SearchBox = dynamic(() =>
  import('../SearchBox/SearchBox').then((mod) => mod.SearchBox),
);

interface MobileMenuProps {
  menu: NavItemFragment[];
  toggleMenu(): void;
  isOpen: boolean;
  partnerPortalUrl: string;
  partnerLoginLabel: string;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  menu,
  toggleMenu,
  isOpen,
  partnerPortalUrl,
  partnerLoginLabel,
}) => {
  const { shared } = useGlobal();
  const { constructUrl } = useConstructUrl();
  if (!menu.length) {
    return null;
  }

  function grandChildMapper(subSubItem: NavItemGrandChildFragment, i: number) {
    if (!subSubItem?.pageLink?.slug) {
      return null;
    }
    return (
      <MenuLink
        key={`Sub-Sub-Item-${i}`}
        to={constructUrl(subSubItem.pageLink.slug)}
        onClick={toggleMenu}
        $level={3}
        aria-level={3}
      >
        {subSubItem.title}
      </MenuLink>
    );
  }
  function childMapper(subItem: NavItemChildFragment, i: number) {
    return subItem.children?.length
      ? (
        <MenuAccordionItem
          key={`Sub-Item-${i}`}
          label={(
            <MenuLink
              to={constructUrl(subItem.pageLink?.slug || '')}
              onClick={toggleMenu}
              $weight={600}
              $size={20}
            >
              {subItem.title}
            </MenuLink>
          )}
          level={2}
          dangerouslySetExpanded
        >
          {(
            subItem?.children?.filter(Boolean) as NavItemGrandChildFragment[]
          ).map(grandChildMapper)}
        </MenuAccordionItem>
        )
      : (
        <MenuLink
          to={constructUrl(subItem.pageLink?.slug || '')}
          onClick={toggleMenu}
        >
          {subItem.title}
        </MenuLink>
        );
  }

  function menuMapper(item: NavItemFragment, i: number) {
    if (item.children?.length) {
      return (
        <MenuAccordionItem
          key={`Root-Item-${i}`}
          level={1}
          label={<MenuLabel>{item.title}</MenuLabel>}
          arrowIcon
        >
          {(item.children?.filter(Boolean) as NavItemChildFragment[]).map(
            childMapper,
          )}
        </MenuAccordionItem>
      );
    }
    return (
      <MenuLink to={item.url || ''} onClick={toggleMenu} key={`Root-Item-${i}`}>
        <HTag type="h2" styleType="header3">
          {item.title}
        </HTag>
      </MenuLink>
    );
  }
  return (
    <MenuStyled>
      <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText="Le menu"
        isMenu
        padContent
        className="mobileMenu"
        closeLabel={shared?.closeLabel || ''}
      >
        <span>
          <div className="SearchWrapper">
            <SearchBox isOpen={isOpen} toggleMenu={toggleMenu} />
          </div>
          <div className="MenuWrapper ">
            <Accordion allowZeroExpanded>{menu.map(menuMapper)}</Accordion>
          </div>
        </span>
        <div className="PartnerLogin ">
          <Paragraph>
            <a
              href={partnerPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="PartnerLoginLink"
            >
              {partnerLoginLabel}
              <PartnerLogin
                aria-hidden={true}
                focusable={false}
                width={16}
                height={16}
              />
            </a>
          </Paragraph>
        </div>
      </SlideIn>
    </MenuStyled>
  );
};
