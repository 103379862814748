import NextLink from 'next/link';
import styled from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

import { colors } from '@hultafors/hellberg/helpers';

interface NavItemStyledProps {
  $hasFavorite?: boolean;
  $isShoppingCart?: boolean;
}

export const ItemContainer = styled.button<NavItemStyledProps>`
  ${buttonReset};
  position: relative;
  width: auto;
  height: auto;
  color: ${colors.black};
  color: ${({ $hasFavorite }) => ($hasFavorite ? colors.black : 'transparent')};
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

export const Link = styled(NextLink)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  color: ${colors.black};
  text-decoration: none;

  svg {
    color: ${colors.black};
  }
`;
