import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

export const StyledProductDetailData = styled.div`
  padding-block: ${spacing.medium} ${spacing.regular};
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: var(--header-height) ${spacing.medium} ${spacing.medium} 0;
    margin-block-start: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IsNewLabel = styled(Paragraph).attrs({
  styleType: 'body14Medium',
})`
  color: ${colors.hellBlue};
`;

export const ProductNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModelSkuLabel = styled(Paragraph).attrs({ styleType: 'body12' })`
  margin: 0;
  margin-block-start: ${spacing.xsmall};
  color: ${colors.gray0};
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding-block-start: ${spacing.small};

  p {
    margin-block-end: 0;
  }
`;

export const RRPLabel = styled(Paragraph).attrs({ styleType: 'body12' })`
  color: ${colors.gray0};
  text-transform: uppercase;
  margin-inline-start: ${spacing.xsmall};
`;

export const RRPCurrency = styled(Paragraph).attrs({ styleType: 'body16' })`
  color: ${colors.gray0};
  text-transform: uppercase;
  margin-inline-start: ${spacing.xsmall};
`;
