import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const CartStyled = styled.div`
  .SlideInBody {
    position: relative;
    padding-block-end: 150px;

    .Empty {
      margin-block-start: ${spacing.medium};
    }
  }

  .SendListWrapper {
    box-sizing: border-box;
    width: 100%;
    background-color: ${colors.white};
    padding: ${spacing.regular} 0 0 0;

    .MailBtn {
      background-color: ${colors.gray5};
      border: 1px solid ${colors.gray4};

      &:hover {
        background-color: ${colors.gray4};
        border: 1px solid ${colors.gray3};
      }
    }
  }
`;
