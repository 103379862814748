import { getFontColorBasedOnBg, IsTextDark } from '@hultafors/shared/helpers';

import { colors } from '@hultafors/hellberg/helpers';
import { LinkBoxBlockFragment } from '@hultafors/hellberg/types';

import { H2 } from '../H2/H2';

import {
  LinkBox,
  LinkText,
  StyledImage,
  StyledLinkBoxBlock,
} from './LinkBoxBlock.styled';

type LinkBoxBlockProps = LinkBoxBlockFragment;

export const LinkBoxBlock: React.FC<LinkBoxBlockProps> = ({ title, links }) => {
  return (
    <StyledLinkBoxBlock>
      <div className="Wrapper">
        {title && <H2 className="Title">{title}</H2>}
        {links?.map((link, i) => (
          <LinkBox
            target="_blank"
            rel="noopener noreferrer"
            href={link.linkUrl ?? ''}
            $color={link.boxColor?.hex}
            key={`linkbox-${i}`}
          >
            {link.icon && (
              <StyledImage
                src={link.icon.url}
                alt={link.icon.alt || link.icon.title || 'icon'}
                $dark={IsTextDark(
                  getFontColorBasedOnBg(
                    link.boxColor?.hex ?? '',
                    colors.white,
                    colors.black,
                  ),
                )}
              />
            )}
            {link.title && (
              <LinkText
                $color={getFontColorBasedOnBg(
                  link.boxColor?.hex ?? '',
                  colors.white,
                  colors.black,
                )}
                $image={!!link.icon}
              >
                {link.title}
              </LinkText>
            )}
          </LinkBox>
        ))}
      </div>
    </StyledLinkBoxBlock>
  );
};
