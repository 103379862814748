import { useCart, useGlobal } from '@hultafors/hellberg/hooks';

import ShoppingList from '../../svg/shopping_list.svg';
import { HTag } from '../HTag/HTag';
import { LinkButton } from '../LinkButton/LinkButton';
import { ProductListView } from '../ProductListView/ProductListView';
import { SlideIn } from '../SlideIn/SlideIn';

import { CartStyled } from './Cart.styled';

interface CartProps {
  isOpen: boolean;
  toggleMenu(): void;
  header: string;
  clearText: string;
  sendText: string;
  emailText: string;
  shoppingListText: string;
  sendShoppingListText: string;
  rrpLabel?: string;
  rrpIncludingVatLabel?: string;
  artNoLabel?: string;
  cartEmpty?: string;
}

export const Cart: React.FC<CartProps> = ({
  isOpen,
  toggleMenu,
  header,
  clearText,
  sendText,
  shoppingListText,
  rrpLabel,
  rrpIncludingVatLabel,
  artNoLabel,
  cartEmpty,
}) => {
  const { deleteItemFromCart, cart, deleteCart } = useCart();
  const { shared } = useGlobal();

  const getCartString = () => {
    let result = '';
    cart.map((item) => {
      result += `${item.sku ?? item.sku} ${item.name}`;
      result += '%0D%0A%0D%0A';
    });
    return result;
  };

  const createMailToLink = () => {
    return `?subject=${shoppingListText}&body=${getCartString()}`;
  };

  return (
    <CartStyled>
      <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText={header}
        headerExtra={`(${cart.length})`}
        clearText={clearText}
        clearAll={deleteCart}
        fromLeft={false}
        isCart
        itemLength={cart.length}
        closeLabel={shared?.closeLabel || ''}
      >
        {cart.length > 0 && (
          <div className="SendListWrapper">
            <LinkButton
              text={sendText}
              mailToLink={createMailToLink()}
              icon={(
                <ShoppingList
                  aria-hidden={true}
                  focusable={false}
                  width={16}
                  height={16}
                />
              )}
              fullWidth
              mailTo
              black
            />
          </div>
        )}
        {cart.map((product, i) => {
          return (
            <ProductListView
              key={`CartRow-${i}`}
              product={product}
              removeItem={deleteItemFromCart}
              rrpLabel={rrpLabel}
              rrpIncludingVatLabel={rrpIncludingVatLabel}
              artNoLabel={artNoLabel}
              toggleMenu={toggleMenu}
            />
          );
        })}
        {cart.length === 0 && (
          <HTag center type="h3" className="Empty" styleType="header3">
            {cartEmpty}
          </HTag>
        )}
      </SlideIn>
    </CartStyled>
  );
};
