import { MaintenanceModeFragment } from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';

import {
  HeroContent,
  MaintenanceBlockStyled,
  StyledMarkdown,
} from './MaintenanceBlock.styled';

interface MaintenanceBlockProps {
  data: MaintenanceModeFragment;
}

export const MaintenanceBlock: React.FC<MaintenanceBlockProps> = ({ data }) => {
  return (
    <MaintenanceBlockStyled>
      <HeroContent>
        <Grid columnGap={48}>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 12, start: 1 },
              { breakpoint: 'desktop', columns: 6, start: 1 },
              { breakpoint: 'desktopLarge', columns: 5, start: 2 },
            ]}
          >
            <HTag
              type="h2"
              styleType={[
                { styleType: 'header1' },
                { breakpoint: 'desktop', styleType: 'mega' },
              ]}
            >
              {data.title}
            </HTag>
          </GridChild>
          {data.body && (
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 12, start: 1 },
                { breakpoint: 'desktop', columns: 6, start: 7 },
                { breakpoint: 'desktopLarge', columns: 5, start: 7 },
              ]}
            >
              <StyledMarkdown>{data.body}</StyledMarkdown>
            </GridChild>
          )}
        </Grid>
      </HeroContent>
    </MaintenanceBlockStyled>
  );
};
