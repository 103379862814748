import { StyledFieldWrapper } from './FieldWrapper.styled';
export interface FieldWrapperProps {
  inline?: boolean;
  children?: React.ReactNode;
}
export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  inline,
  children,
}) => {
  return <StyledFieldWrapper $inline={inline}>{children}</StyledFieldWrapper>;
};
