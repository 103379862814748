import Link from 'next/link';
import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

export const Address = styled(Paragraph).attrs({
  styleType: 'body16',
})`
  margin: 0 0 ${spacing.small} 0;
  font-size: ${fontSizes.body16};
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 0 ${spacing.small} 0;
  align-items: flex-start;
`;

export const Label = styled.div`
  margin: 0;
`;

export const VisitSite = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  justify-content: flex-start;
  padding-block: ${spacing.small};
  padding-inline: 0;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: underline;

    svg {
      margin-inline-start: 8px;
    }
  }
`;

export const StoreText = styled.label`
  color: ${colors.black};
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.body16};
`;

export const StoresWrapper = styled.div`
  flex-direction: column;
`;

export const StoreSells = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  padding-block: ${spacing.small};
  padding-inline: 0;

  a {
    color: ${colors.black};
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    text-decoration: underline;
  }
`;

export const Separator = styled.div`
  display: inline-flex;
  margin-block: 0;
  margin-inline: 5px;
`;

export const Stores = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const IconLink = styled(Link)`
  color: ${colors.hellBlue};
  margin-block: ${spacing.xsmall};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: ${spacing.tiny};

  &:visited {
    color: ${colors.hellBlue};
  }

  &:hover {
    color: ${colors.hellBlueHover};
  }
`;

export const StoreItemStyled = styled.div`
  background: ${colors.gray5};
  padding: ${spacing.small};

  p,
  label {
    font-size: 14px;
  }
`;
