import { BadgeStyled } from './Badge.styled';
interface BadgeProps {
  children?: string | number;
  styleName?: string;
  inline?: boolean;
  round?: boolean;
  blue?: boolean;
  small?: boolean;
  tiny?: boolean;
  isNew?: boolean;
  premium?: boolean;
}
export const Badge: React.FC<BadgeProps> = ({
  children,
  styleName,
  inline,
  round,
  blue,
  small,
  tiny,
  isNew,
  premium,
}) => (
  <BadgeStyled
    className={`Badge ${styleName}`}
    $inline={inline}
    $round={round}
    $blue={blue}
    $small={small}
    $tiny={tiny}
    $isNew={isNew}
    $premium={premium}
  >
    <span>{children}</span>
  </BadgeStyled>
);
