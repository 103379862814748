import {
  StyledInput,
  StyledSliderSpan,
  StyledToggleSwitch,
  StyledToggleSwitchContainer,
  StyledToggleSwitchLabel,
} from './ToggleSwitch.styled';
interface ToggleSwitchProps {
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
}
export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
  label,
}) => (
  <StyledToggleSwitchContainer>
    <StyledToggleSwitch>
      <StyledInput type="checkbox" checked={checked} onChange={onChange} />
      <StyledSliderSpan className="slider" />
    </StyledToggleSwitch>
    {label && <StyledToggleSwitchLabel>{label}</StyledToggleSwitchLabel>}
  </StyledToggleSwitchContainer>
);
