import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const StyledGreyInfoBox = styled.div`
  max-width: calc(100vw - 40px);
  width: 1000px;
  margin-inline: auto;
`;

export const Content = styled.div`
  background-color: ${colors.gray4};
  padding-block: 31px;
  padding-inline: 32px;

  h2 {
    padding: ${spacing.medium};
  }
`;
