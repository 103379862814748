import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { Box } from '../Box/Box';

interface DialogWrapperProps {
  $top?: CSSProperties['top'];
  $right?: CSSProperties['right'];
  $bottom?: CSSProperties['bottom'];
  $left?: CSSProperties['left'];
  $height?: CSSProperties['height'];
  $width?: CSSProperties['width'];
}

export const DialogWrapper = styled(Box)<DialogWrapperProps>`
  position: fixed;
  inset-block: ${({ $top }) => $top} ${({ $bottom }) => $bottom};
  inset-inline: ${({ $left }) => $left} ${({ $right }) => $right};
  margin: auto;
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  z-index: 10;
  box-sizing: border-box;
  color: ${colors.black};
  background-color: ${colors.white};
  overflow-y: auto;
`;

interface DialogHeaderProps {
  $isFullscreen?: boolean;
}

export const DialogHeader = styled.div<DialogHeaderProps>`
  width: auto;
  position: absolute;
  inset-block-start: ${spacing.small};
  inset-inline-end: ${spacing.small};
  z-index: 10;

  ${({ $isFullscreen }) =>
    $isFullscreen
    && css`
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        inset-block-start: ${spacing.regular};
        inset-inline-end: ${spacing.medium};
      }
    `};

  button {
    padding: 0;
    width: 40px;
    height: 40px;
    min-height: 40px;

    img {
      height: 24px;
      width: 24px;
    }
  }
`;

export const DialogContent = styled.div`
  width: 100%;
  height: 100%;
  margin-block: 0;
  margin-inline: auto;
  overflow-y: auto;
  padding-block: 0;
  padding-inline: ${spacing.medium};
`;
