import { EmptyProductStyled } from './EmptyProduct.styled';
export const EmptyProduct: React.FC = () => (
  <EmptyProductStyled>
    <div className="ImagePlaceholder" />
    <div className="ProductInfoPlaceholder">
      <span />
      <span />
    </div>
  </EmptyProductStyled>
);
