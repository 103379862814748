import { PlugsGridStyled } from './PlugsGrid.styled';

export interface PlugsGridProps {
  children?: React.ReactNode;
  total?: number;
  gap?: number;
  minWidth?: number;
}

export const PlugsGrid: React.FC<PlugsGridProps> = ({
  gap,
  total,
  minWidth,
  children,
}) => {
  return (
    <PlugsGridStyled $gap={gap} $total={total} $minWidth={minWidth}>
      {children}
    </PlugsGridStyled>
  );
};
