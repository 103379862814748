import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

interface GaugeStyledProps {
  $soFar?: number;
  $toGo?: number;
}
export const GaugeStyled = styled.div`
  width: 100%;
  display: flex;
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.small};
  padding-inline-end: calc(${spacing.small} + ${spacing.small});

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-inline-end: calc(${spacing.medium} + ${spacing.medium});
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-inline: ${spacing.small};
    padding-block: ${spacing.small} ${spacing.medium};
  }

  div {
    height: 2px;
  }
`;

export const SoFar = styled.div<{ $value?: number }>`
  border-radius: 1px;
  background-color: ${colors.hellSalmon};
  transition: width 0.2s ease-out;
  width: ${({ $value = 0 }) => $value}%;
`;

export const ToGo = styled.div<{ $value?: number }>`
  border-radius: 0 1px 1px 0;
  background-color: ${colors.gray3};
  transition: width 0.2s ease-out;
  width: ${({ $value = 100 }) => $value}%;
`;
