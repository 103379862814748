import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/hellberg/helpers';

interface RowGap {
  breakpoint?: Breakpoint;
  rowGap: number | Spacing;
}

interface ColumnGap {
  breakpoint?: Breakpoint;
  columnGap: number | Spacing;
}

interface Columns {
  breakpoint?: Breakpoint;
  columns: number | string;
}

export type GridRowGapProp = RowGap[] | number | Spacing;
export type GridColumnGapProp = ColumnGap[] | number | Spacing;
export type GridColumnsProp = Columns[] | number | string;

interface StyledGridProps {
  $autoFlow?: CSSProperties['gridAutoFlow'];
  $align?: CSSProperties['alignItems'];
  $margin?: CSSProperties['margin'];
  $maxWidth?: CSSProperties['maxWidth'];
  $center?: boolean;
  $minColumnWidth?: string;
  $rowGap?: GridRowGapProp;
  $columnGap?: GridColumnGapProp;
  $columns?: GridColumnsProp;
}

function getRowGapMapper(breakpoint: RowGap) {
  if (breakpoint.breakpoint) {
    return css`
      @media all and (min-width: ${breakpoint.breakpoint}) {
        row-gap: ${breakpoint.rowGap}px;
      }
    `;
  }
  return css`
    row-gap: ${breakpoint.rowGap}px;
  `;
}

function getRowGap({ $rowGap }: StyledGridProps) {
  if (Array.isArray($rowGap)) {
    return $rowGap.map(getRowGapMapper);
  }
  if (typeof $rowGap === 'number') {
    return css`
      row-gap: ${$rowGap}px;
    `;
  }

  return css`
    row-gap: ${spacing[$rowGap ?? 'regular']};
  `;
}

function getColumnsMapper({ breakpoint, columns }: Columns) {
  if (breakpoint) {
    return css`
      @media all and (min-width: ${breakpoints[breakpoint]}) {
        grid-template-columns: repeat(${columns}, minmax(0, 1fr));
      }
    `;
  }
  return css`
    grid-template-columns: repeat(${columns}, minmax(0, 1fr));
  `;
}

function getColumns({ $columns, $minColumnWidth }: StyledGridProps) {
  if (Array.isArray($columns)) {
    return $columns.map(getColumnsMapper);
  }
  return css`
    grid-template-columns: repeat(
      ${$columns},
      ${$minColumnWidth ? `minmax(${$minColumnWidth}, 1fr)` : 'minmax(0, 1fr)'}
    );
  `;
}

function getColumnGapMapper({ breakpoint, columnGap }: ColumnGap) {
  if (breakpoint) {
    const value
      = typeof columnGap === 'number' ? `${columnGap}px` : spacing[columnGap];
    return css`
      @media all and (min-width: ${breakpoints[breakpoint]}) {
        column-gap: ${value};
      }
    `;
  }
  if (typeof columnGap === 'number') {
    return css`
      column-gap: ${columnGap}px;
    `;
  }
  return css`
    column-gap: ${spacing[columnGap]};
  `;
}

function getColumnGap({ $columnGap }: StyledGridProps) {
  if (Array.isArray($columnGap)) {
    return $columnGap.map(getColumnGapMapper);
  }
  if (typeof $columnGap === 'number') {
    return css`
      column-gap: ${$columnGap}px;
    `;
  }
  return css`
    column-gap: ${spacing[$columnGap ?? 'regular']};
  `;
}

export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-auto-flow: ${({ $autoFlow }) => $autoFlow};
  place-items: ${({ $align }) => $align}
    ${({ $align }) => ($align === 'flex-start' ? 'stretch' : $align)};
  justify-content: ${({ $center }) => ($center ? 'center' : 'initial')};
  margin: ${({ $margin }) => $margin};
  max-width: ${({ $maxWidth }) => $maxWidth};
  ${getRowGap};
  ${getColumns};
  ${getColumnGap};
`;
