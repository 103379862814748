import { fixSlash } from '@hultafors/hellberg/helpers';
import { useConstructUrl } from '@hultafors/hellberg/hooks';
import {
  ExploreAreaPageLinkFragment,
  InfoPagePlugFragment,
  InformationPagePlugFragment,
  isInfoPagePlug,
  isPageLink,
  PageLinkFragment,
} from '@hultafors/hellberg/types';

import { ContentPlug } from '../ContentPlug/ContentPlug';
import { HTag } from '../HTag/HTag';
import { PlugsGrid } from '../PlugsGrid/PlugsGrid';

import { StyledExploreMoreBlock } from './ExploreMoreBlock.styled';
// TODO While building contntArea the plugs should have the same structure
// We rather not use multiple plug types
interface ExploreMoreBlockProps {
  title?: string;
  ctaText?: string;
  pageLinks?: (PageLinkFragment | ExploreAreaPageLinkFragment)[];
  infoPagePlugs?: (InformationPagePlugFragment | InfoPagePlugFragment)[];
}

export const ExploreMoreBlock: React.FC<ExploreMoreBlockProps> = ({
  title,
  pageLinks,
  infoPagePlugs,
}) => {
  const { constructUrl } = useConstructUrl();
  const pageLinkMapper = (
    plug: PageLinkFragment | ExploreAreaPageLinkFragment,
    i: number,
  ) => {
    const key = `Content-plug${i}`;
    if (isPageLink(plug)) {
      return (
        <ContentPlug
          key={key}
          title={plug.title || ''}
          text={plug.description || ''}
          ctaText={plug.ctaText || ''}
          url={constructUrl(plug.page?.slug || '')}
          image={plug.image}
        />
      );
    }
    return (
      <ContentPlug
        key={key}
        title={plug.heroTitle || ''}
        text={plug.heroDescription}
        ctaText={plug.plugCtaLabel}
        url={constructUrl(plug.slug || '')}
        image={plug.heroImage}
      />
    );
  };

  const infoPlugMapper = (
    plug: InformationPagePlugFragment | InfoPagePlugFragment,
    i: number,
  ) => {
    if (isInfoPagePlug(plug)) {
      return (
        <ContentPlug
          title={plug.title || ''}
          text={plug.description || ''}
          ctaText={plug.ctaText || ''}
          url={fixSlash(plug.url || '')}
          image={plug.image}
          key={`Content-plug${i}`}
        />
      );
    }
    return (
      <ContentPlug
        title={plug.heroTitle || ''}
        text={plug.shortDescription || plug.heroDescription || ''}
        ctaText={plug.plugCtaLabel || plug.heroTitle || ''}
        url={constructUrl(plug.slug || '')}
        image={plug.heroImage}
        key={`Content-plug${i}`}
      />
    );
  };

  return (
    <StyledExploreMoreBlock>
      {title && (
        <HTag
          type="h2"
          styleType={[
            { styleType: 'header3' },
            { breakpoint: 'mobileMax', styleType: 'header2' },
          ]}
        >
          {title}
        </HTag>
      )}
      {pageLinks && (
        <PlugsGrid gap={200} minWidth={420} total={pageLinks?.length}>
          {pageLinks.map(pageLinkMapper)}
        </PlugsGrid>
      )}

      {infoPagePlugs && (
        <PlugsGrid gap={200} minWidth={420} total={infoPagePlugs?.length}>
          {infoPagePlugs.map(infoPlugMapper)}
        </PlugsGrid>
      )}
    </StyledExploreMoreBlock>
  );
};
