import styled from 'styled-components';

import { spacing } from '@hultafors/hellberg/helpers';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

export const TextGrid = styled(Grid)`
  padding-block-start: ${spacing.regular};
`;

export const Description = styled(GridChild).attrs({
  columnSpan: [
    { columns: 4 },
    { breakpoint: 'mobileMax', columns: 12, start: 1 },
    { breakpoint: 'desktop', columns: 5, start: 1 },
  ],
})`
  padding-block-end: ${spacing.medium};
`;

export const CategoryHeaderStyled = styled.section`
  margin-block-start: ${spacing.small};
`;
