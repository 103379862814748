import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const ExploreAreaBlockStyled = styled.div`
  border-block-start: 1px solid ${colors.gray4};
  padding: ${spacing.small};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-block: ${spacing.medium};
    padding-inline: ${spacing.large};
  }

  h2 {
    margin-block-end: ${spacing.regular};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-block-end: ${spacing.medium};
    }
  }
`;
