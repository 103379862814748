import styled, { CSSProperties } from 'styled-components';

import { spacing } from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

interface ImageBlockProps {
  $small?: boolean;
}

export const ImageBlockStyled = styled.div<ImageBlockProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: ${spacing.regular};
  margin-inline: auto;
  max-inline-size: calc(100vw - 40px);
  inline-size: 1000px;

  p {
    padding-block-start: ${spacing.xsmall};
  }
`;

export const Wrapper = styled.div<ImageBlockProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  max-inline-size: ${({ $small }) => ($small ? '600px' : '1000px')};
  position: relative;
`;

interface InnerProps {
  $aspectRatio?: CSSProperties['aspectRatio'];
}

export const Inner = styled.div<InnerProps>`
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio ?? '9 / 5'};
  inline-size: 100%;
`;

export const Label = styled(Paragraph).attrs({
  styleType: 'body14',
})`
  /* keep */
`;
