import { Fragment } from 'react';

import Markdown from 'markdown-to-jsx';

import { ListValue } from '@hultafors/hellberg/types';

import { TechnicalDataBox } from '../TechnicalDataBox/TechnicalDataBox';

import {
  StyledGrid,
  StyledGridChild,
  StyledProductMoreInformation,
  TechBoxWrapper,
  TechInfo,
} from './ProductMoreInformation.styled';

interface ProductMoreInformationProps {
  snrList: ListValue[];
  snrLabel: string;
  technicalDataLabel: string;
  techList: ListValue[];
  headerFeatures: string;
  contentFeatures: string[];
}

export const ProductMoreInformation: React.FC<ProductMoreInformationProps> = ({
  snrList,
  snrLabel,
  technicalDataLabel,
  techList,
  headerFeatures,
  contentFeatures,
}) => {
  const contentFeatureMapper = (item: string) => {
    return (
      <Markdown
        key={`ProductMoreInformation-ContentFeature-${item}`}
        options={{ forceBlock: true, wrapper: Fragment }}
      >
        {item}
      </Markdown>
    );
  };
  return (
    <StyledProductMoreInformation>
      <StyledGrid>
        {!!contentFeatures.length && (
          <StyledGridChild>
            <h2>{headerFeatures}</h2>
            {contentFeatures.map(contentFeatureMapper)}
          </StyledGridChild>
        )}
        {(!!snrList?.length || !!techList?.length) && (
          <TechInfo
            rowSpan="1"
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 6 },
              { breakpoint: 'desktopSmall', columns: 4, start: 9 },
            ]}
          >
            <TechBoxWrapper>
              {!!snrList?.length && (
                <TechnicalDataBox
                  technicalDataLabel={snrLabel}
                  list={snrList}
                  snr
                />
              )}
            </TechBoxWrapper>

            {!!techList?.length && (
              <TechBoxWrapper>
                <TechnicalDataBox
                  technicalDataLabel={technicalDataLabel}
                  list={techList}
                />
              </TechBoxWrapper>
            )}
          </TechInfo>
        )}
        {/* {!!techList?.length && (
          <GridChild
            rowSpan="1"
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 6 },
              { breakpoint: 'desktopSmall', columns: 4, start: 9 },
            ]}
          >
            <TechnicalDataBox
              technicalDataLabel={technicalDataLabel}
              list={techList}
            />
          </GridChild>
        )} */}
      </StyledGrid>
    </StyledProductMoreInformation>
  );
};
