import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

export const InputStyled = styled.div<{ $error?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  position: relative;
  height: 3rem;

  label {
    font-size: ${fontSizes.body14};
    line-height: 20px;
  }

  textarea {
    border-radius: 5px;
    border: 0.5px solid ${colors.gray2};
    min-height: 150px;
    padding-block: ${spacing.small};
    padding-inline: ${spacing.medium};
    line-height: ${lineHeights.body14};
    box-sizing: border-box;
  }

  input,
  textarea {
    width: 100%;
    padding: ${spacing.xsmall};
    background-color: ${colors.white};
    font-size: 16px;
    box-sizing: border-box;
    appearance: none;
    border: 0.5px solid ${colors.gray2};
    border-radius: 50px;

    &:focus {
      outline: 0;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    height: 3rem;
    border-radius: 50px;
    border: 0.5px solid ${colors.gray2};
    background-color: ${colors.white};
    line-height: 3rem;
  }

  input:placeholder-shown {
    border-color: ${colors.gray2};
    color: ${colors.gray2};
  }

  input:invalid {
    border-color: ${colors.hellSalmon};
  }

  ${({ $error }) =>
    $error
    && css`
      input {
        border-color: ${colors.hellSalmon};
      }
    `}
`;

export const ErrorMessage = styled.span`
  width: 100%;
  color: ${colors.hellSalmon};
  font-size: ${fontSizes.body10};
`;
