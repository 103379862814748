import styled from 'styled-components';

import { spacing } from '@hultafors/hellberg/helpers';

export const StyledNav = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-block-end: ${spacing.menuHeight};
`;
