import { FieldWrapper, FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { Label } from '../Label/Label';

import { StyledTextarea } from './Textarea.styled';
interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  // id: string;
  inline?: boolean;
  label: string;
  wrapperProps?: FieldWrapperProps;
}
export const Textarea: React.FC<TextareaProps> = ({
  label,
  id,
  inline,
  wrapperProps,
  ...rest
}) => (
  <FieldWrapper inline={inline} {...wrapperProps}>
    <Label htmlFor={id}>{label}</Label>
    <StyledTextarea id={id} {...rest} />
  </FieldWrapper>
);
