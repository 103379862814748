import { useConstructUrl, useGlobal } from '@hultafors/hellberg/hooks';
import {
  NavItemChildFragment,
  NavItemGrandChildFragment,
} from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { SlideIn } from '../SlideIn/SlideIn';

import {
  DeskTopMenuContent,
  DeskTopMenuContentWrapper,
  DesktopMenuList,
  DeskTopMenuWrapper,
  MenuLink,
  MenuListChildren,
  MenuSection,
} from './DesktopDropdownMenu.styled';

interface DesktopDropdownMenuProps {
  toggleDesktopMenu(type?: string): void;
  openType: string;
  menuType: string;
  menuItems: NavItemChildFragment[];
}
export const DesktopDropdownMenu: React.FC<DesktopDropdownMenuProps> = ({
  toggleDesktopMenu,
  openType,
  menuType,
  menuItems,
}) => {
  const { shared } = useGlobal();
  const { constructUrl } = useConstructUrl();

  function grandChildMapper(child: NavItemGrandChildFragment | null) {
    if (!child || child.hide) {
      return null;
    }
    return (
      <MenuLink
        key={`DesktopDropdownMenu-grandchild-${child.id}`}
        to={constructUrl(child?.pageLink?.slug || '')}
        onClick={() => toggleDesktopMenu()}
      >
        {child.title ?? ''}
      </MenuLink>
    );
  }

  function menuMapper(item: NavItemChildFragment | null, i: number) {
    if (!item || item.hide) {
      return null;
    }
    return (
      <MenuSection key={`Product-Menu-${i}`}>
        <MenuLink
          $parent={!!item.children?.length}
          to={constructUrl(item.pageLink?.slug || '')}
          onClick={() => toggleDesktopMenu()}
        >
          {item.title}
        </MenuLink>
        <MenuListChildren>
          {item.children?.map(grandChildMapper)}
        </MenuListChildren>
      </MenuSection>
    );
  }

  return (
    <SlideIn
      toggle={toggleDesktopMenu}
      noHeader
      isOpen={openType === menuType}
      fromTop
      closeLabel={shared?.closeLabel || ''}
    >
      <DeskTopMenuWrapper>
        <DeskTopMenuContentWrapper
          $show={openType === menuType}
          $menuType={menuType}
        >
          <Grid columns={12}>
            <GridChild columnSpan={[{ columns: 11, start: 2 }]}>
              <DeskTopMenuContent>
                <DesktopMenuList>{menuItems.map(menuMapper)}</DesktopMenuList>
              </DeskTopMenuContent>
            </GridChild>
          </Grid>
        </DeskTopMenuContentWrapper>
      </DeskTopMenuWrapper>
    </SlideIn>
  );
};
