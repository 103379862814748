import { StyledBox } from './BoxUi.styled';

interface BoxUiProps {
  children?: React.ReactNode;
  marginTop?: boolean;
  shadow?: boolean;
  className?: string;
}

export const BoxUi: React.FC<BoxUiProps> = ({
  children,
  marginTop,
  shadow,
  className,
}) => {
  return (
    <StyledBox $marginTop={marginTop} $shadow={shadow} className={className}>
      {children}
    </StyledBox>
  );
};
