import MuxPlayer from '@mux/mux-player-react';
import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { H2 } from '../H2/H2';
import { Paragraph } from '../Paragraph/Paragraph';

interface StyledHeroBlockProps {
  $image?: boolean;
  $borderColor?: CSSProperties['borderColor'];
  $isInSlider?: boolean;
}

export const HeroBlockStyled = styled.div<StyledHeroBlockProps>`
  position: relative;
  height: auto;
  width: 100%;
  z-index: 1;
  aspect-ratio: 3 / 4;

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 1 / 1;
    min-height: auto;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 12 / 5;
  }
`;

export const HeroBlockContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: ${spacing.medium};
`;

export const ContentWrapper = styled.div<StyledHeroBlockProps>`
  border: ${({ $borderColor }) =>
    $borderColor ? `5px solid ${$borderColor}` : 'none'};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $isInSlider }) => $isInSlider && css`
  transform: translateZ(0);
  `}
`;

export const CenterWrapper = styled.div`
  width: 100%;
  position: relative;

  h3 {
    margin-inline: auto;
    max-width: 300px;
    text-align: center;
  }
`;

interface HeroButtonWrapperProps {
  $image?: boolean;
}

export const HeroButtonWrapper = styled.div<HeroButtonWrapperProps>`
  width: 100%;

  a {
    font-size: ${fontSizes.body14};
    line-height: ${lineHeights.body14};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    overflow: hidden;
    white-space: nowrap;

    &:first-child {
      margin-block-end: ${spacing.small};
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: ${spacing.medium};
  text-align: center;
  ${({ $image }) =>
    !$image
    && css`
      a {
        border: 1px solid ${colors.black};
        color: ${colors.black};

        &:hover {
          background-color: ${colors.black};
          color: ${colors.white};
        }
      }
    `}

  > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    max-width: 100%;
  };

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-start: ${spacing.medium};
  };
`;

export const HeroIcon = styled.div<StyledHeroBlockProps>`
  color: ${({ color = 'inherit' }) => color};
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const HeroTitle = styled(H2)`
  color: ${colors.white};
  text-align: center;
  text-shadow: 0 2px 20px rgb(0 0 0 / 25%);
  font-size: 60px;
  line-height: 66px;
  font-weight: 500;
  font-family: var(--font-hero);

    @media screen and (max-width: ${breakpoints.mobileMax}) {
    font-size: 30px;
  }
`;

export const VideoStyled = styled.div`
  width: 100%;

  /* background-color: ${colors.gray4}; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const VideoInner = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  /* video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  } */
`;

export const HeroVideo = styled(MuxPlayer).attrs({ streamType: 'on-demand',
  loop: true,
  muted: true,
  autoPlay: 'muted',
})`
  --dialog: none; /* Hide the error dialog */
  --media-object-fit: cover;
  --controls: none;
  `;

export const DescriptionBox = styled.div`
  margin-block-start: ${spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  max-width: 500px;
  text-align: center;
`;

export const Description = styled(Paragraph)`
  color: ${colors.white};
  font-size: ${fontSizes.l};

  @media screen and (max-width: ${breakpoints.mobileMax}) {
    font-size: 16px;
  }
`;
