import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hellberg/helpers';

export const StyledBackLink = styled(Link)`
  position: absolute;
  z-index: 1;
  inset-block-start: calc(var(--header-height, 56px) + 8px);
  inset-inline-start: 12px;
  color: ${colors.hellBlue};
  font-size: ${fontSizes.body14};
  text-decoration: none;
  display: flex;
  gap: ${spacing.xsmall};
  align-items: center;
  min-width: 48px;
  min-height: 48px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    inset-inline-start: 5vw;
    inset-block-start: calc(var(--header-height, 56px) + 12px);
  }
`;
