import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/hellberg/helpers';
export const StyledRetailersAdditionalSelections = styled.div`
  margin-block-start: ${spacing.regular};
  display: flex;
  justify-content: center;
`;

export const RadiusSelectContainer = styled.div`
  max-width: 400px;

  label {
    font-size: ${fontSizes.body12};
  }
`;
