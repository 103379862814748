import { useFavorites } from '@hultafors/hellberg/hooks';
import {
  HellbergProduct,
  HellbergProductDetails,
  ParsedProduct,
} from '@hultafors/hellberg/types';

import {
  FavoriteStyled,
  StyledFavoriteIcon,
  StyledRemoveIcon,
} from './Favorite.styled';

interface FavoriteProps {
  product: HellbergProduct | HellbergProductDetails | ParsedProduct;
  inFavorites?: boolean;
  bigStar?: boolean;
}

export const Favorite: React.FC<FavoriteProps> = ({
  product,
  inFavorites,
  bigStar,
}) => {
  const { toggleFavoriteItem, isProductFavorite } = useFavorites();
  const isFavorite = isProductFavorite(product.sku);
  const onClick = () => toggleFavoriteItem(product, isFavorite);
  return (
    <FavoriteStyled
      className={`Favorite ${isFavorite ? 'Active ' : ''} ${
        inFavorites ? 'InFavorites' : ''
      }`}
      onClick={onClick}
      $isFavorite={isFavorite}
    >
      {!inFavorites && !bigStar && (
        <StyledFavoriteIcon
          $isFavorite={isFavorite}
          aria-hidden={true}
          focusable={false}
          width={20}
          height={20}
        />
      )}
      {!inFavorites && bigStar && (
        <StyledFavoriteIcon
          $isFavorite={isFavorite}
          aria-hidden={true}
          focusable={false}
          width={24}
          height={24}
        />
      )}
      {inFavorites && (
        <StyledRemoveIcon
          aria-hidden={true}
          focusable={false}
          width={16}
          height={16}
        />
      )}
    </FavoriteStyled>
  );
};
