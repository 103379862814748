import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled, { css } from 'styled-components';

import { RouterLink } from '../RouterLink/RouterLink';

const AccordionLinkShared = css`
  display: flex;
  width: 100%;
  min-height: 48px;
  cursor: pointer;
  position: relative;
  margin: 0;
`;

export const AccordionLinkStyled = styled(RouterLink)`
  ${AccordionLinkShared};
`;

export const AccordionItemStyled = styled(AccordionItem)`
  position: relative;
  width: 100%;
`;

export const AccordionItemHeadingStyled = styled(AccordionItemHeading)`
  width: 100%;
`;

export const ButtonInner = styled.span`
  position: relative;
  ${AccordionLinkShared};
  min-height: 32px;
`;

interface AccordionItemButtonProps {
  $arrow?: boolean;
  $plus?: boolean;
}

export const AccordionItemButtonStyled = styled(
  AccordionItemButton,
)<AccordionItemButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;

  ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 20px;
  }
  ${AccordionItemStyled} ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 40px;
  }

  svg {
    transition: all 0.3s ease-out;
  }

  &[aria-expanded='true'] {
    svg {
      transform: ${(props) =>
        props['$arrow']
          ? 'rotate(-180deg)'
          : props['$plus']
          ? 'rotate(-45deg)'
          : ''};
      transform-origin: 8px 8px;
    }
  }
`;

interface AccordionItemPanelStyledProps {
  $height?: number;
}

export const AccordionItemPanelStyled = styled(
  AccordionItemPanel,
)<AccordionItemPanelStyledProps>`
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &[hidden] {
    display: block;
  }
`;
