import { useFavorites, useGlobal } from '@hultafors/hellberg/hooks';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Product } from '../Product/Product';
import { SlideIn } from '../SlideIn/SlideIn';

interface FavoritesProps {
  isOpen: boolean;
  toggleMenu(): void;
  header?: string;
  clearText?: string;
  badgeText?: string;
  rrpLabel?: string;
  rrpIncludingVatLabel?: string;
  favoritesEmpty?: string;
  isSmall?: boolean;
}

export const Favorites: React.FC<FavoritesProps> = ({
  toggleMenu,
  isOpen,
  header,
  clearText,
  badgeText,
  rrpLabel,
  rrpIncludingVatLabel,
  favoritesEmpty,
}) => {
  const { favorites, deleteFavorites } = useFavorites();
  const { shared } = useGlobal();
  return (
    <div data-test="Favorites">
      <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText={`${header}`}
        clearText={clearText}
        clearAll={deleteFavorites}
        padContent
        fromLeft={false}
        itemLength={favorites.length}
        closeLabel={shared?.closeLabel || ''}
      >
        <Grid
          columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 1 }]}
        >
          <>
            {favorites.map((product, i) => {
              return (
                <GridChild
                  key={`Favorite-${i}`}
                  columnSpan={[
                    { columns: 1 },
                    { breakpoint: 'mobileMax', columns: 1 },
                  ]}
                >
                  <Product
                    product={product}
                    inFavorites={true}
                    badgeText={badgeText}
                    rrpLabel={rrpLabel || ''}
                    rrpIncludingVatLabel={rrpIncludingVatLabel}
                    noHover
                    toggleMenu={toggleMenu}
                  />
                </GridChild>
              );
            })}

            {favorites && favorites.length === 0 && (
              <HTag center type="h3" styleType="header3">
                {favoritesEmpty}
              </HTag>
            )}
          </>
        </Grid>
      </SlideIn>
    </div>
  );
};
