import styled from 'styled-components';

import { colors } from '@hultafors/hellberg/helpers';

export const ProductImageStyled = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1/1;
  background-color: ${colors.gray6};

  img {
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  a {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
  }
`;
