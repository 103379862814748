import {
  StyledGraphic,
  StyledGraphicWrapper,
  StyledGraphicWrapperProps,
} from './Graphic.styled';

interface GraphicProps {
  src: string;
  alt: string;
  width?: string;
  wrapperProps?: StyledGraphicWrapperProps;
}

export const Graphic: React.FC<GraphicProps> = ({
  wrapperProps,
  width = '40px',
  src,
  alt,
}) => {
  return (
    <StyledGraphicWrapper {...wrapperProps}>
      <StyledGraphic src={src} alt={alt} width={width} />
    </StyledGraphicWrapper>
  );
};
