import { StyledH2 } from './H2.styled';
interface H2Props {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
}
export const H2: React.FC<H2Props> = ({ center, children, className }) => (
  <StyledH2 className={className} $center={center}>
    {children}
  </StyledH2>
);
