import dynamic from 'next/dynamic';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/hellberg/hooks';

import LogoSvg from '../../svg/logo.svg';

const FooterStyled = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Footer),
);

interface FooterMenuLink {
  title?: string;
  url?: string;
}
interface FooterMenuItem {
  heading?: string;
  links?: FooterMenuLink[];
  includeCookieToggle?: boolean;
}

export const Footer = () => {
  const { footer } = useGlobal();
  const { toggle, activeMarket } = useMarketPicker();

  return (
    <FooterStyled
      activeMarket={activeMarket}
      brandLinks={footer?.hultaforsBrands}
      bottomIntro={footer?.bottomIntro ?? ''}
      introText={footer.introText ?? ''}
      items={footer?.menuItems as FooterMenuItem[]}
      logoAriaLabel="Hellberg Safety"
      logoIcon={
        <LogoSvg aria-hidden={true} focusable={false} width={34} height={21} />
      }
      moreInfoText={footer?.moreInfoText ?? ''}
      selectCountryLabel={footer?.selectCountryLabel ?? ''}
      socialMediaLabel={footer?.socialMediaLabel}
      socialMediaMenu={footer?.socialMediaMenu}
      toggleMarketPicker={toggle}
      cookieSettingsLabel={footer?.cookieSettings}
    />
  );
};
