import { StyledLink } from './Link.styled';
interface LinkProps {
  external?: boolean;
  href: string;
  children?: React.ReactNode;
}
/**
 * Styled link component, to use for internal or external links:
 * <Link as={RouterLink} to="/">Go to home page</Link>
 * <Link external to="https://some.where.el.se">Go somewhere else</Link>
 */
export const Link: React.FC<LinkProps> = ({ external, href, children }) => {
  if (external) {
    return (
      <StyledLink href={href} rel="noreferrer noopener" target="_blank">
        {children}
      </StyledLink>
    );
  }
  return <StyledLink href={href}>{children}</StyledLink>;
};
