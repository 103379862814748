import styled from 'styled-components';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hellberg/helpers';

interface StyledH3Props {
  $center?: boolean;
}

export const StyledH3 = styled.h3<StyledH3Props>`
  font-size: ${fontSizes.h3};
  line-height: ${lineHeights.xs};
  font-weight: ${fontWeights.fontMedium};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
`;
