import Image from 'next/image';
import Link from 'next/link';

import { useConstructUrl } from '@hultafors/hellberg/hooks';
import { CategoryFeatureFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import { StyledFeatures } from './Features.styled';

interface FeaturesProps {
  title?: string | null;
  features?: CategoryFeatureFragment[] | null;
}
export const Features: React.FC<FeaturesProps> = ({
  title = '',
  features = [],
}) => {
  const { constructUrl } = useConstructUrl();

  if (!features?.length) {
    return null;
  }

  function featureMapper(feature: CategoryFeatureFragment, i: number) {
    const key = `feature${i}`;
    if (feature.link) {
      return (
        <Link
          key={key}
          href={constructUrl(feature.link?.slug || '')}
          className="feature"
        >
          {feature.icon?.url && (
            <Image
              src={feature.icon.url}
              alt={feature.icon?.alt || feature.name || ''}
              width={24}
              height={24}
            />
          )}
          <Paragraph styleType="body16">{feature.name}</Paragraph>
        </Link>
      );
    }
    return (
      <div className="feature" key={key}>
        {feature.icon?.url && (
          <Image
            src={feature.icon?.url}
            alt={feature.icon?.alt || ''}
            width={24}
            height={24}
          />
        )}
        <Paragraph styleType="body16">{feature.name}</Paragraph>
      </div>
    );
  }

  return (
    <StyledFeatures data-test="features">
      <HTag type="h2" styleType="header4">
        {title}
      </HTag>
      {features.map(featureMapper)}
    </StyledFeatures>
  );
};
