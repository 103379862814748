import { ProductFilter, QueryProductFilter } from '@hultafors/shared/types';

import { Paragraph } from '../Paragraph/Paragraph';

import { StyledMobileFilter } from './MobileFilter.styled';

interface MobileFilterProps {
  filters: ProductFilter[];
  filterChange({ AttrId, ValueId }: QueryProductFilter): void;
}

export const MobileFilter: React.FC<MobileFilterProps> = ({
  filters = [],
  filterChange,
}) => {
  return (
    <StyledMobileFilter>
      <div className="DropDownFilters">
        {filters
          ?.filter((x) => !x.isHighLighted)
          .map((parent, i) => (
            <div key={`Filter-${i}`} className="FilterWrapper">
              <Paragraph className="FilterType" styleType="body16Medium">
                {parent.label}
              </Paragraph>
              <div className="SubFilters">
                {parent.values.map((value, j) => (
                  <div key={`SubFilter-${j}`} className="SubFilter">
                    <label className={value.active ? 'checked' : ''}>
                      <input
                        type="checkbox"
                        onChange={() =>
                          filterChange({
                            AttrId: `${parent.id}`,
                            ValueId: `${value.id}`,
                          })}
                        checked={value.active}
                      />
                      <Paragraph styleType="body16">
                        {value.description}
                      </Paragraph>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </StyledMobileFilter>
  );
};
