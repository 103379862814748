import { useState } from 'react';

import { ImageBasicFragment } from '@hultafors/hellberg/types';

import { Button } from '../Button/Button';
import { SubscriptionDialog } from '../SubscriptionDialog/SubscriptionDialog';

import { SubscribeText } from './SubscribeArea.styled';

interface SubscribeAreaProps {
  subscribeInfoText?: string | null;
  subscribeButtonText?: string | null;
  userEmailFormUrl?: string | null;
  userEmailFormImage?: ImageBasicFragment | null;
}

export const SubscribeArea: React.FC<SubscribeAreaProps> = ({
  subscribeInfoText,
  subscribeButtonText,
  userEmailFormUrl,
  userEmailFormImage,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
  return (
    <>
      <SubscribeText>{subscribeInfoText}</SubscribeText>
      <Button onClick={toggleDialog} nature="primary">
        {subscribeButtonText}
      </Button>

      {isDialogOpen && (
        <SubscriptionDialog
          formUrl={userEmailFormUrl}
          image={userEmailFormImage}
          onClose={toggleDialog}
        />
      )}
    </>
  );
};
