import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const EmptyProductStyled = styled.div`
  margin-block-end: ${spacing.block};

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    margin-block-end: ${spacing.medium};
  }

  .ImagePlaceholder {
    position: relative;
    display: flex;
    background: ${colors.gray6};

    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 0;
      padding-block-end: 100%;
    }
  }

  .ProductInfoPlaceholder {
    display: flex;
    flex-direction: column;
    padding: 10px 0 0;

    span {
      background-color: ${colors.gray5};
      display: flex;
      height: 14px;
      margin-block-end: 10px;

      &:nth-of-type(1) {
        width: 150px;
      }

      &:nth-of-type(2) {
        width: 110px;
      }
    }
  }
`;
