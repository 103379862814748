import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

interface GridChildColumnSpan {
  breakpoint?: Breakpoint;
  columns?: number | string;
  display?: CSSProperties['display'];
  start?: number | string;
}

export type GridChildColumnSpanProp = number | string | GridChildColumnSpan[];

interface StyledGridChildProps {
  $align?: CSSProperties['justifySelf'];
  $alignSelf?: CSSProperties['alignSelf'];
  $padding?: CSSProperties['padding'];
  $rowStart?: CSSProperties['gridRowStart'];
  $rowSpan?: CSSProperties['gridRowEnd'];
  $columnSpan?: GridChildColumnSpanProp;
}

function columnMapper({
  breakpoint,
  display = 'initial',
  start,
  columns,
}: GridChildColumnSpan) {
  const gridStart = start ? `${start} /` : '';
  const span = columns ? `span ${columns}` : '-1';
  const gridColumn = `${gridStart} ${span}`.trim();
  if (breakpoint) {
    return css`
      @media all and (min-width: ${breakpoints[breakpoint]}) {
        display: ${display};
        grid-column: ${gridColumn};
      }
    `;
  }
  return css`
    display: ${display};
    grid-column: ${gridColumn};
  `;
}

function getColumnSpan({ $columnSpan }: StyledGridChildProps) {
  if (Array.isArray($columnSpan)) {
    return $columnSpan.map(columnMapper);
  }
  return css`
    grid-column: ${$columnSpan || '1 / -1'};
  `;
}

export const StyledGridChild = styled.div<StyledGridChildProps>`
  place-self: ${({ $alignSelf }) => $alignSelf} ${({ $align }) => $align};
  padding: ${({ $padding }) => $padding};
  grid-row: ${({ $rowStart }) => $rowStart && `${$rowStart}`} /
    ${({ $rowSpan }) => $rowSpan && `span ${$rowSpan}`};
  ${getColumnSpan};
`;
