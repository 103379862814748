import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

export const ContactInformationStyled = styled.div`
  padding-block: 0;
  padding-inline: ${spacing.small};
  margin-block: ${spacing.medium} ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }

  /* width: 1000px;
  max-width: calc(100vw - 40px);
  margin-block: 0; margin-inline: auto; */
  p,
  span,
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: ${fontWeights.fontRegular};
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    margin: 0;
    color: ${colors.black};
  }

  a {
    display: block;

    &:hover {
      color: ${colors.blackHover};
    }
  }

  .TextBlock {
    margin-block-end: ${spacing.regular};

    &:first-of-type {
      margin-block-end: ${spacing.medium};
    }

    .Title {
      margin-block-end: ${spacing.xsmall};
    }

    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.mobileMax}) {
    .LocalContactInfo {
      margin-block-start: ${spacing.medium};
    }
  }
`;
