import NextImage from 'next/image';

import { ImageBlockFragment } from '@hultafors/hellberg/types';

import { ImageBlockStyled, Inner, Label, Wrapper } from './ImageBlock.styled';

export const ImageBlock: React.FC<ImageBlockFragment> = ({
  image,
  label,
  small,
}) => {
  const maxSize = small
    ? '(min-width: 640px) 600px'
    : '(min-width: 840px) 800px';
  const sizes = [maxSize, 'calc(100vw - 40px)'].join(', ');

  if (image?.url && image.url.endsWith('.svg')) {
    return (
      <ImageBlockStyled>
        <Wrapper $small={small}>
          <Inner $aspectRatio="1 / 1">
            <NextImage
              src={image.url}
              alt={image.alt || 'image'}
              fill
              style={{ objectFit: 'contain' }}
              sizes={sizes}
            />
          </Inner>
        </Wrapper>
        {label && <Label>{label}</Label>}
      </ImageBlockStyled>
    );
  }
  if (!image?.responsiveImage?.src) {
    return null;
  }
  return (
    <ImageBlockStyled>
      <Wrapper $small={small}>
        <Inner $aspectRatio={image.responsiveImage.aspectRatio}>
          <NextImage
            src={image.responsiveImage.src}
            alt={image.alt || 'image'}
            fill
            style={{ objectFit: 'contain' }}
            blurDataURL={image.responsiveImage.base64 || ''}
            placeholder="blur"
            sizes={sizes}
          />
        </Inner>
      </Wrapper>
      {label && <Label>{label}</Label>}
    </ImageBlockStyled>
  );
};
