import { useEffect, useId, useRef } from 'react';

import Image from 'next/image';
import { Swiper } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import { useGlobal } from '@hultafors/hellberg/hooks';
import { ImageFragment } from '@hultafors/hellberg/types';

import ArrowRight from '../../svg/arrow_right.svg';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  Container,
  NavigationButton,
  NavigationWrapper,
} from './ImageSlider.styled';

require('swiper/css');
require('swiper/css/navigation');

interface ImageSliderProps {
  images: ImageFragment[];
}

export const ImageSlider: React.FC<ImageSliderProps> = ({ images = [] }) => {
  const uid = useId();
  const { shared } = useGlobal();
  const swiper = useRef<Swiper | null>(null);
  const swiperRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (swiperRef.current && !swiper.current) {
      swiper.current = new Swiper(swiperRef.current, {
        modules: [Navigation, Pagination],
        direction: 'horizontal',
        loop: true,
        pagination: {
          el: paginationRef.current,
          type: 'fraction',
        },
        navigation: {
          nextEl: nextRef.current,
          prevEl: prevRef.current,
        },
      });
    }
  }, []);

  function slideMapper(image: ImageFragment, index: number) {
    if (!image.responsiveImage?.src) {
      return null;
    }
    return (
      <div
        key={`ImageSlider-${uid}-image-${index}`}
        className="swiper-slide"
        style={{ aspectRatio: image.responsiveImage.aspectRatio }}
      >
        <Image src={image.responsiveImage.src} fill alt={image.alt || ''} />
      </div>
    );
  }

  if (!images.length) {
    return null;
  }
  return (
    <Container>
      <div className="swiper" ref={swiperRef}>
        <div className="swiper-wrapper">{images.map(slideMapper)}</div>
        <NavigationWrapper>
          <NavigationButton
            ref={prevRef}
            aria-label={shared.previous || ''}
            $flip
          >
            <ArrowRight
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
          </NavigationButton>
          <Paragraph styleType="body16">
            <span ref={paginationRef} />
          </Paragraph>
          <NavigationButton ref={nextRef} aria-label={shared.next || ''}>
            <ArrowRight
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
          </NavigationButton>
        </NavigationWrapper>
      </div>
    </Container>
  );
};
