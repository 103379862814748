import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const StyledFeatures = styled.div`
  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin: 0;
  }

  a:hover {
    color: ${colors.hellBlue};
  }

  .feature {
    display: flex;
    align-items: center;
    padding-block-start: ${spacing.small};
    text-decoration: none;

    img {
      margin-inline-end: ${spacing.small};
      width: 24px;
      height: 24px;
    }
  }
`;
