import { useRouter } from 'next/router';

export function useUpdateQuery({
  filter: urlParams,
  replace = false,
}: {
  filter?: string[];
  replace?: boolean;
}) {
  const router = useRouter();
  return function updateQuery(params: URLSearchParams) {
    urlParams?.forEach((value) => {
      params.delete(value);
    });
    const search = params.toString();
    const pathname = router.asPath.split('?')[0];
    const method = replace ? router.replace : router.push;
    method({ pathname, search }, undefined, {
      shallow: true,
    });
  };
}
