import { FC } from 'react';

import { DualTextBlockFragment } from '@hultafors/hellberg/types';

import { ArrowLink } from '../ArrowLink/ArrowLink';
import { HTag } from '../HTag/HTag';

import {
  // Content,
  Description,
  DualTextBlockStyled,
  ItemWrapper,
} from './DualTextBlock.styled';

export interface DualTextBlockProps {
  title: string;
  description: string;
  linkText?: string;
  linkUrl?: string;
}

export const DualTextBlock: FC<DualTextBlockFragment> = ({
  title,
  description,
  linkUrl,
  linkText,
}) => {
  return (
    <DualTextBlockStyled>
      <ItemWrapper>
        <HTag
          type="h2"
          styleType={[
            { styleType: 'header1' },
            { breakpoint: 'desktop', styleType: 'mega' },
          ]}
        >
          {title}
        </HTag>
      </ItemWrapper>
      {description && (
        <ItemWrapper>
          <Description styleType="body16">{description}</Description>
          {linkText && linkUrl && (
            <ArrowLink url={linkUrl}>{linkText}</ArrowLink>
          )}
        </ItemWrapper>
      )}
    </DualTextBlockStyled>
  );
};
