import { getPlugLinkUrl } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  ExploreAreaBlockFragment,
  ExploreAreaPageLinkFragment,
} from '@hultafors/hellberg/types';

import { GenericPlug } from '../GenericPlug/GenericPlug';
import { HTag } from '../HTag/HTag';
import { PlugsGrid } from '../PlugsGrid/PlugsGrid';

import { ExploreAreaBlockStyled } from './ExploreAreaBlock.styled';

interface ExploreAreaBlockProps extends ExploreAreaBlockFragment {
  isContentArea?: boolean;
}

export const ExploreAreaBlock: React.FC<ExploreAreaBlockProps> = ({
  title,
  pageLink,
  isContentArea,
}) => {
  const { allNavItems } = useGlobal();

  const pageLinkMapper = (plug: ExploreAreaPageLinkFragment, i: number) => {
    // Intended to work for all plugs, use __typename to get parentSlugs with getPlugLinkUrl

    return (
      <GenericPlug
        title={plug.heroTitle || ''}
        description={plug.shortDescription || plug.heroDescription || ''}
        ctaLabel={plug.plugCtaLabel || ''}
        url={
          getPlugLinkUrl(
            plug.__typename || '',
            plug.slug || '',
            allNavItems,
            isContentArea,
          ) || ''
        }
        image={plug?.heroImage || undefined}
        key={`Plug-${i}`}
      />
    );
  };

  return (
    <ExploreAreaBlockStyled>
      {title && (
        <HTag
          type="h2"
          styleType={[
            { styleType: 'header3' },
            { breakpoint: 'mobileMax', styleType: 'header2' },
          ]}
        >
          {title}
        </HTag>
      )}
      {pageLink && (
        <PlugsGrid gap={200} minWidth={420} total={pageLink?.length}>
          {pageLink.map(pageLinkMapper)}
        </PlugsGrid>
      )}
    </ExploreAreaBlockStyled>
  );
};
