import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/hellberg/helpers';

import { ProductImageStyled } from './ProductImage.styled';

interface ProductImageProps {
  url?: string;
  name?: string;
}

export const ProductImage: React.FC<ProductImageProps> = ({ url, name }) => {
  const sizes = [
    '(min-width: 1536px) calc(1340px / 3)',
    '(min-width: 1276px) calc((100vw - 196px) / 3)',
    '(min-width: 866px) calc(50vw - 73px)',
    `(min-width: ${breakpoints.mobileMax}) calc(100vw - 96px)`,
    'calc(100vw - 32px)',
  ].join(', ');
  return (
    <ProductImageStyled>
      <Image
        src={
          url
            ? `${url}?w=2880&format=jpg&bgcolor=${colors.gray6.substring(1)}`
            : '/assets/gfx/noImage.png'
        }
        alt={name ?? ''}
        sizes={sizes}
        style={{ objectFit: 'contain' }}
        fill
      />
    </ProductImageStyled>
  );
};
