import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { useGeolocation } from 'react-use';
import useSWR, { Key } from 'swr';

import {
  RetailersApiResponse,
  Store,
  StoreCoords,
} from '@hultafors/shared/types';

export type FindRetailersModeType = 'findRetailersByCoord' | 'findRetailers';

export const findRetailersEndpoints: Record<FindRetailersModeType, string> = {
  findRetailersByCoord: '/api/findRetailersByCoord',
  findRetailers: '/api/findRetailers',
};

export const findRetailersMode: Record<
  FindRetailersModeType,
  FindRetailersModeType
> = {
  findRetailersByCoord: 'findRetailersByCoord',
  findRetailers: 'findRetailers',
};

interface UseFindRetailers {
  mode: FindRetailersModeType | undefined;
  // data: RetailersApiResponse | undefined;
  stores: Store[];
  isExpandable: boolean | undefined;
  averageCoords: StoreCoords | undefined;
  error: any;
  loading: boolean;
  validating: boolean;
  international: boolean;
  setInternational(value: boolean): void;
  radius: number | undefined;
  setRadius(value: number): void;
  term: string;
  setTerm(value: string): void;
  coords: StoreCoords;
  setCoords(value: StoreCoords): void;
  clear(): void;
  geoLoading: boolean;
}

interface UseFindRetailersProps {
  defaultRadius: number;
  radiusOptions: number[];
  defaultCoords: StoreCoords;
}

/**
 * Hook for find retailers api.
 * Important! Needs api routes set up!
 */
export const useFindRetailers = ({
  defaultRadius = 50,
  radiusOptions = [],
  defaultCoords,
}: UseFindRetailersProps): UseFindRetailers => {
  const getValidRadius = (input: number | string): number => {
    const parsedInput = parseInt(`${input}`, 10);
    return parsedInput && radiusOptions.includes(parsedInput)
      ? parsedInput
      : defaultRadius;
  };

  const router = useRouter();
  const [stores, setStores] = useState<Store[]>([]);

  const [radius, setRadius] = useState<number>(
    router.query['radius']
      ? getValidRadius(`${router.query['radius']}`)
      : defaultRadius,
  );
  const [international, setInternational] = useState<boolean>(
    `${router.query['international']}`.toLowerCase() === 'true' ? true : false,
  );
  const [term, setTerm] = useState<string>(
    router.query['search'] ? `${router.query['search']}` : '',
  );
  const [key, setKey] = useState<Key>(null);
  const [coords, setCoords] = useState<StoreCoords>(defaultCoords);

  const [geoLoading, setGeoLoading] = useState<boolean>(
    process.env.NODE_ENV !== 'development',
  );

  const { loading: geoLoadingReal, latitude, longitude } = useGeolocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      setGeoLoading(geoLoadingReal);
    }
  }, [geoLoadingReal]);

  const mode = useMemo(() => {
    if (
      typeof key === 'string'
      && key.startsWith(findRetailersEndpoints.findRetailersByCoord)
    ) {
      return findRetailersMode.findRetailersByCoord;
    }
    if (
      typeof key === 'string'
      && key.startsWith(findRetailersEndpoints.findRetailers)
    ) {
      return findRetailersMode.findRetailers;
    }
    return undefined;
  }, [key]);

  const { data, error, isValidating, isLoading } = useSWR<RetailersApiResponse>(
    key,
    {
      revalidateIfStale: true,
      revalidateOnReconnect: true,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (!isValidating && data?.averageCoords) {
      setCoords(data.averageCoords);
    }
    if (!isValidating) {
      setStores(data?.stores || []);
    }
  }, [data, isValidating]);

  useEffect(() => {
    if (key && typeof key === 'string') {
      const [url, query] = key.split('?');
      const params = new URLSearchParams(query);
      if (international) {
        params.set('international', 'true');
      } else {
        params.delete('international');
      }
      setKey(`${url}?${params.toString()}`);
    }
    setUrl('international', international ? 'true' : undefined);
  }, [international]);

  useEffect(() => {
    if (key && typeof key === 'string') {
      const [url, query] = key.split('?');
      const params = new URLSearchParams(query);
      if (radius) {
        params.set('radius', `${radius}`);
      } else {
        params.delete('radius');
      }
      setKey(`${url}?${params.toString()}`);
    }
    setUrl('radius', radius ? `${radius}` : undefined);
  }, [radius]);

  useEffect(() => {
    if (!geoLoading && term) {
      const [, query = ''] = (`${key}` || '').split('?');
      const params = new URLSearchParams(query);
      params.set('search', term);
      params.set('radius', `${radius}`);
      setKey(`${findRetailersEndpoints.findRetailers}?${params.toString()}`);
      setUrl('search', term);
    }
  }, [term, geoLoading]);

  useEffect(() => {
    if (!geoLoading && coords && !term) {
      const [, query = ''] = (`${key}` || '').split('?');
      const params = new URLSearchParams(query);
      params.set('latitude', `${coords.latitude}`);
      params.set('longitude', `${coords.longitude}`);
      params.set('radius', `${radius}`);
      setKey(
        `${findRetailersEndpoints.findRetailersByCoord}?${params.toString()}`,
      );
    }
  }, [coords, geoLoading]);

  useEffect(() => {
    if (!geoLoading && latitude && longitude && !term) {
      setCoords({ latitude, longitude });
    }
  }, [geoLoading]);

  const setUrl = (key: string, value?: string) => {
    const [url, query = ''] = router.asPath.split('?');
    const params = new URLSearchParams(query);
    if (value) {
      params.set(key, value);
    } else {
      params.delete(key);
    }
    const newQuery = params.toString();
    router.replace(`${url}${newQuery ? `?${newQuery}` : ''}`, undefined, {
      shallow: true,
    });
  };

  const clear = () => {
    setTerm('');
    setRadius(defaultRadius);
  };

  return {
    mode,
    stores,
    isExpandable: data?.isExpandable,
    averageCoords: data?.averageCoords,
    error,
    loading: isLoading,
    validating: isValidating,
    radius,
    setRadius,
    international,
    setInternational,
    term,
    setTerm,
    coords,
    setCoords,
    clear,
    geoLoading,
  };
};
