import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const ProductsGridStyled = styled.section`
  max-width: 1440px;
  width: 100%;
  margin-inline: auto;
  margin-block: 30px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 50px;

  --grid-layout-gap: 50px;
  --grid-column-count: 1;
  --grid-item-min-width: 360px;

  grid-gap: var(--grid-layout-gap);

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    --grid-column-count: 2;
    --gap-count: calc(var(--grid-column-count) - 1);

    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr)
    );
    grid-gap: var(--grid-layout-gap);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    --grid-column-count: 3;
    --gap-count: calc(var(--grid-column-count) - 1);

    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr)
    );
    grid-gap: var(--grid-layout-gap);
  }
`;
