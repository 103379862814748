import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const StyledGreyInfoBox = styled.div`
  .content {
    background-color: ${colors.gray4};
    padding-block: 31px;
    padding-inline: 32px;

    h2 {
      padding: ${spacing.medium};
    }
  }
`;
