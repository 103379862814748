import { useEffect, useRef, useState } from 'react';

import { MuxPlayerRefAttributes } from '@mux/mux-player-react/.';
import { useWindowSize } from 'react-use';

import { breakpointsRaw } from '@hultafors/shared/constants';

import { VideoFragment } from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { MuxPlayerStyled, PlayToggle, StyledVideo, VideoWrapper } from './VideoBlock.styled';

export const VideoBlock: React.FC<VideoFragment> = ({
  video,
}) => {
  const [paused, setPaused] = useState<boolean>(true);
  const videoPlayer = useRef<MuxPlayerRefAttributes | null>(null);

  const [playbackId, setPlaybackId] = useState<string | undefined>(
    video?.video?.muxPlaybackId ?? undefined,
  );

  const [placeholder, setPlaceholder] = useState<string | undefined>(
    video?.blurUpThumb ?? undefined,
  );

  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width > breakpointsRaw.tablet) {
      setPlaybackId(video?.video?.muxPlaybackId ?? undefined);
      setPlaceholder(video?.blurUpThumb ?? undefined);
    }
  }, [width]);

  function playVideo() {
    if (!videoPlayer?.current) {
      return;
    }
    if (paused === true) {
      setPaused(false);
      videoPlayer.current.play();
    }

    if (paused === false) {
      setPaused(true);
      videoPlayer.current.pause();
    }
  }

  if (!playbackId) {
    return null;
  }

  return (
    <StyledVideo>
      <Grid>
        <GridChild

          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
          ]}
        >
          <VideoWrapper>
            <MuxPlayerStyled ref={videoPlayer} autoPlay={false} playbackId={playbackId} placeholder={placeholder} />
            <PlayToggle>
              <button
                title="play"
                onClick={playVideo}
                // className="visible"
              >
                <svg
                  fill="none"
                  height="14"
                  viewBox="0 0 12 14"
                  width="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12 7-11.9999997 6.9282.00000061-13.8564036z" />
                </svg>
              </button>
            </PlayToggle>
          </VideoWrapper>
          <div className="description">
            {video?.title && <p className="video-title">{video?.title}</p>}
          </div>
        </GridChild>
      </Grid>
    </StyledVideo>
  );
};
