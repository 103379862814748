import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

interface SplitHeroProps {
  $image?: boolean;
  $center?: boolean;
  $margin?: boolean;
  $hasVideo?: boolean;
  $priority?: boolean;
}

export const SplitHeroBlockStyled = styled.div<SplitHeroProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 200px;
  width: 100%;
  box-shadow: ${({ $center }) =>
    $center ? 'none' : 'rgba(0, 0, 0, 0.062) 0px 2px 8px;'};
  ${({ $center, $hasVideo }) =>
    $center
    && !$hasVideo
    && css`
      margin-inline: auto;
      max-width: calc(100vw - 40px);
      width: 1000px;
    `}

  ${({ $priority }) =>
    !$priority
    && css`
      margin-block: ${spacing.medium};
    `}


  ${({ $priority }) =>
    $priority
    && css`
      margin-block-end: ${spacing.medium};
    `}

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    flex-direction: row;
  }
`;

export const TextContentWrapper = styled.div<SplitHeroProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $center }) => $center && 'center'};
  flex: ${({ $center }) => !$center && '1 0 50%'};
  width: 100%;
  padding: ${({ $center }) => !$center && '5vw'};
  padding-block-start: 80px;
  max-width: 100%;

  ${({ $hasVideo }) =>
    $hasVideo
    && css`
      z-index: 1;
    `}

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    max-width: ${({ $image }) => ($image ? '100%' : '50%')};
  }

  ${({ $center }) =>
    $center
    && css`
      > * {
        align-self: center;
      }
    `}
  h1 {
    margin-block-end: ${spacing.small};
  }
`;

export const ImageWrapper = styled.div<SplitHeroProps>`
  aspect-ratio: 16 / 9;
  position: relative;
  height: auto;
  width: 100%;
  margin-block-start: ${({ $margin }) => $margin && spacing.medium};

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    margin: ${({ $margin }) => $margin && spacing.medium};
  }
`;

export const VideoStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  aspect-ratio: 3 / 4;

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 1 / 1;
    min-height: auto;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 12 / 5;
  }

  p,
  h1,
  h2,
  a {
    color: ${colors.white};
  }

  > div:first-child {
    padding: 5vw;
    max-width: 800px;
  }
`;

export const VideoInner = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
