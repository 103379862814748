import Image from 'next/image';
import { useKey } from 'react-use';

import { ParttrapImage } from '@hultafors/shared/types';

import { colors } from '@hultafors/hellberg/helpers';

import Close from '../../svg/close.svg';
import { Dialog } from '../Dialog/Dialog';

import {
  DialogImage,
  ProductImageDialogStyled,
} from './ProductImageDialog.styled';
interface ProductImageDialogProps {
  images?: ParttrapImage[];
  onClick?(...args: any[]): void;
}
export const ProductImageDialog: React.FC<ProductImageDialogProps> = ({
  images = [],
  onClick,
}) => {
  useKey('Escape', onClick);

  const formatColor = (color: string) => {
    return color.substring(1, color.length);
  };
  const params = new URLSearchParams({
    format: 'jpg',
    bgcolor: formatColor(colors.gray6),
  }).toString();
  function imageMapper(image: ParttrapImage, index: number) {
    if (!image.url) {
      return null;
    }
    return (
      <DialogImage key={`ProductImageDialog-${index}`}>
        <Image
          src={`${image.url}?${params}`}
          alt={image.placeholder ?? ''}
          fill
        />
      </DialogImage>
    );
  }
  return (
    <Dialog
      onClose={() => onClick?.()}
      closeButtonLabel={
        <Close aria-hidden={true} focusable={false} width={16} height={16} />
      }
    >
      <ProductImageDialogStyled>
        {images.map(imageMapper)}
      </ProductImageDialogStyled>
    </Dialog>
  );
};
