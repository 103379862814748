import {
  ResultText,
  ResultTitle,
  SearchResultHeroStyled,
} from './SearchResultHero.styled';

interface SearchResultHeroProps {
  children?: React.ReactNode;
  message?: string;
  searchResultsPageTitle: string;
}

export const SearchResultHero: React.FC<SearchResultHeroProps> = ({
  children,
  message,
  searchResultsPageTitle,
}) => {
  return (
    <SearchResultHeroStyled>
      {searchResultsPageTitle && (
        <ResultTitle>{searchResultsPageTitle}</ResultTitle>
      )}
      {children}
      {message && <ResultText>{message}</ResultText>}
    </SearchResultHeroStyled>
  );
};
