import styled from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

import { spacing } from '@hultafors/hellberg/helpers';

export const StyledIconButtonWrapper = styled.button`
  ${buttonReset};
  width: auto;
  height: auto;
  min-height: ${spacing.regular};
  min-width: ${spacing.regular};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
`;
