import { useRef, useState } from 'react';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { StyledVideo } from './Video.styled';
interface VideoProps {
  src: { title: string; url: string };
  thumbnail?: { url: string };
  autoPlay?: boolean;
}
export const Video: React.FC<VideoProps> = (props) => {
  const [paused, setPaused] = useState(true);
  const videoPlayer = useRef(null);
  const handleOnPlay = () => {
    videoPlayer.current.setAttribute('controls', true);
    setPaused(false);
  };
  function playVideo() {
    videoPlayer.current.play();
    handleOnPlay();
  }
  return (
    <StyledVideo>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
          ]}
        >
          <div className="video-wrapper">
            <video
              ref={videoPlayer}
              poster={
                props.thumbnail && props.thumbnail.url
                  ? props.thumbnail.url
                  : ''
              }
              autoPlay={props.autoPlay}
              onPlay={handleOnPlay}
            >
              <source src={`${props.src.url}`} />
              <p>
                Your browser doesn&apos;t support HTML5 video. Here is a
                {' '}
                <a href={props.src.url}>link to the video</a>
                {' '}
                instead.
              </p>
            </video>
            <div className="controls">
              <button
                title="play"
                onClick={playVideo}
                className={paused ? 'visible' : ''}
              >
                <svg
                  fill="none"
                  height="14"
                  viewBox="0 0 12 14"
                  width="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12 7-11.9999997 6.9282.00000061-13.8564036z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="description">
            {props.src.title && (
              <p className="video-title">{props.src.title}</p>
            )}
          </div>
        </GridChild>
      </Grid>
    </StyledVideo>
  );
};
